import { Avatar } from '@material-ui/core'
import React, { Component } from 'react'
import './CompanyBlock.scss'


export default class CompanyBlock extends Component {
  
  render() {
    const { company } = this.props;

    if (!company) {
      return '';
    }

    return (
      <div className="company-block">
        <Avatar style={{ width: 30, height: 30 }} variant="rounded" src={company.image_url} children={company.name}/>
        <span>{company.name}</span>
        <ReputationBadge level={company.company_rating.badge_level ? company.company_rating.badge_level.badge_sub_level : 1} medal={company.company_rating.badge_level ? company.company_rating.badge_level.badge_level.toLowerCase().replace('no badge', 'norating') : 'norating'} />
      </div>
    )
  }
}

const ReputationBadge = ({ type = 'basic', level, medal = 'norating' }) => {
  return (
    <span>
      {Array(level).fill().map((_, index) => (
        <img key={`badge-${medal}-${index}`} alt={`badge-${medal}-${index}`} src={require(`../assets/icons/rating-${medal}.svg`)} />
      ))}
    </span>
  )
}