import React, { useState } from 'react';
import { Modal, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFilter } from '../../actions/trip';
import Select from 'react-select';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  // KeyboardDatePicker,
} from '@material-ui/pickers';
import DatePicker from 'react-date-picker';

const Filter = ({ show, statuses, onHide, setFilter, onFilter }) => {
  const statusStyle = {
    menu: styles => ({
      ...styles, height: 100,
    }),
    menuList: styles => ({
      ...styles, height: 100,
    }),
  };

  const [status_id, setStatusId] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const doFilter = () => {
    const filters = {
      status_id,
      dateFrom,
      dateTo,
    }

    setFilter(filters);
    setTimeout(() => {
      onFilter({
        status_id,
        dateFrom,
        dateTo,
      });
    }, 300);
    onHide();
  }

  const doReset = () => {
    setStatusId('');
    setDateFrom('');
    setDateTo('');
  }

  return (
    <Modal animation={true} className="p-0" dialogClassName="modal-bottom" show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          Pengaturan Filter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="control-label">Status</label>
          <Select placeholder="Semua status" value={status_id} onChange={(status_id) => setStatusId(status_id)} options={statuses} isClearable styles={statusStyle} />
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="form-group">
            <label className="control-label mb-0">Ready Time</label>
            <div className="row">
              <div className="col-6">
                {/* <input type="date" name="dateFrom" id="" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} className="form-control"/> */}
                <DatePicker
                  format="dd/MM/y"
                  onChange={(e) => setDateFrom(e)}
                  value={dateFrom}
                />
                {/* <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="From"
                  format="MM/dd/yyyy"
                  value={dateFrom}
                  onChange={(dateFrom) => setDateFrom(dateFrom)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                /> */}
              </div>
              <div className="col-6">
                {/* <input type="date" name="dateTo" id="" value={dateTo} onChange={(e) => setDateTo(e.target.value)} className="form-control"/> */}
                <DatePicker
                  format="dd/MM/y"
                  onChange={(e) => setDateTo(e)}
                  value={dateTo}
                />
                {/* <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="To"
                  format="MM/dd/yyyy"
                  value={dateTo}
                  onChange={(dateTo) => setDateTo(dateTo)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                /> */}
              </div>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </Modal.Body>
      <Modal.Footer>
        <Col>
          <Button variant="default" className="btn-block" onClick={doReset}>Reset</Button>
        </Col>
        <Col>
          <Button className="btn-block" onClick={doFilter}>Filter</Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

const mapstp = (state) => {
  let statuses = [
    {
      value: 10,
      label: 'PICKUP',
    },
    {
      value: 20,
      label: 'LOADING',
    },
    {
      value: 30,
      label: 'DROP OFF',
    },
    {
      value: 40,
      label: 'UNLOADING',
    },
    {
      value: 50,
      label: 'POD',
    },
  ];

  return {
    statuses,
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({
    setFilter
  }, dispatch);
}

export default connect(mapstp, mapdtp)(Filter)
