import {combineReducers} from 'redux';
import { GET, DETAIL, SET_DECISION, RESET_DECISION, GET_HISTORY, RESET_HISTORY, COUNT_HISTORY } from '../actions/approval';
import { GET_DETAIL_TRIP } from '../actions/trip';
import _ from 'lodash';
import { GET_ADDITIONAL_INFO } from '../actions/allocation_prices';
import { USER_IMPERSONATE } from '../actions/user';

const list = (state = { isLoading: false, rows: [] }, action) => {
    switch (action.type) {
        case GET:
          return {
            ...state,
            isLoading: true
          }
        case GET + '_SUCCESS':
            return {
              ...state,
              rows: action.payload.data,
              isLoading: false
            }
        case USER_IMPERSONATE + '_SUCCESS':
            return {
              ...state,
              rows: []
            }
        default:
            return state;
    }
}

const count = (state = 0, action) => {
    switch (action.type) {
        case GET + '_SUCCESS':
            return action.payload.data.length;
        case USER_IMPERSONATE + '_SUCCESS':
          return 0;
        default:
            return state;
    }
}

const initDetailState = {
  isLoading: false,
  data: {},
  trip: {},
  additionalInfo: {}
};

const detail = (state = initDetailState, action) => {
  switch (action.type) {
    case DETAIL:
      return {
        ...state,
        isLoading: true
      }
    case GET_DETAIL_TRIP:
      return {
        ...state,
        isLoading: true
      }
    case GET_ADDITIONAL_INFO:
      return {
        ...state,
        isLoading: true
      }
    case DETAIL + '_SUCCESS':
      const data = action.payload.data;
      data.schema = JSON.parse(data.schema);
      return {
        ...state,
        isLoading: false,
        trip: {},
        data
      }
    case GET_DETAIL_TRIP + '_SUCCESS':
      const trip = action.payload.data;
      return {
        ...state,
        isLoading: false,
        trip
      }
    case GET_ADDITIONAL_INFO + '_SUCCESS':
      const additionalInfo = action.payload.data;
      return {
        ...state,
        isLoading: false,
        additionalInfo
      }
    default:
        return state;
  }
}

const set = (state = { response: null, isLoading: false }, action) => {
  switch (action.type) {
    case SET_DECISION:
      return {
        ...state,
        isLoading: true
      }
    case SET_DECISION + '_FAIL':
    case SET_DECISION + '_SUCCESS':
      const data = action.payload.data;
      return {
        ...state,
        isLoading: false,
        response: data
      }
    case RESET_DECISION:
      return {
        ...state,
        isLoading: false,
        response: null
      }
    default:
      return state;
  }
}

const history = (state = { isLoading: false, rows: [] }, action) => {
  switch (action.type) {
      case GET_HISTORY:
        return {
          ...state,
          isLoading: true
        }
      case GET_HISTORY + '_SUCCESS':
        const {errorMessage, rows, offset} = action.payload.data;
        if (errorMessage) {
            return state;
        }
        if (offset > 0) {
            return {
              ...state,
              rows: state.rows.concat(rows),
              isLoading: false
            }
        }
        return {
          ...state,
          rows,
          isLoading: false
        }
      case RESET_HISTORY:
        return {
          ...state,
          rows: []
        }
      default:
          return state;
  }
}

const countHistory = (state = 0, action) => {
  switch (action.type) {
      case GET_HISTORY + '_SUCCESS':
          const {errorMessage, count} = action.payload.data;
          if (errorMessage) {
              return state;
          }
          return count;
      case RESET_HISTORY:
        return 0; 
      default:
          return state;
  }
}

const offsetHistory = (state = 0, action) => {
  switch (action.type) {
      case GET_HISTORY + '_SUCCESS':
          const {errorMessage, offset} = action.payload.data;
          if (errorMessage) {
              return state;
          }
          return offset;
      case RESET_HISTORY:
        return 0;
      default:
          return state;
  }
}

const countHistoryCriteria = (state = 0, action) => {
  switch (action.type) {
    case COUNT_HISTORY + '_SUCCESS':
        const {errorMessage, count} = action.payload.data;
        if (errorMessage) {
            return state;
        }
        return count;
    case RESET_HISTORY:
      return 0; 
    default:
        return state;
}
}

export default combineReducers({list, count, detail, set, history, countHistory, offsetHistory, countHistoryCriteria});
