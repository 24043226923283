import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { AppBar, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import {
  IonContent,
  IonModal,
  IonSearchbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonFooter,
  IonToast,
  IonSpinner
} from "@ionic/react";
import Select from 'react-select';
import { ArrowBack, Add, Close } from '@material-ui/icons';
import { cluster as getCluster, states as getStates, cities as getCities, clearCities, filterCities, districts as getDistricts, clearDistricts, filterDistricts, add as addArea, add_cluster as addClusterArea, update_administrative_division as updateAdministrativeDivision, remove as deleteArea, update as updateArea  } from '../../actions/areas';

import Can from '../../components/Can';
import { permissions } from '../../routes';
import _ from 'lodash'
import './Area.scss';
import Swal from 'sweetalert2';

const ManageScreen = (props) => {
  const { cluster, states, cities, districts, item } = props;
  const { pathname } = props.location;

  const [showState, setShowState] = useState(false);
  const [searchState, setSearchState] = useState('');
  const [selectedState, setSelectedState] = useState([]);
  
  const [showCity, setShowCity] = useState(false);
  const [searchCity, setSearchCity] = useState('');
  const [selectedCity, setSelectedCity] = useState([]);

  const [showDistrict, setShowDistrict] = useState(false);
  const [searchDistrict, setSearchDistrict] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState([]);

  const [postalCodes, setPostalCodes] = useState([]);
  const [shownPostalCodes, setShownPostalCodes] = useState('');
  
  const [areaId, setAreaId] = useState(0);
  const [areaName, setAreaName] = useState('');
  const [areaCluster, setAreaCluster] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [showInfoToast, setShowInfoToast] = useState(false);
  const [msgInfoToast, setMsgInfoToast] = useState('');

  const [headerPage, setHeaderPage] = useState('Tambah');
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener('resize', () => setContainerHeight(window.innerHeight))
  }, [])

  const goBack = () => {
    if (!isLoading) props.history.push('/all-price/area');
  }

  
  useEffect(() => {
    if (item && Object.keys(item).length > 0 && pathname === '/all-price-update-area') {
      let tempItem = _.cloneDeep(item);
      let tempStates = [];
      let tempCities = [];
      
      setHeaderPage('Ubah');
      setAreaId(item.id);
      setAreaName(item.name);
      _.map(tempItem.administrative_divisions, (i) => {
        if (i.administrative_division.parent_id) {
          let parent = i.administrative_division.parent;
          if (i.administrative_division.division_level == 1 && !_.find(tempStates, parent)) tempStates.push(parent);
          if (i.administrative_division.division_level == 2 && !_.find(tempCities, parent)) tempCities.push(parent);
        }
      });

      if (item.cluster_areas.length) {
        const tempCluster = {
          value: item.cluster_areas[0].cluster_id,
          label: item.cluster_areas[0].cluster.description
        }

        setAreaCluster(tempCluster);
      }

      setSelectedState(_.uniqBy(_.concat(tempStates, _.map(_.filter(item.administrative_divisions, (division) => {
        return division.administrative_division.division_level === 0;
      }), 'administrative_division')), 'id').map(s => {
        return {
          value: s.id,
          label: s.name,
          division_type: s.division_type,
          parent_id: s.parent_id,
          zipcode: s.zipcode
        }
      }));

      setSelectedCity(_.uniqBy(_.concat(tempCities, _.map(_.filter(item.administrative_divisions, (division) => {
        return division.administrative_division.division_level === 1;
      }), 'administrative_division')), 'id').map(c => {
        return {
          value: c.id,
          label: c.name,
          division_type: c.division_type,
          parent_id: c.parent_id,
          zipcode: c.zipcode
        }
      }));

      setSelectedDistrict(_.map(_.filter(item.administrative_divisions, (division) => {
        return division.administrative_division.division_level == 2;
      }), 'administrative_division').map(d => {
        return {
          value: d.id,
          label: d.name,
          division_type: d.division_type,
          parent_id: d.parent_id,
          zipcode: d.zipcode
        }
      }));
    } else if (item && Object.keys(item).length === 0 && pathname === '/all-price-update-area') {
      goBack();
    }
  }, [item])

  const filterState = (item) => {
    setSearchState(item);
  }

  const selectState = (item) => {
    const choosenState = states.find(temp => temp.value === item);
    if (selectedState.filter(e => e.value === item).length === 0) {
      setSelectedState(prev => [...prev, choosenState]);
    } else {
      const newSelectedState = selectedState.filter(e => e.value !== item);
      props.filterCities(item);
      setSelectedState(newSelectedState);

      const tempSelectedCity = selectedCity.filter(temp => temp.parent_id !== item);
      setSelectedCity(tempSelectedCity);
    }
  }

  const clearState = () => {
    setSelectedState([]);
    setSelectedCity([]);
    setSelectedDistrict([]);
    props.clearCities();
    props.clearDistricts();
  }

  const onApplyState = () => {
    if (selectedState.length) {
      selectedState.map(temp => {
        const existState = cities.filter(city => city.parent_id === temp.value);
        if (!existState.length) props.getCities(temp.value)
      })
    }
    setShowState(false);
  }

  const filterCity = (item) => {
    setSearchCity(item);
  }

  const selectCity = (item) => {
    const choosenCity = cities.find(temp => temp.value === item);
    if (selectedCity.filter(e => e.value === item).length === 0) {
      setSelectedCity(prev => [...prev, choosenCity]);
    } else {
      const newSelectedCity = selectedCity.filter(e => e.value !== item);
      props.filterDistricts(item);
      setSelectedCity(newSelectedCity);

      const tempSelectedDistrict = selectedDistrict.filter(temp => temp.parent_id !== item);
      setSelectedDistrict(tempSelectedDistrict);
    }
  }

  const clearCity = () => {
    setSelectedCity([]);
    setSelectedDistrict([]);
    props.clearDistricts();
  }

  const onApplyCity = () => {
    if (selectedCity.length) {
      selectedCity.map(temp => {
        const existCity = districts.filter(district => district.parent_id === temp.value);
        if (!existCity.length) props.getDistricts(temp.value)
      })
    }
    setShowCity(false);
  }

  const filterDistrict = (item) => {
    setSearchDistrict(item);
  }

  const selectDistrict = (item) => {
    const choosenDistrict = districts.find(temp => temp.value === item);
    if (selectedDistrict.filter(e => e.value === item).length === 0) {
      setSelectedDistrict(prev => [...prev, choosenDistrict]);
    } else {
      const newSelectedDistrict = selectedDistrict.filter(e => e.value !== item);
      setSelectedDistrict(newSelectedDistrict);
    }
  }

  const clearDistrict = () => {
    setSelectedDistrict([]);
  }

  const onApplyDistrict = () => {
    setShowDistrict(false);
  }

  useEffect(() => {
    props.clearCities();
    props.clearDistricts();
    props.getCluster();
    props.getStates();
  }, [])


  useEffect(() => {
    refreshPostalCode();

    if (pathname === '/all-price-update-area') {
      onApplyState();
      onApplyCity();
    }
  }, [selectedState, selectedCity, selectedDistrict])

  const refreshPostalCode = () => {
    let areas = _.concat(selectedState, selectedCity, selectedDistrict);
    const parentIds = _.map(_.uniqBy(areas, 'parent_id'), 'parent_id');
    areas = _.filter(areas, (area) => {
      return typeof _.find(parentIds, (parentId) => {
        return area.value == parentId
      }) === 'undefined';
    });

    const postalCodes = _.map(_.uniqBy(areas, (area) => {
      return area.parent_id === null ? area.value : area.zipcode;
    }), (area) => {
      return area.zipcode;
    });

    setShownPostalCodes(postalCodes.join(','));
    setPostalCodes(postalCodes);
  }

  const handleAreaNameChange = (event) => {
    setAreaName(event.target.value);
  }

  const handleAreaClusterChange = (event) => {
    setAreaCluster(event);
  }

  const handleToastMessage = (msg) => {
    setMsgInfoToast(msg);
    setShowInfoToast(true);
  }

  const getLeaveIds = () => {
    let areas = _.concat(selectedState, selectedCity, selectedDistrict);
    const parentIds = _.map(_.uniqBy(areas, 'parent_id'), 'parent_id');
    areas = _.filter(areas, (area) => {
      return typeof _.find(parentIds, (parentId) => {
        return area.value == parentId
      }) === 'undefined';
    });

    return _.map(areas, 'value');
  }

  const handleCreateOrUpdate = async () => {
    if (!isLoading) {
      if (!areaName || !selectedState.length) {
        handleToastMessage('Isi minimal area dan provinsi');
        return;
      } else if (Object.keys(areaCluster).length === 0) {
        handleToastMessage('Pilih cluster')
        return;
      }

      setIsLoading(true);
      const leaveIds = getLeaveIds();
      let areaResult = null;
      if (!areaId) {
        areaResult = await props.addArea({ name: areaName });
      } else {
        areaResult = await props.updateArea(areaId, { name: areaName });
      }
      const tempAreaId = _.get(areaResult, 'payload.data.id', 0);
      const areaErrorMessage = _.get(areaResult, 'payload.data.errorMessage', '');
      if (tempAreaId && areaErrorMessage === '') {
        let areaAdministrativeDivisionResult = await props.updateAdministrativeDivision(tempAreaId, { administrative_division_ids: leaveIds });
        await props.addClusterArea(tempAreaId, { cluster_id: areaCluster.value});

        let canForce = _.get(areaAdministrativeDivisionResult, 'payload.data.canForce', false);
        let errorMessage = _.get(areaAdministrativeDivisionResult, 'payload.data.errorMessage', '');

        if (canForce) {
          Swal.fire({
            title: 'Konfirmasi',
            text: errorMessage,
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'swal2-btn-area swal2-confirm-area',
              cancelButton: 'swal2-btn-area swal2-cancel-area'
            },
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              await props.updateAdministrativeDivision(tempAreaId, { administrative_division_ids: leaveIds, force: true })
              setIsLoading(false);

              Swal.fire({
                title: areaId ? 'Area berhasil diubah' : 'Area berhasil ditambahkan',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'swal2-btn-area swal2-cancel-area',
                },
                confirmButtonText: 'Tutup',
                allowOutsideClick: false,
              }).then((result) => {
                if (result.value) {
                  goBack();
                }
              })
              return;
            } else {
              await props.deleteArea(tempAreaId, {});
              setIsLoading(false);
              Swal.fire({
                title: areaId ? 'Perubahan area dibatalkan' : 'Penambahan area dibatalkan',
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'swal2-btn-area swal2-cancel-area',
                },
                confirmButtonText: 'Tutup',
                allowOutsideClick: false,
              }).then((result) => {
                if (result.value) {
                  goBack();
                }
              })
              return;
            }
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            title: areaId ? 'Area berhasil diubah' : 'Area berhasil ditambahkan',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'swal2-btn-area swal2-cancel-area',
            },
            confirmButtonText: 'Tutup',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              goBack();
            }
          })
        }
      } else {
        setIsLoading(false);
        handleToastMessage(areaErrorMessage);
      }
    }
  }

  const badgeStyle = {
    display: 'inline-table',
    border: '1px solid #D9D9D9',
    borderRadius: '50px',
    float: 'none',
    whiteSpace: 'nowrap'
  }

  const scrollableContainer = {}

  return (
    <Can redirect permissions={permissions(pathname)}>
        <IonContent style={{ "--background": "#FFF" }}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                edge="start"
                onClick={() => goBack()}
                style={{ color: "white" }}
                aria-label="Menu"
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" style={{ color: "white" }}>
                {headerPage} Area
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="app-container-1 manage-area-container">
            <Container fluid className='container-manage-area-detail' style={{ marginBottom: '2rem', height: (containerHeight - 190)+'px', overflowX: 'auto'}}>
              <div className="mb-2">
                <div>Nama Area</div>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type='text'
                  value={areaName}
                  onChange={handleAreaNameChange}
                />
              </div>
              <div className="mb-2">
                <div>Cluster</div>
                <Select className="mb-3" placeholder="Pilih Cluster" value={areaCluster} options={cluster} isClearable style={{textTransform: 'capitalize'}} onChange={handleAreaClusterChange}/>
              </div>
              <div className="mb-2">
                <div>Provinsi</div>
                <div style={scrollableContainer}>
                  { 
                    selectedState.map(tempState => (
                      <div key={`state-${tempState.value}`} className="p-1 pl-3 pr-3 mr-3" style={ badgeStyle }>
                        {tempState.label}
                        &nbsp;
                        &nbsp;
                        <Close key={`remove-state-${tempState.value}`} style={{ color: '#4A4A4A', fontSize: '1rem' }} onClick={() => { selectState(tempState.value); onApplyState(); }}/>
                      </div>
                    ))
                  }
                  <IconButton
                    color="primary"
                    size="medium"
                    edge="end"
                    aria-label="add"
                    sx={{ mr: 2 }}
                    onClick={ () => setShowState(true) }
                  >
                    <Add style={{color:'white'}}/>
                  </IconButton>
                </div>
                </div>
              <div className="mb-2">
                <div>Kota / Kabupaten</div>
                <div style={scrollableContainer}>
                  { 
                      selectedCity.map(tempCity => (
                        <div key={`city-${tempCity.value}`} className="p-1 pl-3 pr-3 mr-3" style={ badgeStyle }>
                          {tempCity.division_type} {tempCity.label}
                          &nbsp;
                          &nbsp;
                          <Close key={`remove-city-${tempCity.value}`} style={{ color: '#4A4A4A', fontSize: '1rem' }} onClick={() => { selectCity(tempCity.value); onApplyCity(); }}/>
                        </div>
                      ))
                    }
                    <IconButton
                    color="primary"
                    size="large"
                    edge="end"
                    aria-label="add"
                    sx={{ mr: 2 }}
                    onClick={ () => setShowCity(true) }
                  >
                    <Add style={{color:'white'}}/>
                  </IconButton>
                </div>
              </div>
              <div className="mb-2">
                <div>Kecamatan</div>
                <div style={scrollableContainer}>
                  { 
                      selectedDistrict.map(tempDistrict => (
                        <div key={`district-${tempDistrict.value}`} className="p-1 pl-3 pr-3 mr-3" style={ badgeStyle }>
                          {tempDistrict.label}
                          &nbsp;
                          &nbsp;
                          <Close key={`remove-district-${tempDistrict.value}`} style={{ color: '#4A4A4A', fontSize: '1rem' }} onClick={() => { selectDistrict(tempDistrict.value); onApplyDistrict(); }}/>
                        </div>
                      ))
                    }
                  <IconButton
                    color="primary"
                    size="large"
                    edge="end"
                    aria-label="add"
                    sx={{ mr: 2 }}
                    onClick={ () => setShowDistrict(true) }
                  >
                    <Add style={{color:'white'}}/>
                  </IconButton>
                  </div>
              </div>
              <div className="mb-2">
                <div>Kode Pos</div>
                <TextField
                  fullWidth
                  disabled={true}
                  value={shownPostalCodes}
                  variant="outlined"
                  size="small"
                  type='text'
                />
              </div>
            </Container>
            <Container className="fixed-bottom d-flex justify-content-center" style={{ bottom: '20px' }}>
              <Row className="pt-2 pb-2" style={{ width: '100%' }}>
                <Col xs={6} md={6}>
                  <Button block variant="outline-secondary" onClick={() => goBack() }>
                    { isLoading ? <IonSpinner name="crescent"/> : 'Kembali' }
                  </Button>
                </Col>
                <Col xs={6} md={6}>
                  <Button block variant="primary" onClick={handleCreateOrUpdate}>
                  { isLoading ? <IonSpinner name="crescent"/> : 'Simpan' }
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>

          <IonModal cssClass="modal-area" isOpen={showState} swipeToClose={true} onDidDismiss={() => setShowState(false)}>
            <div className="modal-area-content">
              <div className="modal-area-title">Provinsi</div>
              <div className="modal-area-options">
                <IonSearchbar
                  value={searchState} 
                  onIonChange={e => filterState(e.detail.value)}
                  placeholder="Cari Provinsi"
                /> 
                <Container style={{ maxHeight: '280px', overflowY: 'auto', marginTop: '10px'}}>
                    {
                      states
                        .filter(state => state.label.toLowerCase().includes(searchState.toLocaleLowerCase()))
                        .map(state =>
                        (
                          <div style={{ display: 'flex'}}>
                            <label className="checkbox-container">
                              <span style={{ fontWeight: 'normal', marginLeft: '35px' }}>{state.label}</span>
                              <span style={{ float: 'right'}}>{state.zipcode}</span>
                              <input type="checkbox" checked={selectedState.find(tempState => tempState.value === state.value) || false} onChange={(e) => selectState(state.value)} />
                              <span className="checkmark"></span>
                            </label>  
                          </div>
                        )
                      )
                    }
                </Container>
              </div>
              <IonFooter style={{ position: 'fixed', bottom: 0, padding: '0 1rem' }}>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonButton className="btn-filter btn-modal-light" expand="block" color="light" onClick={() => clearState()}>Reset</IonButton>
                    </IonCol>
                    <IonCol>
                      <IonButton className="btn-filter" expand="block" color="primary" onClick={() => onApplyState()}>Terapkan</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonFooter>
            </div>
          </IonModal>

          <IonModal cssClass="modal-area" isOpen={showCity} swipeToClose={true} onDidDismiss={() => setShowCity(false)}>
            <div className="modal-area-content">
              <div className="modal-area-title">Kota / Kabupaten</div>
              <div className="modal-area-options">
                <IonSearchbar
                  value={searchCity} 
                  onIonChange={e => filterCity(e.detail.value)}
                  placeholder="Cari Kota / Kabupaten"
                /> 
                <Container style={{ maxHeight: '280px', overflowY: 'auto', marginTop: '10px'}}>
                    {
                      cities.length ?
                      (
                        cities
                          .filter(city => {
                            const city_name = city.division_type.toLowerCase() + ' ' + city.label.toLowerCase();
                            return city_name.includes(searchCity.toLowerCase()) || city.label.toLowerCase().includes(searchCity.toLowerCase());
                          })
                          .map(city =>
                          (
                            <div style={{ display: 'flex'}}>
                              <label className="checkbox-container">
                                <span style={{ fontWeight: 'normal', marginLeft: '35px' }}>{city.division_type} {city.label}</span>
                                <span style={{ float: 'right'}}>{city.zipcode}</span>
                                <input type="checkbox" checked={selectedCity.find(tempCity => tempCity.value === city.value) || false} onChange={(e) => selectCity(city.value)} />
                                <span className="checkmark"></span>
                              </label>  
                            </div>
                          )
                        )
                      ) : (
                        <p className="text-center">Tidak ada kota / kabupaten yang tersedia</p>
                      )
                    }
                </Container>
              </div>
              <IonFooter style={{ position: 'fixed', bottom: 0, padding: '0 1rem' }}>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonButton className="btn-filter btn-modal-light" expand="block" color="light" onClick={() => clearCity()}>Reset</IonButton>
                    </IonCol>
                    <IonCol>
                      <IonButton className="btn-filter" expand="block" color="primary" onClick={() => onApplyCity()}>Terapkan</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonFooter>
            </div>
          </IonModal>

          <IonModal cssClass="modal-area" isOpen={showDistrict} swipeToClose={true} onDidDismiss={() => setShowDistrict(false)}>
            <div className="modal-area-content">
              <div className="modal-area-title">Kecamatan</div>
              <div className="modal-area-options">
                <IonSearchbar
                  value={searchDistrict} 
                  onIonChange={e => filterDistrict(e.detail.value)}
                  placeholder="Cari Kecamatan"
                /> 
                <Container style={{ maxHeight: '280px', overflowY: 'auto', marginTop: '10px'}}>
                    {
                      districts.length ? (
                        districts
                          .filter(district => district.label.toLowerCase().includes(searchDistrict.toLocaleLowerCase()))
                          .map(district =>
                          (
                            <div style={{ display: 'flex'}}>
                              <label className="checkbox-container">
                                <span style={{ fontWeight: 'normal', marginLeft: '35px' }}>{district.label}</span>
                                <span style={{ float: 'right'}}>{district.zipcode}</span>
                                <input type="checkbox" checked={selectedDistrict.find(tempDistrict => tempDistrict.value === district.value) || false} onChange={(e) => selectDistrict(district.value)} />
                                <span className="checkmark"></span>
                              </label>  
                            </div>
                          )
                        ) 
                      ) : (
                        <p className="text-center">Tidak ada kecamatan yang tersedia</p>
                      )
                    }
                </Container>
              </div>
              <IonFooter style={{ position: 'fixed', bottom: 0, padding: '0 1rem' }}>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonButton className="btn-filter btn-modal-light" expand="block" color="light" onClick={() => clearDistrict()}>Reset</IonButton>
                    </IonCol>
                    <IonCol>
                      <IonButton className="btn-filter" expand="block" color="primary" onClick={() => onApplyDistrict()}>Terapkan</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonFooter>
            </div>
          </IonModal>

          <IonToast
            isOpen={showInfoToast}
            onDidDismiss={() => { setMsgInfoToast(''); setShowInfoToast(false)}}
            message={msgInfoToast}
            duration={4000}
            position="top"
          />
        </IonContent>
    </Can>
  )
}

const mapstp = (state) => {
  const { areas } = state
  const cluster = areas.cluster.map((item)=> {
    return {
      value: item.id,
      label: item.description
    }
  })

  const states = areas.states.map((item) => {
    return {
      value: item.id,
      label: item.name,
      division_type: item.division_type,
      parent_id: item.parent_id,
      zipcode: item.zipcode
    }
  })

  const cities = areas.cities.map((item) => {
    return {
      value: item.id,
      label: item.name,
      division_type: item.division_type,
      parent_id: item.parent_id,
      zipcode: item.zipcode
    }
  })

  const districts = areas.districts.map((item) => {
    return {
      value: item.id,
      label: item.name,
      division_type: item.division_type,
      parent_id: item.parent_id,
      zipcode: item.zipcode
    }
  })

  return {
    item: areas.detail,
    loading: areas.loading,
    cluster,
    states,
    cities,
    districts
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ getCluster, getStates, getCities, clearCities, filterCities, getDistricts, clearDistricts, filterDistricts, addArea, addClusterArea, updateAdministrativeDivision, deleteArea, updateArea }, dispatch);
}
export default connect(mapstp, mapdtp)(ManageScreen);
