import {RESET_CONSIGNEE_REPORT, STORE_CONSIGNEE_REPORT} from "../actions/consignee";
import get from 'lodash/get';

const init = {
    goods_statuses: [
        'issue', 'unload',
    ],
    store: {
        loading: false,
        error: null,
    }
}

export default (state = init, {type, payload, error}) => {
    switch (type) {
        case RESET_CONSIGNEE_REPORT:
            return init;
        case STORE_CONSIGNEE_REPORT:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: true,
                    error: null,
                }
            };
        case `${STORE_CONSIGNEE_REPORT}_SUCCESS`:
        case `${STORE_CONSIGNEE_REPORT}_FAIL`:
            return {
                ...state,
                store: {
                    loading: false,
                    // eslint-disable-next-line no-mixed-operators
                    error: error || get(payload, 'data.errorMessage') && get(payload, 'data') || null,
                }
            }
        default:
            return state;
    }
}
