export default {
  pod_monitoring: {
    id: 56,
    application: 'admin',
    module: 'pod_monitoring',
    action: '*',
    description: 'Access menu POD Monitoring'
  },
  trip_monitoring: {
    id: 57,
    application: 'admin',
    module: 'trip_monitoring',
    action: 'review',
    description: 'Access menu Trip Monitoring and review created report'
  },
  attendance_planner: {
    id: 121,
    application: 'admin',
    module: 'resources',
    action: 'update_status_tier_1',
    description: 'Update attendance status for Tier 1 (Planner)'
  },
  attendance_field: {
    id: 122,
    application: 'admin',
    module: 'resources',
    action: 'update_status_tier_2',
    description: 'Update attendance status for Tier 2 (Field Ops)'
  },
  capacity_offer: {
    id: 182,
    application: 'admin',
    module: 'transport_capacity_offers',
    action: 'manageTransportCapacityOffers',
    description: 'Allow admin to manage access to transport capacity offers module'
  },
  all_price: {
    id: 52,
    application: 'admin',
    module: 'allprices',
    action: '*',
    description: 'Access menu All Prices'
  }
};
