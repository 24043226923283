import {
  GET_AREAS,
  GET_TRANSPORT_ROUTE,
  GET_VEHICLE_TYPE} from '../actions/all_prices';
import { combineReducers } from 'redux';

const vehicles = (state = [], action) => {
  switch (action.type) {
    case GET_VEHICLE_TYPE+'_SUCCESS':
      if (action.payload.data) {
        const { rows = []} = action.payload.data
        return [
          ...state,
          ...rows
        ]
      }
      return state;
    default:
      return state;
  }
}

const areas = (state = [], action) => {
  switch (action.type) {
    case GET_AREAS+'_SUCCESS':
      if (action.payload.data) {
        const { rows = []} = action.payload.data
        return [
          ...state,
          ...rows
        ]
      }
      return state;
    default:
      return state;
  }
}

const transportRoutes = (state = [], action) => {
  switch (action.type) {
    case GET_TRANSPORT_ROUTE+'_SUCCESS':
      if (action.payload.data) {
        const { rows = []} = action.payload.data
        return [
          ...state,
          ...rows
        ]
      }
      return state;
    default:
      return state;
  }
} 


export default combineReducers({
  vehicles,
  areas, 
  transportRoutes});