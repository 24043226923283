import React from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const BackButton = (props) => {
  return (
    <AppBar className="ritase-appbar ritase-toolbar-back" style={{background: 'white'}}>
      <Toolbar className="toolbar">
        <IconButton edge="start" onClick={() => props.history.goBack()} className="ritase-menu-button" aria-label="Menu">
          <ArrowBack />
          <Typography variant="h6" color="inherit">
            Kembali
          </Typography>
        </IconButton>
      </Toolbar>
      {props.children}
    </AppBar>
  );
}

export default BackButton;
