export const GET_ADDITIONAL_INFO = 'ALLOCATION_PRICES_GET_ADDITIONAL_INFO';

export const getAdditionalInfo = (trip_id) => {
  return {
    type: GET_ADDITIONAL_INFO,
    payload: {
      request: {
        url: `/allocation_prices/additional_info/${trip_id}`,
        method: 'get',
      },
    },
  };
};
