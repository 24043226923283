import queryString from 'query-string';

export const GET_TRIPS = 'W_GET_TRIPS';
export const GET_TRIP_DETAIL = 'W_GET_TRIP_DETAIL';
export const UPDATE_TRIP = 'W_UPDATE_TRIP';
export const SET_ORDER = 'W_SET_ORDER';
export const CLOSE_ORDER = 'W_CLOSE_ORDER';
export const SET_TRIP = 'W_SET_TRIP';
export const GET_TRIPS_PARAM = 'W_GET_TRIPS_PARAM';
export const UPDATE_PACKAGES = 'W_UPDATE_PACKAGES';

export const getTrips = (offset = 0, status = 'inprogress', params=null) => {
  return {
    type: GET_TRIPS,
    payload: {
      request: {
        url: `/trips/warehouse/inbound?limit=10&offset=${offset}&populate=vehicle.type,driver,orders.packages,company_transport&status=${status}&${queryString.stringify(params)}`
      }
    }
  }
}

export const getTripById = (trip_id, params=null) => ({
  type: GET_TRIP_DETAIL,
  payload: {
    request: {
      url: `/trips/${trip_id}?${queryString.stringify({
        ...params,
        populate: 'vehicle.type,driver,orders.packages,company_transport'
      })}`,
      method: 'get'
    },
  },
});

export const updateTrip = (trip_id, data) => ({
  type: UPDATE_TRIP,
  payload: {
    request: {
      url: `/trips/${trip_id}`,
      method: 'put',
      data,
    },
  },
});

export const updatePackages = (data) => ({
  type: UPDATE_PACKAGES,
  payload: {
    request: {
      url: `/packages`,
      method: 'put',
      data
    },
  },
});

export const setOrder = order => ({
  type: SET_ORDER,
  order
});

export const closeOrder = () => ({
  type: CLOSE_ORDER
});

export const setTrip = trip => ({
  type: SET_TRIP,
  trip
})

export const getTripsParam = (offset = 0, params=null) => {
  return {
    type: GET_TRIPS_PARAM,
    payload: {
      request: {
        url: `/trips?limit=10&offset=${offset}${params}`
      }
    }
  }
}

export const resetTrips = () => {
  return {
    type: GET_TRIPS_PARAM + '_RESET',
  }
}