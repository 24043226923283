import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppBar, Toolbar, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ArrowBack, PrintOutlined as PrintIcon, Info as IconInfo } from '@material-ui/icons';
import { QRCode } from 'react-qr-svg';
import ReactToPrint from 'react-to-print';
import { toggleHeader } from '../../actions';
import Can from '../../components/Can';
import { permissions } from '../../routes';
import moment from 'moment';

class PrintQRScreen extends Component {
  componentDidMount() {
    this.props.toggleHeader(false);
  }

  componentWillUnmount() {
    this.props.toggleHeader(true);
  }

  goBack = () => {
    this.props.history.push('/field');
  }

  printBarcode = () => {

  }

  render() {
    if (!this.props.detail) {
      return (
        <Can redirect permissions={permissions('/field')}>

          <Container>
            <AppBar position="fixed" color="default">
              <Toolbar>
                <IconButton edge="start" onClick={() => this.goBack()} color="inherit" aria-label="Menu">
                  <ArrowBack />
                </IconButton>
                <Typography variant="h6" color="inherit">
                  Print QR Code
              </Typography>
              </Toolbar>
            </AppBar>
            <h2 className="text-danger">
              Terjadi kesalahan saat menampilkan halaman.
            </h2>
          </Container>
        </Can>
      )
    }
    const { detail, user } = this.props
    return (
      <Can redirect permissions={permissions('/field')}>
        <Container style={{padding: '20px 15px'}}>
          <AppBar position="fixed" color="default">
            <Toolbar>
              <IconButton edge="start" onClick={() => this.goBack()} color="inherit" aria-label="Menu">
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="inherit">
                Print QR Code
              </Typography>
            </Toolbar>
          </AppBar>
          <ReactToPrint
            copyStyles={false}
            trigger={() => (
              <Button variant="primary" size="lg" block onClick={this.printBarcode}>
                <PrintIcon /> Print
              </Button>
            )}
            content={() => this.printArea}
          />
          <br/>
          <Accordion>
            <AccordionSummary
              expandIcon={<IconInfo />}
            >
              <Typography>Instruksi</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Untuk dapat mencetak QR Code pada printer thermal yang sudah disediakan, silakan ikuti langkah berikut
              </Typography>
              <ol>
                <li>Hubungkan Android dengan thermal printer melalui Bluetooth</li>
                <li>Instal utility driver <strong>RawBT</strong></li>
                <li>Buka aplikasi <strong>RawBT</strong> kemudian pergi ke menu Settings</li>
                <li>Pilih Connection method: Bluetooth</li>
                <li>Lalu pilih perangkat printer yang terhubung</li>
                <li>Sesuaikan ukuran kertas yang dapat digunakan pada printer thermal</li>
                <li>Setelah konfigurasi printer selesai, kembali ke sini dan tekan tombol Print</li>
                <li>Terakhir, pilih RawBT printer dan cetak QR Code</li>
              </ol>
            </AccordionDetails>
          </Accordion>
          <br />
          <div style={{ display: 'none' }}>
            <div ref={el => (this.printArea = el)}>
              <div className="printArea" style={{ fontFamily: 'monospace' }}>
                <div style={{textAlign: 'center'}}>
                  <p style={{ fontSize: 11 }}>POD Monitoring</p>
                  <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="Q"
                    style={{ width: '80%', margin: 'auto' }}
                    value={detail.id}
                  />
                </div>
                <br />
                <div style={{ fontSize: 11 }}>Shipment Number: {detail.shipment_number || '-'}</div>
                <div style={{ fontSize: 11 }}>Order Number: {detail.order_number || '-'}</div>
                <div style={{ fontSize: 11 }}>PO Number: {detail.po_number || '-'}</div>
                <div style={{ fontSize: 11 }}>Order ID: {detail.id || '-'}</div>
                <div style={{ fontSize: 11 }}>Trip ID: {detail.trip_id || '-'}</div>
                <div style={{ fontSize: 11 }}>Driver Name: {detail.trip && detail.trip.driver ? detail.trip.driver.name : '-'}</div>
                <div style={{ fontSize: 11 }}>Vehicle Number: {detail.trip && detail.trip.vehicle ? detail.trip.vehicle.license_plate : '-'}</div>
                <br/>
                <div style={{ textAlign: 'right', fontSize: 10 }}>
                  <div>Printed by {user.name}</div>
                  <div>{moment().format('YYYYMMDD')}</div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Can>
    );
  }
}

// Map state to props
const mapstp = (state) => {
  const { field, user } = state;

  return {
    detail: field.detail,
    user: user.data
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ toggleHeader }, dispatch)
}

export default connect(mapstp, mapdtp)(PrintQRScreen)
