import { combineReducers } from 'redux';
import { SUMMARY } from '../actions/issue';
import { USER_IMPERSONATE } from '../actions/user';

const summary = (state = {}, action) => {
  switch (action.type) {
    case SUMMARY + '_SUCCESS':
      const { data } = action.payload;
      if (data && data.length > 0) {
        let tempArr = [];
        data.forEach(function (issue) {
          if (issue.reason_id) {
            let obj = { reason_id: issue.reason_id, description: issue.description, count: issue[issue.reason_id] };
            tempArr.push(obj);
          }
        })
        return tempArr;
      }
      return state;
    case USER_IMPERSONATE + '_SUCCESS':
      return {}
    default:
      return state;
  }
}

export default combineReducers({ summary });