/* eslint-disable no-unused-vars, react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {getLists} from '../actions/contact';

const GetContact = ({getLists}) => {
    useEffect(() => {
        getLists();
    }, [])

    return null;
}

const mapstp = (state) => {
    return state.contact;
}

const mapdtp = (dispatch) => {
    return bindActionCreators({getLists}, dispatch);
}

export default connect(mapstp, mapdtp)(GetContact);
