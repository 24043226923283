import CryptoJS from 'crypto-js'

export const validateQR = (data, key) => {
  if (data && data.data && data.data.value && data.signature) {
    // if the data has data value and signature
    const signature = CryptoJS.HmacSHA256(JSON.stringify({value: data.data.value}), key).toString(CryptoJS.enc.Hex);
    if (signature.toLowerCase() === data.signature.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
