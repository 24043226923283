import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TripLists from './TripLists';
import Can from '../../components/Can';
import { permissions } from '../../routes';

const TabPanel = (props) => {
  const { children } = props;
  return children;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const FullWidthTabs = (props) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  }

  const {pathname} = props.location;

  return (
    <Can redirect permissions={permissions(pathname)}>
      <div style={{heigth: '100%'}}>
        <AppBar position="fixed" color="default" className="ritase-tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example">
            <Tab className="ritase-menu-button ritase-tab" label="Approval" {...a11yProps(0)} />
            <Tab className="ritase-menu-button ritase-tab" label="History" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          className="w-100 ritase-swipeable"
          onChangeIndex={handleChangeIndex}>
          <TripLists status="submitted,rejected" trip="trip_approval" index={0}/>
          <TripLists status="approved" trip="trip_history" index={1}/>
        </SwipeableViews>
      </div>
    </Can>
  );
}

export default FullWidthTabs;
