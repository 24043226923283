import React, { Component } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { getLists as getContacts } from '../../actions/contact';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFilterVisibility, onChangeCompanyShipperId, onChangeStatusId, onChangeFilter } from '../../actions/field';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DatePicker from 'react-date-picker';

class Filter extends Component {
  state = {
    status_id: '',
    company_shipper_id: '',
    company_transport_id: '',
    dateFrom: '',
    dateTo: '',
    license_plate: '',
    destination_contact_id: '',
    searchContact: '',
  }

  componentDidMount () {
    const { status_id, company_shipper_id, company_transport_id, dateFrom, dateTo, license_plate } = this.props;

    this.setState({
      status_id,
      company_shipper_id,
      company_transport_id,
      dateFrom,
      dateTo,
      license_plate,
    });
  }

  hide () {
    this.props.setFilterVisibility(false)
  }

  reset () {
    this.setState({status_id: '', company_shipper_id: '', company_transport_id: '', dateFrom: '', dateTo: '', license_plate: '', destination_contact_id: ''});
  }

  terapkan () {
    this.props.onChangeFilter(this.state);
    setTimeout(() => {
      this.props.onSubmit(this.state);
    }, 300);
    this.hide();
  }

  loadContacts = (searchContact, callback) => {
    setTimeout(() => {
      this.props.getContacts('&search='+searchContact);
      callback(this.props.contacts)
    }, 1000)

  }

  handleInputContactChange = (newValue: string) => {
    const searchContact = newValue;
    this.setState({ searchContact });
    return searchContact;
  };

  render() {
    const shipperStyles = {
      menu: styles => ({
        ...styles, height: 100,
      }),
      menuList: styles => ({
        ...styles, height: 100,
      }),
    };

    return (
      <Modal animation={true} className="p-0" dialogClassName="modal-bottom" show={this.props.show} onHide={() => this.hide()}>
        <Modal.Header closeButton>
          <Modal.Title>Pengaturan Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'scroll', overflowX: 'hidden'}}>
            <div className="form-group">
              <label className="control-label">Status</label>
              <Select placeholder="Semua" value={this.state.status_id} onChange={(status) => this.setState({status_id: status})} options={this.props.statuses} isClearable styles={shipperStyles} />
            </div>
            <div className="form-group">
              <label className="control-label">Shipper</label>
              <Select placeholder="Semua shipper" value={this.state.company_shipper_id} onChange={(status) => this.setState({company_shipper_id: status})} options={this.props.shippers} isClearable styles={shipperStyles} />
            </div>
            <div className="form-group">
              <label className="control-label">Transporter</label>
              <Select placeholder="Semua transporter" value={this.state.company_transport_id} onChange={(status) => this.setState({company_transport_id: status})} options={this.props.transporters} isClearable styles={shipperStyles} />
            </div>
            <div className="form-group">
              <label className="control-label">Destination</label>
              <AsyncSelect placeholder="Semua destinasi" value={this.state.destination_contact_id} onChange={(status) => this.setState({destination_contact_id: status})} loadOptions={this.loadContacts} isClearable defaultOptions onInputChange={this.handleInputContactChange} styles={shipperStyles} />
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="form-group">
                <label className="control-label mb-0">Ready Time</label>
                <div className="row">
                  <div className="col-6">
                    {/* <input type="date" name="dateFrom" id="" value={this.state.dateFrom} className="form-control" onChange={(e) => this.setState({dateFrom: e.target.value})}/> */}
                    <DatePicker
                      format="dd/MM/y"
                      onChange={(e) => this.setState({dateFrom: e})}
                      value={this.state.dateFrom}
                    />
                    {/* <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="From"
                      format="MM/dd/yyyy"
                      value={this.state.dateFrom}
                      onChange={(dateFrom) => this.setState({dateFrom})}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    /> */}
                  </div>
                  <div className="col-6">
                    {/* <input type="date" name="dateTo" id="" value={this.state.dateTo}  className="form-control" onChange={(e) => this.setState({dateTo: e.target.value})}/> */}
                    <DatePicker
                      format="dd/MM/y"
                      onChange={(e) => this.setState({dateTo: e})}
                      value={this.state.dateTo}
                    />
                    {/* <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="To"
                      format="MM/dd/yyyy"
                      value={this.state.dateTo}
                      onChange={(dateTo) => this.setState({dateTo})}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    /> */}
                  </div>
                </div>
              </div>
            </MuiPickersUtilsProvider>
            <div className="form-group">
              <label className="control-label">No Pol</label>
              <input type="text" className="form-control" value={this.state.license_plate} onChange={((e) => this.setState({license_plate: e.target.value}))}/>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Col><button className="btn btn-default btn-block" onClick={() => this.reset()}>Reset</button></Col>
          <Col><button className="btn btn-primary btn-block" onClick={() => this.terapkan()}>Terapkan</button></Col>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapstp = (state) => {
  const { lists } = state.shipper;
  const { filter } = state.field;
  const contacts = state.contact.lists.map(x => {
    return {
      value: x.id,
      label: x.name
    }
  });
  const shippers = lists.filter(x => x.company_type === 'client').map(x => {
    return {
      value: x.id,
      label: x.name,
    };
  });
  const transporters = lists.filter(x => x.company_type === 'transport').map(x => {
    return {
      value: x.id,
      label: x.name,
    };
  });

  const statuses = [
    {value: 40, label: 'Unloaded'},
    {value: 50, label: 'POD'},
  ];

  let status_id = '';
  let company_shipper_id = '';
  let company_transport_id = '';
  let dateFrom = null;
  let dateTo = null;
  let license_plate = filter.license_plate ? filter.license_plate : '';

  if (filter.status_id) {
    var status = statuses.filters(x => x.value === filter.status_id);
    status_id = status.length > 0 ? status[0]: '';
  }

  if (filter.company_shipper_id) {
    var shipper = shippers.filters(x => x.value === filter.company_shipper_id);
    company_shipper_id = shipper.length > 0 ? shipper[0]: '';
  }

  if (filter.company_transport_id) {
    var transport = transporters.filters(x => x.value === filter.company_transport_id);
    company_transport_id = transport.length > 0 ? transport[0]: '';
  }

  if (filter.ready_time) {
    const dates = filter.ready_time.split(',');
    dateFrom = dates[0];
    dateTo = dates[1];
  }

  return {
    show: state.field.filterShow,
    shippers,
    transporters,
    statuses,
    status_id,
    company_shipper_id,
    company_transport_id,
    dateFrom,
    dateTo,
    license_plate,
    contacts,
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({
    setFilterVisibility,
    onChangeStatusId,
    onChangeCompanyShipperId,
    onChangeFilter,
    getContacts,
  }, dispatch);
}

export default connect(mapstp, mapdtp)(Filter)
