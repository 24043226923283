import { GET_TRIPS, GET_TRIP_DETAIL, SET_ORDER, CLOSE_ORDER, SET_TRIP, GET_TRIPS_PARAM, UPDATE_PACKAGES } from '../actions/w_trip';

const initialState = {
  isLoading: false,
  trips: [],
  trip: undefined,
  errorMessage: undefined,
  order: undefined
}

export default (state = initialState, action) => {
  let trips = [];
  let trip = undefined;
  let order = undefined;
  const {error, payload} =  action;
  switch (action.type) {
    case GET_TRIPS :
      return {
        ...state,
        trips,
        isLoading: true
      };
    case GET_TRIPS + '_FAIL':
      return {
        ...state,
        trips,
        errorMessage: error.data,
        isLoading: false
      };
    case GET_TRIPS + '_SUCCESS' :
      trips = payload.data.rows;
      return {
        ...state,
        trips,
        isLoading: false
      };
    case GET_TRIP_DETAIL :
      return {
        ...state,
        trip,
        isLoading: true
      };
    case GET_TRIP_DETAIL + '_FAIL':
      return {
        ...state,
        trip,
        errorMessage: error.data,
        isLoading: false
      };
    case GET_TRIP_DETAIL + '_SUCCESS' :
      trip = payload.data;
      if (trip && trip.orders) {
        trip.orders = trip.orders.map(order => {
          if (order.packages) {
            order.packages = order.packages.map(pkg => ({
              ...pkg,
              updatedQuantity: pkg.quantity
            }));
          }
          return order;
        });
      }
      return {
        ...state,
        trip,
        isLoading: false
      };
    case SET_ORDER :
      if (action.order) {
        order = action.order;
      }

      return {
        ...state,
        order
      };
    case SET_TRIP :
      if (action.trip) {
        trip = action.trip;
      }

      return {
        ...state,
        trip
      };
    case CLOSE_ORDER :
      return {
        ...state,
        order: undefined
      };
    case GET_TRIPS_PARAM :
      return {
        ...state,
        trips,
        isLoading: true
      };
    case GET_TRIPS_PARAM + '_FAIL':
      return {
        ...state,
        trips,
        errorMessage: error.data,
        isLoading: false
      };
    case GET_TRIPS_PARAM + '_SUCCESS' :
      trips = payload.data.rows;
      return {
        ...state,
        trips,
        isLoading: false
      };
    case GET_TRIPS_PARAM + '_RESET':
      return initialState;
    case UPDATE_PACKAGES :
      return {
        ...state,
        packageUpdated: false,
        isLoading: true
      };
    case UPDATE_PACKAGES + '_FAIL':
      return {
        ...state,
        errorMessage: error.data,
        isLoading: false
      };
    case UPDATE_PACKAGES + '_SUCCESS' :
      return {
        ...state,
        packageUpdated: true,
        isLoading: false
      };
    default:
      return state;
  }
}
