export const GET = 'CAPACITY_OFFER_GET';
export const DETAIL = 'CAPACITY_OFFER_DETAIL';
export const SET_SORT = 'CAPACITY_OFFER_SET_SORT';
export const SET_FILTER = 'CAPACITY_OFFER_SET_FILTER';
export const CLEAR_LIST = 'CAPACITY_OFFER_CLEAR_LIST';

export const getLists = (offset = 0, params = {}, sort = {}) => {
  if (params.start && params.end) {
    params.available_date = `${params.start},${params.end}`
    delete params.start
    delete params.end
  } else {
    const start = new Date()
    const end = (new Date())
    end.setFullYear(4000, 12, 30);

    params.available_date = `${start.toISOString()},${end.toISOString()}`;
  }
  return {
    type: GET,
    payload: {
      request: {
        url: 'transport_capacity_offers',
        method: 'get',
        params: {
          limit: 10,
          offset,
          ...params,
          ...sort,
          status: 'OPEN',
          format: 'list-default'
        }
      }
    }
  }
}

export const getDetail = (id) => {
  return {
    type: DETAIL,
    payload: {
      request: {
        url: 'transport_capacity_offers/'+id,
        method: 'get',
        params: {
          populate: [
            'origin',
            'destination',
            'vehicle',
            'open_order_offers',
            'type',
            'company_transport.company_rating.badge_level',
            'creator',
            'driver'
          ].join(',')
        }
      }
    }
  }
}

export const setSort = (column, type = 'DESC') => {
  return {
    type: SET_SORT,
    payload: {
      sort: column,
      asc: type
    }
  }
}

export const setFilter = (filters) => {
  return {
    type: SET_FILTER,
    payload: filters
  }
}

export const clear = (filters) => {
  return {
    type: CLEAR_LIST,
    payload: filters
  }
}
