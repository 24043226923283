import React, { Component } from 'react'
import { IonContent } from '@ionic/react';
import { AppBar, Button, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, TextField, Toolbar, Typography } from '@material-ui/core';
import { ArrowBack, CheckBox as CheckBoxIcon  } from '@material-ui/icons';
import { Col, Container, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleHeader } from '../../actions';
import { updateAddPrices, getAreas } from '../../actions/all_prices';
import { get as getVehicleType } from '../../actions/vehicle_type';
import { get as getCompanies } from '../../actions/companies';
import Can from '../../components/Can';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import debounce from 'lodash/debounce'
import { permissions } from '../../routes';
import "./style.scss";

class AddEditPriceAllPriceScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: null,
      miscField: false,
      weightField: false,
      multidropField: false,
      premiumRateField: false,
      form: {
        company: null,
        vehicle_type: null,
        origin_area: null,
        destination_area: null,
        transport_type: null,
        price: null,
        default_expenses: null,
        default_other_fees: null,
        cbm_price: null,
        min_cbm: null,
        kg_price: null,
        min_kg: null,
        same_city_drop_price: null,
        diff_city_drop_price: null,
        premium_price: null
      }
    }
  }
  
  componentDidMount() {
    const addAction = this.props.location.pathname.includes('tambah')
    if (!addAction && !this.props.detail.id) {
      return this.goBack();
    }
    if (!addAction) {
      this.setInitialValue(addAction)
    } else {
      this.setState({action: addAction ? 'add' : 'edit'})
    }
    this.props.toggleHeader(false);
  }

  setInitialValue(addAction) {
    this.setState(prev => ({
      ...prev,
      miscField: this.props.detail.default_expenses > 0 || this.props.detail.default_other_fees > 0,
      weightField: this.props.detail.kg_price > 0 || this.props.detail.cbm_price > 0,
      multidropField: this.props.detail.same_city_drop_price > 0 || this.props.detail.diff_city_drop_price > 0,
      premiumRateField: this.props.detail.premium_price > 0,
      action: addAction ? 'add' : 'edit',
      form: {
        company: {
          value: this.props.detail.company.id,
          label: this.props.detail.company.name
        },
        vehicle_type: {
          value: this.props.detail.vehicle_type.id,
          label: this.props.detail.vehicle_type.name
        },
        origin_area: {
          value: this.props.detail.origin_area,
          label: this.props.detail.origin_area.name
        },
        destination_area: {
          value: this.props.detail.destination_area,
          label: this.props.detail.destination_area.name
        },
        transport_type: {
          value: this.props.detail.transport_type,
          label: this.props.detail.transport_type
        },
        price: this.props.detail.price,
        default_expenses: this.props.detail.default_expenses,
        default_other_fees: this.props.detail.default_other_fees,
        cbm_price: this.props.detail.cbm_price,
        min_cbm: this.props.detail.min_cbm,
        kg_price: this.props.detail.kg_price,
        min_kg: this.props.detail.min_kg,
        same_city_drop_price: this.props.detail.same_city_drop_price,
        diff_city_drop_price: this.props.detail.diff_city_drop_price,
        premium_price: this.props.detail.premium_price,
      }
    }))
  }

  handleShowInputField(field) {
    this.setState({[field] : !this.state[field]})
  }

  handleChangeForm = (field, value) => {
    this.setState(prev => ({
      ...prev,
      form: {
        ...prev.form,
        [field]: value
      }
    }))
  }

  backToList = () => {
    Swal.close()
    this.props.history.push({
      pathname: this.props.location.state.dataPage.prefix === 'all_price_transporter' ? '/all-price/transporter-prices' : '/all-price/client-prices'
    })
  }

  handleSubmit = () => {
    const { form } = this.state
    const payload = {}
    if (form.company && form.company.value) {
      payload.company_id = form.company.value
    }
    if (form.vehicle_type && form.vehicle_type.value) {
      payload.vehicle_type_id = form.vehicle_type.value
    }
    if (form.origin_area && form.origin_area.value.id) {
      if (form.origin_area.value.administrative_divisions.length) {
        payload.origin_area_id = form.origin_area.value.id
      } else {
        return;
      }
    }
    if (form.destination_area && form.destination_area.value.id) {
      if (form.destination_area.value.administrative_divisions.length) {
        payload.destination_area_id = form.destination_area.value.id
      } else {
        return;
      }
    }
    if (form.transport_type && form.transport_type.value) {
      payload.transport_type = form.transport_type.value
    }
    payload.price = form.price || 0;
    payload.default_expenses = form.default_expenses || 0;
    payload.default_other_fees = form.default_other_fees || 0;
    payload.cbm_price = form.cbm_price || 0;
    payload.min_cbm = form.min_cbm || 0;
    payload.kg_price = form.kg_price || 0;
    payload.min_kg = form.min_kg || 0;
    payload.same_city_drop_price = form.same_city_drop_price || 0;
    payload.diff_city_drop_price = form.diff_city_drop_price || 0;

    this.checkForceAllowed(payload)
  }

  checkForceAllowed = async (payload) => {
    const response = await this.props.updateAddPrices({ payload })
    const { data } = response.payload

    if (data.errorMessage) {
      if (data.canForce) {
        Swal.fire({
        title: 'Konfirmasi',
        text: data.errorMessage,
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
            this.submitForm(payload, true)
          }
        })
      } else {
        Swal.fire({
        title: 'Error',
        text: data.errorMessage,
        type: 'error',
        showCancelButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
            this.backToList();
          }
        })
      }
    } else {
      this.submitForm(payload)
    }
  }
      
  submitForm = async (payloadData, force) => {
    const payload = force ? {
      ...payloadData,
      force: true
    } : {
      ...payloadData
    }
    const response = await this.props.updateAddPrices({
      payload: payload
    })
    if (this.state.form.premium_price !== response.payload.data.premium_price) {
      await this.props.updateAddPrices({
        payload: {
          premium_price: this.state.form.premium_price
        },
        id: response.payload.data.id
      })
    }
    Swal.fire({
      type: 'success',
      title: 'Success',
      text: `Data berhasil ${this.state.action==='add' ? 'ditambahkan' : 'diperbaharui'}.`,
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(result=>{
      if (result.value) {
        this.backToList();
      }
    })
  }

  componentWillUnmount() {
    this.props.toggleHeader(true);
    if (this.props.history.action === 'POP') {
      this.backToList()
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }
  
  NumberFormatCustom = React.forwardRef(
    function NumberFormatCustom(props, ref) {
      const { onChange, ...other } = props;
  
      return (
        <NumberFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator={'.'}
          decimalSeparator={','}
          isNumericString
        />
      );
    },
  );

  render() {
    const { loading, transport_type, location } = this.props;
    const { miscField, multidropField, premiumRateField, weightField, form, action } = this.state

    if (!location.state || loading) {
      return (
        <Can redirect permissions={permissions(this.props.location.pathname)}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton edge="start" onClick={() => this.goBack()} style={{color: 'white'}} aria-label="Menu">
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" style={{color: 'white'}}>
                {action && action === 'add' ? 'Tambah ' : 'Edit '} Harga
              </Typography>
            </Toolbar>
          </AppBar>
        <Container>
          <Row>
            <Col>
              <center>
              {loading ? <CircularProgress /> : <h2 className="text-danger">
                Terjadi kesalahan saat menampilkan halaman.
              </h2>}
              </center>
            </Col>
          </Row>
        </Container>
      </Can>
      )
    }  
    
    return (
      <Can redirect permissions={permissions(this.props.location.pathname)}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => this.goBack()}
              style={{ color: "white" }}
              aria-label="Menu"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" style={{ color: "white" }}>
                {action && action === 'add' ? 'Tambah ' : 'Edit '} Harga
            </Typography>
          </Toolbar>
        </AppBar>
        <IonContent style={{ "--background": "#F0F6F6" }}>
          <Container fluid className='container-detail'>
            <Card className="card-all-price">
              <CardContent>
                <DropdownFields handleChangeForm={this.handleChangeForm} form={form} dataPage={location.state.dataPage}/>

                <div className="mb-2">
                  <div>Transport Type</div>
                  <Select className="mb-3" placeholder="Transport Type" options={transport_type} value={form.transport_type} onChange={(value)=>this.handleChangeForm('transport_type', value)} isClearable style={{textTransform: 'capitalize'}}/>
                </div>

                <div className="mb-2">
                  <div>Harga</div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                      inputComponent: this.NumberFormatCustom
                    }}
                    value={form.price} onChange={(e)=>this.handleChangeForm('price', e.target.value)}
                  />
                </div>

                <FormControlLabel
                  control={<Checkbox checked={miscField} onChange={()=>this.handleShowInputField('miscField')}/>}
                  label="Uang Jalan & Biaya Lain-lain"
                />

                {
                  miscField && (
                    <div>
                      <div className="mb-2">
                        <div>Uang Jalan</div>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                            inputComponent: this.NumberFormatCustom
                          }}
                          value={form.default_expenses} onChange={(e)=>this.handleChangeForm('default_expenses', e.target.value)}
                        />
                      </div>

                      <div className="mb-2">
                        <div>Biaya Lain-lain</div>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                            inputComponent: this.NumberFormatCustom
                          }}
                          value={form.default_other_fees} onChange={(e)=>this.handleChangeForm('default_other_fees', e.target.value)}
                        />
                      </div>
                    </div>
                  )
                }

                <br/>
                <FormControlLabel
                  control={<Checkbox checked={weightField} onChange={()=>this.handleShowInputField('weightField')}/>}
                  label="Biaya CBM & Biaya Kg"
                />

                {
                  weightField && (
                    <div>
                      <div className="mb-2">
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <div>Biaya per CBM</div>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                inputComponent: this.NumberFormatCustom
                              }}
                              value={form.cbm_price} onChange={(e)=>this.handleChangeForm('cbm_price', e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={5}>                     
                            <div>Berlaku Min.</div>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">CBM</InputAdornment>,
                              }}
                              type='number'
                              value={form.min_cbm} onChange={(e)=>this.handleChangeForm('min_cbm', e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </div>
                
                      <div className="mb-2">
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <div>Biaya per Kg</div>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                inputComponent: this.NumberFormatCustom
                              }}
                              value={form.kg_price} onChange={(e)=>this.handleChangeForm('kg_price', e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={5}>                     
                            <div>Berlaku Min.</div>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                              }}
                              type='number'
                              value={form.min_kg} onChange={(e)=>this.handleChangeForm('min_kg', e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  )
                }
                
                <br/>
                <FormControlLabel
                  control={<Checkbox checked={multidropField} onChange={()=>this.handleShowInputField('multidropField')}/>}
                  label="Multidrop Dalam & Luar Kota"
                />

                {
                  multidropField && (
                    <div>
                      <div className="mb-2">
                        <div>Biaya Multidrop Dalam Kota</div>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                            inputComponent: this.NumberFormatCustom
                          }}
                          value={form.same_city_drop_price} onChange={(e)=>this.handleChangeForm('same_city_drop_price', e.target.value)}
                        />
                      </div>

                      <div className="mb-2">
                        <div>Biaya Multidrop Luar Kota</div>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                            inputComponent: this.NumberFormatCustom
                          }}
                          value={form.diff_city_drop_price} onChange={(e)=>this.handleChangeForm('diff_city_drop_price', e.target.value)}
                        />
                      </div>
                    </div>
                  )
                }
                
                <br/>
                <FormControlLabel
                  control={<Checkbox checked={premiumRateField} onChange={()=>this.handleShowInputField('premiumRateField')}/>}
                  label="Premium Rate"
                />
                
                {
                  premiumRateField && (
                    <div>
                      <div className="mb-2">
                        <div>Premium Rate</div>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                            inputComponent: this.NumberFormatCustom
                          }}
                          value={form.premium_price} onChange={(e)=>this.handleChangeForm('premium_price', e.target.value)}
                        />
                      </div>
                    </div>
                  )
                }

              <Button fullWidth variant="contained" className='button-solid-primary' onClick={this.handleSubmit}>
                {action && action === 'add' ? 'Tambah' : 'Edit'}
              </Button>
                
              </CardContent>
            </Card>
          </Container>
        </IonContent>
      </Can>
    );
  }
}

const DropdownFields = ({handleChangeForm, form, dataPage}) => {

  const dispatch = useDispatch();

  const loadVehicleType = debounce((inputValue, callback) => {
    const query = `searchFields=name&search=${inputValue}&format=dropdown`
    dispatch(getVehicleType(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d.id, label: d.name })).sort((a,b)=>a.label>b.label?1:-1) : []
      callback([
        ...data
      ])
    })
  }, 500);

  const loadTransporter = debounce((inputValue, callback) => {
    const query = `searchFields=name&search=${inputValue}&company_type=transport&fields=id,name&sort=name`
    dispatch(getCompanies(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d.id, label: d.name })) : []
      callback([
        ...data
      ])
    })
  }, 500);

  const loadShipper = debounce((inputValue, callback) => {
    const query = `searchFields=name&search=${inputValue}&company_type=client&fields=id,name&sort=name`
    dispatch(getCompanies(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d.id, label: d.name })) : []
      callback([
        ...data
      ])
    })
  }, 500);

  const loadOrigin = debounce((inputValue, callback) => {
    const query = `searchFields=name&limit=15&search=${inputValue}&populate=administrative_divisions`
    dispatch(getAreas(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d, label: d.name })) : []
      callback([
        ...data
      ])
    })
  }, 500);
  
  const loadDestination = debounce((inputValue, callback) => {
    const query = `searchFields=name&limit=15&search=${inputValue}&populate=administrative_divisions`
    dispatch(getAreas(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d, label: d.name })) : []
      callback([
        ...data
      ])
    })
  }, 500);

  return(
    <div>
      <div className="mb-2">
        <div>Perusahaan</div>
        <AsyncSelect
          placeholder="Perusahaan"
          cacheOptions
          defaultOptions
          value={form.company}
          loadOptions={dataPage.params.company_type==='transport' ? loadTransporter : loadShipper}
          onChange={(val) => handleChangeForm('company', val)}
          getOptionLabel={({ label }) => label}
          getOptionValue={({ value }) => value}
          isClearable
        />
      </div>
      
      <div className="mb-2">
        <div>Unit</div>
        <AsyncSelect
          placeholder="Unit"
          cacheOptions
          defaultOptions
          value={form.vehicle_type}
          loadOptions={loadVehicleType}
          onChange={(val) => handleChangeForm('vehicle_type', val)}
          getOptionLabel={({ label }) => label}
          getOptionValue={({ value }) => value}
        />
      </div>
      
      <div className="mb-2">
        <div>Asal</div>
        <AsyncSelect
          placeholder="Asal"
          cacheOptions
          defaultOptions
          value={form.origin_area}
          loadOptions={loadOrigin}
          onChange={(val) => handleChangeForm('origin_area', val)}
          getOptionLabel={({ value, label }) => {
            return (
              <div>
                <CheckBoxIcon color={value.administrative_divisions ? value.administrative_divisions.length ? 'primary' : 'error' : 'primary'}/>
                {label}
              </div>
            )
          }}
          getOptionValue={({ value }) => value}
        />
        {
          form && form.origin_area && form.origin_area.value.administrative_divisions && !form.origin_area.value.administrative_divisions.length && (
            <FormHelperText error>Area administratif belum terisi</FormHelperText>
          )
        }
      </div>
      
      <div className="mb-2">
        <div>Tujuan</div>
        <AsyncSelect
          placeholder="Tujuan"
          cacheOptions
          defaultOptions
          value={form.destination_area}
          loadOptions={loadDestination}
          onChange={(val) => handleChangeForm('destination_area', val)}
          getOptionLabel={({ value, label }) => {
            return (
              <div>
                <CheckBoxIcon color={value.administrative_divisions ? value.administrative_divisions.length ? 'primary' : 'error' : 'primary'}/>
                {label}
              </div>
            )
          }}
          getOptionValue={({ value }) => value}
        />
        {
          form && form.destination_area && form.destination_area.value.administrative_divisions && !form.destination_area.value.administrative_divisions.length && (
            <FormHelperText error>Area administratif belum terisi</FormHelperText>
          )
        }
      </div>
    </div>
  )
}

const mapstp = (state, props) => {
  const {dataPage} = props.location.state || {};
  const {prefix} =  dataPage || "";

  const transport_type_array = ['Land', 'Sea', 'Air']
  const transport_type = transport_type_array.map((item)=> {
    return {
      value: item,
      label: item
    }
  })

  return {
    detail: state[prefix]?.detail,
    loading: state[prefix]?.loading,
    transport_type
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ toggleHeader, updateAddPrices, getCompanies, getVehicleType, getAreas }, dispatch)
}

export default connect(mapstp, mapdtp)(AddEditPriceAllPriceScreen)
