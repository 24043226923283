import React, {Component} from 'react';
import LoginScreen from './screens/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import ScanQRScreen from './screens/ScanQRScreen';
import FieldScreen from './screens/FieldScreen';
import FieldDetailScreen from './screens/FieldDetailScreen';
import Attendance from './screens/AttendanceScreen';
import TripMonitoring from './screens/TripMonitoring';
import TripMonitoringDetail from './screens/TripMonitoring/DetailScreen';
import TripMonitoringMap from './screens/TripMonitoring/TripMap';
import LostScreen from './screens/LostScreen';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import $ from 'jquery';
import icodashboard from './assets/nav-dashboard.png';
import icomonitor from './assets/nav-ordermonitoring.png';
import icoattendance from './assets/nav-attendance.png';
import icoallprice from './assets/nav-allprice.png';
import icodot from './assets/icodot.png';
import capacityoffer from './assets/nav-capacityoffer.svg';
import p from './permissions';
import PrintQRScreen from './screens/PrintQRScreen';
import { DetailCapacityOfferScreen, ListCapacityOfferScreen } from './screens/CapacityOfferScreen';
import { PriceListAllPriceScreen,PriceDetailAllPriceScreen, ViewCoverageAllPriceScreen, AddEditPriceAllPriceScreen } from './screens/AllPriceScreen';
import AreaScreen from './screens/AreaScreen';
import DetailAreaScreen from './screens/AreaScreen/DetailScreen';
import ManageAreaScreen from './screens/AreaScreen/ManageScreen';

export const routes = [
  {
    title: 'Dashboard',
    ico: icodashboard,
    path: '/',
    hide: true,
    component: HomeScreen,
    exact: true
  },
  {
    title: 'Looking for Shipment',
    path: '/capacity-offer',
    ico: capacityoffer,
    component: ListCapacityOfferScreen,
    exact: true,
    permissions: [
      p.capacity_offer
    ]
  },
  {
    title: 'Looking for Shipment Detail',
    path: '/capacity-offer-detail',
    ico: capacityoffer,
    component: DetailCapacityOfferScreen,
    hide: true,
    exact: true,
    permissions: [
      p.capacity_offer
    ]
  },
  {
    title: 'POD Monitoring',
    path: '/field',
    ico: icomonitor,
    component: FieldScreen,
    exact: true,
    permissions: [
      p.pod_monitoring
    ],
  },
  {
    title: 'Scan QR Code',
    ico: icodashboard,
    path: '/scan-qr-code',
    component: ScanQRScreen,
    hide: true,
    exact: true,
    permissions: [
      p.pod_monitoring
    ],
  },
  {
    title: 'Print QR Code',
    path: '/field-print-qr',
    component: PrintQRScreen,
    hide: true,
    permissions: [
      p.pod_monitoring
    ],
  },
  {
    title: 'POD Monitoring Detail',
    path: '/field-detail',
    component: FieldDetailScreen,
    hide: true,
    permissions: [
      p.pod_monitoring
    ],
  },
  /**
  * Attendance routes
  * @type {Array}
  */
  {
    title: 'Attendance',
    path: '/attendance',
    ico: icoattendance,
    component: Attendance,
    permissions: [
      p.attendance_planner,
      p.attendance_field,
    ],
  },
  /**
  * Trip Monitoring routes
  * @type {Array}
  */
  {
    title: 'Trip Monitoring',
    path: '/trip-monitoring',
    ico: icomonitor,
    component: TripMonitoring,
    permissions: [
      p.trip_monitoring
    ],
  },
  {
    title: 'Trip Monitoring Detail',
    path: '/trip-monitoring-detail',
    hide: true,
    component: TripMonitoringDetail,
    permissions: [
      p.trip_monitoring
    ],
  },
  {
    title: 'Trip Monitoring Map',
    path: '/trip-monitoring-detail-map',
    hide: true,
    component: TripMonitoringMap,
    permissions: [
      p.trip_monitoring
    ],
  },
  /**
  * All Prices routes
  * @type {Array}
  */
   {
    title: 'All Price',
    ico: icoallprice,
    path: '/all-price',
    permissions: [
      p.all_price
    ],
    // This array will be use only for listing at the sidebar, and wont be register in react route
    child: [
      {
        title: 'Area',
        path: '/all-price/area',
        ico: icodot,
        permissions: [
          p.all_price
        ],
      },
      {
        title: 'Transporter',
        path: '/all-price/transporter-prices',
        ico: icodot,
        permissions: [
          p.all_price
        ],
      },
      {
        title: 'Client',
        path: '/all-price/client-prices',
        ico: icodot,
        permissions: [
          p.all_price
        ],
      }
    ]
  },
  // all price routes that will be register to react route
  {
    title: 'Area',
    path: '/all-price/area',
    hide: true,
    component: AreaScreen,
    permissions: [
      p.all_price
    ],
  },
  {
    title: 'Detil Area',
    path: '/all-price-detail-area',
    hide: true,
    exact: true,
    component: DetailAreaScreen,
    permissions: [
      p.all_price
    ],
  },
  {
    title: 'Tambah Area',
    path: '/all-price-add-area',
    hide: true,
    exact: true,
    component: ManageAreaScreen,
    permissions: [
      p.all_price
    ],
  },
  {
    title: 'Tambah Area',
    path: '/all-price-update-area',
    hide: true,
    exact: true,
    component: ManageAreaScreen,
    permissions: [
      p.all_price
    ],
  },
  {
    title: 'Transporter',
    path: '/all-price/transporter-prices',
    hide: true,
    component: PriceListAllPriceScreen,
    permissions: [
      p.all_price
    ],
  },
  {
    title: 'Client',
    path: '/all-price/client-prices',
    hide: true,
    component: PriceListAllPriceScreen,
    permissions: [
      p.all_price
    ],
  },
  {
    title: 'Detail Harga',
    path: '/all-price/price-detail',
    hide: true,
    component: PriceDetailAllPriceScreen,
    permissions: [
      p.all_price
    ],
  },
  {
    title: 'View Coverage',
    path: '/all-price/view-coverage',
    hide: true,
    component: ViewCoverageAllPriceScreen,
    permissions: [
      p.all_price
    ],
  },
  {
    title: 'Tambah Harga',
    path: '/all-price/tambah-harga',
    hide: true,
    component: AddEditPriceAllPriceScreen,
    permissions: [
      p.all_price
    ],
  },
  {
    title: 'Edit Harga',
    path: '/all-price/edit-harga',
    hide: true,
    component: AddEditPriceAllPriceScreen,
    permissions: [
      p.all_price
    ],
  },
]

export const publicRoutes = [
  {
    title: 'Lost screens',
    path: '/403',
    component: LostScreen,
  },
  {
    title: 'Login',
    path: '/login',
    component: LoginScreen,
  },
];

export const permissions = (pathname) => {
  const route = routes.filter(x => x.path === pathname);
  if (route.length > 0) {
    return route[0].permissions;
  }
  return route;
}

class Public extends Component {
  render () {
    return publicRoutes.map((route, key) => <Route key={key} {...route} {...this.props} />);
  }
}

class Private extends Component {
  render () {
    if (!this.props.token) {
      return (
        <Redirect to={{pathname: '/login', state: {from: this.props.location}}} />
      );
    }
    $('body').removeClass('splash-bg');
    return routes.map((route, key) => <Route key={key} {...route} />);
  }
}

const mapstp = (state) => {
  let {token} = state.user
  return {
    token,
  }
}

export const PublicRoutes = connect(mapstp)(Public)
export const PrivateRoutes = connect(mapstp)(Private)
