import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show, update, UPDATE, setDetailReport } from '../../actions/trip';
import { logout } from '../../actions/user';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import SimpleLineIcon from 'react-simple-line-icons';
import Swal from 'sweetalert2';
import BackButton from '../../components/BackButton';
import ReportList from './ReportList';
import moment from 'moment';
import errorHandler from '../../errorHandler';
import Can from '../../components/Can';
import { permissions } from '../../routes';
import ls from 'local-storage';
import Filter from './Filter';
import _ from 'lodash'

const DetailScreen = props => {
  const [ showPrev, setShowPrev] = useState(false);
  const [ search, setSearch] = useState('');
  const [ showFilter, setShowFilter ] = useState(false);
  const [status_id, setStatusId] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const formatDate = (date, type='startOf') => {
    return moment.utc(moment(date)[type]('day')).format();
  }

  let { id, company_shipper, summary, created_at, status, company_template_report } = props.detail;
  if (!id) {
    const detail = ls.get('TRIP_DETAIL');
    id = detail.id;
    company_shipper = detail.company_shipper;
    summary = detail.summary;
    created_at = detail.created_at;
    status = detail.status;
  } else {
    ls.set('TRIP_DETAIL', props.detail);
  }

  useEffect(() => {
    if (id) {
      getReports(0);
      getReports(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const getReports = (is_compare_report = 0) => {
    props.show(id, company_shipper.id, is_compare_report, props.filter);
  }

  const onFilter = (filter) => {
    setStatusId(filter.status_id);
    setDateFrom(filter.dateFrom);
    setDateTo(filter.dateTo);
  }

  const detailMap = (report) => {
    let { latitude, longitude } = report;
    console.log("position", latitude, longitude);
    if (!latitude || !longitude) {
      Swal.fire('Yah :(', 'Posisi tidak ditemukan :(', 'info');
      return false;
    }
    props.setDetailReport({...report, summary});
    props.history.push('/trip-monitoring-detail-map');
  }

  const onSubmit = (action = 'rejected') => {
    let msg, title;
    // do validation

    const checkAppropriate = props.reports.filter(report => !report.is_appropriate);

    switch (action) {
      case 'approved':
      if (checkAppropriate.length > 0) {
        Swal.fire(
          'Belum sesuai :(',
          'Masih ada trip yang belum sesuai.',
          'info'
        );
        return false;
      }
      title = "Approved"
      msg = 'Are you sure you want to approve ?'
      break;
      default:
      if (checkAppropriate.length === 0) {
        Swal.fire(
          'Report Sudah Sesuai Semuanya',
          'Anda sudah mencentang seluruh report menjadi sesuai, silahkan tekan tombol Approve.',
          'info'
        );
        return false;
      }
      const checkNote = props.reports.filter(report => !report.is_appropriate && !report.note);
      if (checkNote.length > 0) {
        Swal.fire(
          'Belum lengkap :(',
          'Harap berikan catatan untuk trip yang belum sesuai',
          'info'
        );
        return false;
      }
      title = "Rejected"
      msg = 'Are you sure you want to reject ?'
    }

    Swal.fire({
      title: 'Confirmation',
      text: msg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        let report = [];
        _.each(props.reports, rep => { 
          if (!_.isNil(rep.pickup_at)) {
            rep.pickup_at = moment(rep.pickup_at).utc().format();
          }

          if (!_.isNil(rep.start_loading_at)) {
            rep.start_loading_at = moment(rep.start_loading_at).utc().format();
          }

          if (!_.isNil(rep.end_loading_at)) {
            rep.end_loading_at = moment(rep.end_loading_at).utc().format();
          }

          if (!_.isNil(rep.loading_date) && !_.isNil(rep.loading_time)) {
            rep.loading_arrive_at = moment(`${rep.loading_date} ${rep.loading_time}`, 'YYYY-MM-DD HH:mm').utc().format();
            delete rep.loading_date;
            delete rep.loading_time;
          }
          
          if (!_.isNil(rep.dropoff_date) && !_.isNil(rep.dropoff_time)) {
            rep.dropoff_at = moment(`${rep.dropoff_date} ${rep.dropoff_time}`, 'YYYY-MM-DD HH:mm').utc().format();
            delete rep.dropoff_date;
            delete rep.dropoff_time;
          }
          
          if (!_.isNil(rep.unloading_date) && !_.isNil(rep.unloading_time)) {
            rep.unloading_at = moment(`${rep.unloading_date} ${rep.unloading_time}`, 'YYYY-MM-DD HH:mm').utc().format();
            delete rep.unloading_date;
            delete rep.unloading_time;
          }

          report.push(rep);
        });
        
        props.update(id, action, report).then(res => {
          const { errorMessage } = res.payload.data;
          if (res.type === UPDATE + '_FAIL') {
            errorHandler(props, res);
          } else if (errorMessage) {
            Swal.fire(
              'Gagal!',
              res.payload.data.errorMessage,
              'error'
            );
          } else {
            // if (action === 'approved') {
            props.history.goBack();
            // }
            Swal.fire(
              title+'!',
              '',
              'success'
            );
          }
        });

      }
    })
  }

  const {pathname} = props.location;

  const tdsSingkat = (str) => {
    let tdsArr = str.split('-');
    tdsArr[2] = tdsArr[2].substr(-6);
    return tdsArr.join('-');
  }

  const getTemplate = () => {
    var defColumns = [
      {
        key: 'no',
        name: 'No',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'id',
        name: 'No',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'trip_id',
        name: 'Trip ID',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'order_id',
        name: 'Order ID',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'trip_status',
        name: 'Status Trip',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'order_status',
        name: 'Status Order',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'license_plate',
        name: 'License Plate',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'pickup_date',
        name: 'Pick Up Date',
        readOnly: false,
        type: 'datetime-local'
      },
      {
        key: 'origin',
        name: 'Origin',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'destination_name',
        name: 'Destination Name',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'destination_city',
        name: 'Destination City',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'loading_start',
        name: 'Start Loading',
        readOnly: false,
        type: 'datetime-local'
      },
      {
        key: 'loading_end',
        name: 'End Loading',
        readOnly: false,
        type: 'datetime-local'
      },
      {
        key: 'loading_date',
        name: 'Date Arrival Loading',
        readOnly: false,
        type: 'date'
      },
      {
        key: 'loading_time',
        name: 'Time Arrival Loading',
        readOnly: false,
        type: 'time'
      },
      {
        key: 'dropoff_date',
        name: 'Dropoff Date',
        readOnly: false,
        type: 'date'
      },
      {
        key: 'dropoff_time',
        name: 'Dropoff Time',
        readOnly: false,
        type: 'time'
      },
      {
        key: 'unloading_date',
        name: 'Unloading Date',
        readOnly: false,
        type: 'date'
      },
      {
        key: 'unloading_time',
        name: 'Unloading Time',
        readOnly: false,
        type: 'time'
      },
      {
        key: 'shipment_number',
        name: 'No Shipment',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'order_number',
        name: 'No Order',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'po_number',
        name: 'PO Number',
        readOnly: true,
        type: 'text'
      },
      {
        key: 'latest_position',
        name: 'Latest Position',
        readOnly: false,
        type: 'text'
      },
      {
        key: 'last_update',
        name: 'Last Update',
        readOnly: false,
        type: 'text'
      },
      {
        key: 'remarks',
        name: 'Remarks',
        readOnly: false,
        type: 'text'
      }
    ];
    var fields = [];

    let res = props.reports;
    
    if (res.length > 0) {
      if (!res[0].header.template) return;

      var templates = res[0].header.template.split(',');
      templates.forEach(function (obj, key) {
        if (fields.find(p => p.data === obj)) return;

        var column = defColumns.find(p => p.key === obj);
        if (!column) return;

        fields.push(column);
      });
    };

    return fields;
  }

  const filteredReports = () => {
    let res = props.reports;
    if (search) {
      res = props.reports.filter(item => {
        return item.trip_id.toLowerCase() === search.toLowerCase() || tdsSingkat(item.trip_id.toLowerCase()) === search.toLowerCase();
      });

      if (res.length === 0) {
        res = props.reports.filter(item => {
          return item.order_id.toLowerCase() === search.toLowerCase() || tdsSingkat(item.order_id.toLowerCase()) === search.toLowerCase();
        });
      }
    }

    if (status_id) {
      res = res.filter(item => {
        return item.order.status_id.toString() === status_id.value.toString();
      });
    }

    if (dateFrom && dateTo) {
      res = res.filter(item => {
        return item.order.ready_time >= formatDate(dateFrom) && item.order.ready_time <= formatDate(moment(dateTo).add(1, 'days'));
      });
    }

    return res;
  }

  const togglePrevious = () => {
    setShowPrev(!showPrev);
    // if (!showPrev) {
    //   getReports(1);
    // } else {
    //   getReports(0);
    // }
  }

  return (
    <Can redirect permissions={permissions(pathname)}>
      <Filter show={showFilter} onHide={() => setShowFilter(false)} onFilter={onFilter}/>
      <div className="app-container-1">
        <BackButton {...props} />
        <Container className="pt-3">
          <Row>
            <Col>
              <strong>{ company_shipper?company_shipper.name:'' }</strong>
              <br/>
              { summary?summary.count_order:0 } Shipment
              <br />
              {_.get(summary, 'count_trip_flag', false) && 
                <div style={{color: '#f27130', fontSize: '14px', fontWeight: '900'}}><i className="icon-tag"></i> &nbsp;{ summary.count_trip_flag } Shipment</div>
              }
            </Col>
            <Col>
              { moment(created_at).format('DD MMMM YYYY HH:mm') }
              { }
              {_.get(company_template_report, 'send_time', false) && 
                <div style={{color: _.get(company_template_report, 'send_time', false) && moment(company_template_report.send_time, "HH:mm:ss").diff(moment().utc(), 'hours') <= 1 ? '#f23d3d' : '#009abf', fontSize: '12px', fontWeight: '900', marginTop: '7px'}}>Report at { moment.utc(company_template_report.send_time, "HH:mm:ss").local().format('HH:mm') }</div>
              }
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={10} className="pr-0">
                  <div className="ritase-search mt-2">
                    <SimpleLineIcon name="magnifier" style={{marginRight: 15}}/>
                    <input type="text" value={search} className="form-control" placeholder="Cari Order ID, Trip ID" onChange={(e) => setSearch(e.target.value)}/>
                  </div>
                </Col>
                <Col xs={2} className="d-flex justify-content-center p-0">
                  <button className="btn btn-transparent" onClick={() => setShowFilter(true)}>
                    <SimpleLineIcon name="equalizer"></SimpleLineIcon>
                  </button>
                </Col>
              </Row>
              <div className="text-center">
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary" checked={showPrev} onChange={(e) => togglePrevious()} />}
                  label="Perlihatkan Report Sebelumnya"
                  labelPlacement="start"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="table-responsive">
          {/* <button onClick={TripReports} className="btn btn-success">test</button> */}
          <table className="table table-hover">
            <thead className="table-primary">
              <tr>
                {/* <th>Trip ID</th>
                <th>Order ID</th>
                <th>Ready Time</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Status</th>
                <th>No Pol</th>
                <th>Truck Type</th> */}
                {getTemplate().map(field => (
                  <th key={field.key}>{field.name}</th>
                ))}
                {showPrev && <th className="table-info">Previous Status</th>}
                {showPrev && <th className="table-info">Previous Position</th>}
                {showPrev && <th className="table-info">Previous Info</th>}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredReports().length === 0 && <tr>
                <td colSpan={13} className="text-center">{props.loadingReport?<CircularProgress color="primary" />:search?'Tidak ditemukan hasil':'Belum ada data'}</td>
              </tr>}
              {filteredReports().map((x, k) => <ReportList key={k} index={k} report={x} showPrev={showPrev} reportStatus={status} fields={getTemplate()} onPress={report => detailMap(report)} />)}
            </tbody>
          </table>
        </div>
        {status !== 'approved' && <div className="bg-light">
          <Container>
            <Row className="pt-2 pb-2">
              <Col>
                <Button block variant="outline-danger" disabled={props.reports.length === 0} onClick={() => onSubmit('rejected')}>Reject</Button>
              </Col>
              <Col>
                <Button block disabled={props.reports.length === 0} onClick={() => onSubmit('approved')}>Approve</Button>
              </Col>
            </Row>
          </Container>
        </div>}
      </div>
    </Can>
  );
}

const mapstp = (state) => {
  const { detail, reports, loadingReport, filter } = state.trip_approval;
  return {
    detail,
    reports,
    loadingReport,
    filter,
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ show, update, logout, setDetailReport }, dispatch);
}
export default connect(mapstp, mapdtp)(DetailScreen);
