import React from 'react';
import { connect } from 'react-redux';
import { get as getReason, getAllowedPodTrackId } from '../actions/reason';
import { bindActionCreators } from 'redux';

class GetPodRemarks extends React.Component {
  componentDidMount () {
    this.get(this.props);
  }
  
  componentDidUpdate() {
    this.get(this.props);
  }
  
  get = (props) => {
    if (props.token) {
      props.getReason();
      props.getAllowedPodTrackId();
    }
  }

  render() {
    return null;
  }
}

const mapstp = (state) => {
  return {
    token: state.user.token,
  };
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ getReason, getAllowedPodTrackId }, dispatch);
}

export default connect(mapstp, mapdtp)(GetPodRemarks);
