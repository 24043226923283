import { combineReducers } from 'redux';
import { FIELD_SET_FILTER_VISIBILITY, GET_DOCUMENT, CLEAR_DOCUMENTS, FILTER_STATUS_ID, FILTER_COMPANY_SHIPPER_ID, DETAIL, STORE_DOCUMENT_TRACK, FILTER, LIMIT } from '../actions/field';
import moment from 'moment';

const formatDate = (date, type='startOf') => {
  return moment.utc(moment(date)[type]('day')).format();
}

const filterShow = (state=false, action) => {
  switch (action.type) {
    case FIELD_SET_FILTER_VISIBILITY:
    return action.visibility;
    default:
    return state;
  }
}

const documentTracks = (state = [], action) => {
  switch (action.type) {
    case GET_DOCUMENT + '_SUCCESS':
    const { data } = action.payload;
    if (data.errorMessage) {
      return state;
    }
    if (data.offset > 0) {
      return state.concat(data.rows)
    }
    return data.rows;
    case CLEAR_DOCUMENTS:
    return [];
    default:
    return state;
  }
}

const count = (state = 0, action) => {
  switch (action.type) {
    case GET_DOCUMENT + '_SUCCESS':
    const { data } = action.payload;
    if (data.count) {
      return data.count
    };
    return state;
    case CLEAR_DOCUMENTS:
    return 0;
    default:
    return state;
  }
}

const offset = (state = 0, action) => {
  switch (action.type) {
    case GET_DOCUMENT + '_SUCCESS':
    const { data } = action.payload;
    if (data.offset) {
      return data.offset / LIMIT;
    };
    return state;
    case CLEAR_DOCUMENTS:
    return 0;
    default:
    return state;
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case GET_DOCUMENT:
    return true;
    case GET_DOCUMENT + '_SUCCESS':
    return false;
    case GET_DOCUMENT + '_FAIL':
    return false;
    default:
    return state;
  }
}

const fail = (state = false, action) => {
  switch (action.type) {
    case GET_DOCUMENT:
    return false;
    case GET_DOCUMENT + '_FAIL':
    return true;
    default:
    return state;
  }
}

const status_id = (state = '', action) => {
  switch (action.type) {
    case FILTER_STATUS_ID:
    return action.value;
    default:
    return state;
  }
}

const company_transport_id = (state = '', action) => {
  switch (action.type) {
    case 'FIELD_FILTER_COMPANY_TRANSPORT_ID':
    return action.value;
    default:
    return state;
  }
}

const dateFrom = (state = '', action) => {
  switch (action.type) {
    case 'FIELD_FILTER_DATE_FROM':
    return action.value;
    default:
    return state;
  }
}

const dateTo = (state = '', action) => {
  switch (action.type) {
    case 'FIELD_FILTER_DATE_T0':
    return action.value;
    default:
    return state;
  }
}

const license_plate = (state = '', action) => {
  switch (action.type) {
    case 'FIELD_FILTER_LICENSE_PLATE':
    return action.value;
    default:
    return state;
  }
}

const detail = (state = null, action) => {
  switch (action.type) {
    case DETAIL:
    return action.detail;
    default:
    return state;
  }
}

const company_shipper_id = (state = '', action) => {
  switch (action.type) {
    case FILTER_COMPANY_SHIPPER_ID:
    return action.value
    default:
    return state;
  }
}

const storeLoading = (state = false, action) => {
  switch (action.type) {
    case STORE_DOCUMENT_TRACK:
    return true;
    case STORE_DOCUMENT_TRACK + '_SUCCESS':
    return false;
    case STORE_DOCUMENT_TRACK + '_FALSE':
    return false;
    default:
    return state;
  }
}

const filter = (state = '', action) => {
  switch (action.type) {
    case FILTER:
    let { status_id, company_shipper_id, dateFrom, dateTo, company_transport_id, license_plate, destination_contact_id } = action.value;
    if (status_id) {
      status_id = status_id.value;
    }
    if (company_shipper_id) {
      company_shipper_id = company_shipper_id.value;
    }
    if (company_transport_id) {
      company_transport_id = company_transport_id.value;
    }
    if (destination_contact_id) {
      destination_contact_id = destination_contact_id.value;
    }
    const paramStatus = status_id?'&status_id='+status_id:''
    const paramShipper = company_shipper_id?'&company_shipper_id='+company_shipper_id:''
    const paramTransport = company_transport_id?'&company_transport_id='+company_transport_id:''
    const paramDestination = destination_contact_id?'&destination_contact_id='+destination_contact_id:''
    const paramLicensePlate = license_plate?'&license_plate='+license_plate:'';
    const paramReadyTime = dateFrom && dateTo?'&ready_time='+formatDate(dateFrom, 'startOf')+','+formatDate(dateTo, 'endOf'):'';
    const params = paramStatus+paramShipper+paramTransport+paramLicensePlate+paramReadyTime+paramDestination;
    return params;
    default:
    return state;
  }
}

const refreshDocument = (state = false, action) => {
  switch (action.type) {
    case STORE_DOCUMENT_TRACK + '_SUCCESS':
    return true;
    case GET_DOCUMENT + '_SUCCESS':
    return false;
    default:
    return state;
  }
}

export default combineReducers({ filterShow, documentTracks, loading, offset, count, fail, status_id, company_shipper_id, company_transport_id, dateFrom, dateTo, license_plate, detail, storeLoading, refreshDocument, filter });
