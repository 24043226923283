const transporter_params = {
  'company_type': 'transport',
  'populate': 'logs.updated_by,company.config,vehicle_type,origin_area,destination_area,origin_area.administrative_divisions,destination_area.administrative_divisions,route_code,quotation,quotation.company'
}

const client_params = {
  'company_type': 'client',
  'populate': 'logs.updated_by,company.config,vehicle_type,origin_area,destination_area,origin_area.administrative_divisions,destination_area.administrative_divisions,route_code,quotation,quotation.company'
}

export {transporter_params, client_params}