import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { logout } from '../../actions/user';
import { bindActionCreators } from 'redux';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import { ArrowBack, PrintOutlined as PrintIcon } from '@material-ui/icons';
import { toggleHeader } from '../../actions';
import { storeDocument, STORE_DOCUMENT_TRACK } from '../../actions/field';
import FieldForm from './FieldForm';
import moment from 'moment';
import Swal from 'sweetalert2';
import Can from '../../components/Can';
import { permissions } from '../../routes';

class FieldDetailScreen extends Component {
  componentDidMount() {
    this.props.toggleHeader(false);
  }

  componentWillUnmount() {
    this.props.toggleHeader(true);
  }

  goBack = () => {
    this.props.history.goBack();
  }

  printScreen = () => {
    this.props.history.push('field-print-qr');
  }

  onSubmit = (data) => {
    const { id } = this.props.detail;
    let { notes, pod_track_id, remark_id } = data;
    if (remark_id && parseInt(remark_id.value) !== 65) {
      const index = this.props.remarks.map(x => parseInt(x.id)).indexOf(parseInt(remark_id.value));
      if (this.props.remarks[index]) {
        notes = this.props.remarks[index].description;
      }
    }
    data = {
      pod_track_id: pod_track_id.value,
      remark_id: remark_id.value,
      notes,
      order_id: id,
      document_type: 'pod',
      action: 'receive',
      action_at: moment().format(),
      role: this.props.role,
    }
    this.props.storeDocument(data).then(res => {
      if (res.type === STORE_DOCUMENT_TRACK + '_SUCCESS') {
        if (res.payload.data.errorMessage) {
          Swal.fire(
            'Gagal menyimpan!',
            res.payload.data.errorMessage,
            'error'
          );
        } else {
          this.props.history.goBack();
          Swal.fire(
            'Berhasil!',
            'Perubahan berhasil disimpan!',
            'success'
          );
        }
      }
    })
  }

  render() {
    if (!this.props.detail) {
      return (
        <Can redirect permissions={permissions('/field')}>

        <Container>
          <AppBar position="fixed" color="default">
            <Toolbar>
              <IconButton edge="start" onClick={() => this.goBack()} color="inherit" aria-label="Menu">
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="inherit">
                Trip Detail
              </Typography>
            </Toolbar>
          </AppBar>
          <Row>
            <Col>
              <h2 className="text-danger">
                Terjadi kesalahan saat menampilkan halaman.
              </h2>
            </Col>
          </Row>
        </Container>
      </Can>
    )
  }
  const { detail } = this.props
  return (
    <Can redirect permissions={permissions('/field')}>
    <Container>
      <AppBar position="fixed" color="default">
        <Toolbar>
          <IconButton edge="start" onClick={() => this.goBack()} color="inherit" aria-label="Menu">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit">
            Trip Detail
          </Typography>
        </Toolbar>
      </AppBar>
      <Row className="mb-3 mt-3">
        <Col xs="6" sm="3">
          <h6 className="mb-0"><strong>Shipment Number</strong></h6>
          {detail.shipment_number?detail.shipment_number:'-'}
        </Col>
        <Col xs="6" sm="3">
          <h6 className="mb-0"><strong>Order Number</strong></h6>
          <p>{detail.order_number?detail.order_number.replace(new RegExp(',', 'g'),', '):'-'}</p>
        </Col>
        <Col xs="6" sm="3">
          <h6 className="mb-0"><strong>PO Number</strong></h6>
          {detail.po_number?detail.po_number:'-'}
        </Col>
        <Col xs="6" sm="3">
          <Button variant="light" onClick={this.printScreen}><PrintIcon /></Button>
        </Col>
      </Row>
      <Row>
        <Col className="pb-3">
          <table className="table table-bordered table-primary table-striped" style={{width: '100%'}}>
            <tbody>
              <tr>
                <th>Trip ID</th>
                <td>{detail.trip_id}</td>
              </tr>
              <tr>
                <th>Order ID</th>
                <td>{detail.id}</td>
              </tr>
              <tr>
                <th>Origin</th>
                <td>{detail.origin_contact_name}</td>
              </tr>
              <tr>
                <th>Destination</th>
                <td>{detail.destination_contact_name}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{detail.status?detail.status.name:'-'}</td>
              </tr>
              <tr>
                <th>No. Pol</th>
                <td>{this.props.no_pol}</td>
              </tr>
              <tr>
                <th>Truck Type</th>
                <td>{this.props.truck_type}</td>
              </tr>
            </tbody>
          </table>
          <FieldForm onSubmit={this.onSubmit} {...this.props} />
          {this.props.document_track.user && <i className="float-right text-muted">Last update by {this.props.document_track.user.name}</i>}
        </Col>
      </Row>
    </Container>
  </Can>
);
}
}

// Map state to props
const mapstp = (state) => {
  const { reason, field } = state;
  const { data } = state.user;
  let no_pol = '-';
  let truck_type = '-';
  let role = {};

  if (field.detail) {
    const vehicle = field.detail.trip.vehicle;
    if (vehicle) {
      no_pol = vehicle.license_plate;
      truck_type = vehicle.type.name;
    }
  }


  if (data.role) {
    const { id, name, company_type, created_at, updated_at, description, description_en } = data.role
    role = { id, name, company_type, created_at, updated_at, description, description_en };
  }

  const pod_status_code = '01';
  const remarks_code = '02';

  let rolekey;

  switch(role.name.toLowerCase()) {
    case 'field ops':
      rolekey = 'field_ops';
      break;
    case 'admin pod':
      rolekey = 'admin_pod';
      break;
    case 'pod':
      rolekey = 'admin_pod';
      break;
    case 'finance':
      rolekey = 'finance';
      break;
    case 'head finance':
      rolekey = 'finance';
      break;
    case 'master':
      rolekey = 'master';
      break;
    default:
      rolekey = null;
  }

  const pod_statuses = reason.reasons.filter(r => {
    const hasPermission = (rolekey && r.objCode[rolekey] === '1') || rolekey === 'master';
    return hasPermission && r.objCode.code === 'ritase' && r.objCode.type === pod_status_code && reason.allowed_pod_tracks_id.includes(r.id);
  });
  const remarks = reason.reasons.filter(r => {
    const hasPermission = (rolekey && r.objCode[rolekey] === '1') || rolekey === 'master';
    return hasPermission && r.objCode.code === 'ritase' && r.objCode.type === remarks_code;
  });

  let document_track = {user: null};
  if (field.detail) {
    const { document_tracks } = field.detail;
    if (document_tracks.length > 0) {
      document_track = document_tracks[0];
    }
  }

  return {
    detail: field.detail,
    loading: field.storeLoading,
    pod_statuses,
    remarks,
    no_pol,
    truck_type,
    document_track,
    role,
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ storeDocument, logout, toggleHeader }, dispatch)
}

export default connect(mapstp, mapdtp)(FieldDetailScreen)
