import { IonContent } from '@ionic/react';
import { AppBar, Button, Card, CardContent, CircularProgress, Divider, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toggleHeader } from '../../actions';
import { getOriginCoverage, getDestinationCoverage, deletePrice } from '../../actions/all_prices';
import Can from '../../components/Can';
import thousand from '../../helpers/thousand';
import { permissions } from '../../routes';
import "./style.scss";

class DetailPriceAllPriceScreen extends Component {
  handleInitPage = () => {
    if (!this.props.detail.id) {
      this.goBack();
    }
  }

  componentDidMount() {
    this.handleInitPage();
    this.props.toggleHeader(false);
  }

  componentWillUnmount() {
    this.props.toggleHeader(true);
  }

  goBack = () => {
    this.props.history.goBack();
  }

  viewCoverage = async () => {
    const {dataPage} =  this.props.location.state;
    const {prefix} =  dataPage

    const originId = this.props.detail.origin_area.id
    const destinationId = this.props.detail.destination_area.id
    await this.props.getOriginCoverage({prefix, id: originId});
    await this.props.getDestinationCoverage({prefix, id: destinationId});
    
    this.props.history.push({
      pathname: '/all-price/view-coverage',
      state: {dataPage: dataPage}
    })
  }

  handleClickEdit (location) {
    const {dataPage} =  location.state;

    this.props.history.push({
      pathname: '/all-price/edit-harga',
      state: {dataPage}
    })
  }

  handleClickDelete = (id) => {
    Swal.fire({
      title: 'Apakah Anda yakin akan menghapus data?',
      text: 'Aksi yang dilakukan tidak dapat dibatalkan! Ketik HAPUS INI untuk konfirmasi.',
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'OK',
      input: 'text',
      allowOutsideClick: false,
      allowEscapeKey: false,
      inputValidator: (value) => {
        return new Promise(async (resolve) => {
          if (value === 'HAPUS INI') {
            await this.props.deletePrice(id)
            resolve()
          } else {
            resolve('Konfirmasi Salah')
          }
        })
      }
    }).then((result) => {
      if (result.value === 'HAPUS INI') {
        Swal.fire({
          type: 'success',
          title: 'Terhapus',
          text: 'Data berhasil terhapus.',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(result=>{
          if(result.value) {
            this.props.history.push({
              pathname: this.props.location.pathname.includes('transporter') ? '/all-price/transporter-prices' : '/all-price/client-prices'
            })
          }
        })
      }
    })
  }

  render() {
    const { detail, loading, location } = this.props;

    if (!detail || loading) {
      return (
        <Can redirect permissions={permissions(this.props.location.pathname)}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton size='medium' edge="start" onClick={() => this.goBack()} style={{color: 'white'}} aria-label="Menu">
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" style={{color: 'white'}}>
                Detail Harga
              </Typography>
            </Toolbar>
          </AppBar>
        <Container>
          <Row>
            <Col>
              <center>
              {loading ? <CircularProgress /> : <h2 className="text-danger">
                Terjadi kesalahan saat menampilkan halaman.
              </h2>}
              </center>
            </Col>
          </Row>
        </Container>
      </Can>
      )
    }  
    
    return (
      <Can redirect permissions={permissions(this.props.location.pathname)}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              size='medium'
              edge="start"
              onClick={() => this.goBack()}
              style={{ color: "white" }}
              aria-label="Menu"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" style={{ color: "white" }}>
              Detail Harga
            </Typography>
          </Toolbar>
        </AppBar>
        <IonContent style={{ "--background": "#F0F6F6" }}>
          <Container fluid className='container-detail'>
            <Card className="card-all-price">
              <CardContent>
                <div className="mb-2">
                  <div className="label">Perusahaan</div>
                  <span>{detail.company && detail.company.name}</span>
                </div>

                <Grid container spacing={2} className="my-1">
                  <Grid item xs={12}>
                    <ul className="route-area-with-title">
                      <li className="route-area-with-title-item">
                        <div className="label">Asal</div>
                        <strong>{detail.origin_area && detail.origin_area.name}</strong>
                      </li>
                      <li className="route-area-with-title-item">
                        <div className="label">Tujuan</div>
                        <strong>{detail.destination_area && detail.destination_area.name}</strong>
                      </li>
                    </ul>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className='label'>Unit</div>
                    <span>{detail.vehicle_type && detail.vehicle_type.name}</span>
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={7}>
                      <div className='label'>Route Code</div>
                      <span>{detail.route_code && detail.route_code.code ? detail.route_code.code : '-'}</span>
                    </Grid>
                    <Grid container item xs={5} alignItems="center">
                      <Button variant='outlined' className='button-icon button-outlined-primary' onClick={this.viewCoverage}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M18.25 4.5C18.782 4.1228 19.4796 4.0727 20.06 4.37C20.6438 4.67478 21.007 5.28149 21 5.94V15.44C21.0024 15.9993 20.7388 16.5264 20.29 16.86L15.79 20.18C15.173 20.64 14.327 20.64 13.71 20.18L9.4 17C9.31232 16.9298 9.18768 16.9298 9.1 17L5.79 19.435C5.25748 19.8239 4.55191 19.8814 3.9634 19.584C3.37488 19.2866 3.00275 18.6844 3 18.025V8.57C3.00428 8.01297 3.27349 7.49125 3.725 7.165L8.225 3.83C8.83082 3.38583 9.65141 3.37379 10.27 3.8L14.605 6.84C14.6923 6.90032 14.8077 6.90032 14.895 6.84L18.25 4.5ZM4.6382 18.2486C4.72289 18.291 4.82425 18.2818 4.9 18.225L8.21 15.78C8.30264 15.7167 8.39954 15.6599 8.5 15.61V5.5L4.6 8.37C4.53766 8.41772 4.50077 8.49149 4.5 8.57V18.025C4.5 18.1197 4.5535 18.2063 4.6382 18.2486ZM10.29 15.78C10.1974 15.7167 10.1005 15.6599 10 15.61V5.445L13.75 8.06C13.8303 8.11765 13.9156 8.16786 14.005 8.21V8.255V18.5L10.29 15.78ZM19.4 15.64C19.4623 15.5923 19.4992 15.5185 19.5 15.44L19.505 5.925C19.5055 5.83118 19.4534 5.74497 19.3702 5.70176C19.2869 5.65854 19.1864 5.66558 19.11 5.72L15.755 8.06C15.6747 8.11765 15.5894 8.16786 15.5 8.21V8.255V18.5L19.4 15.64Z" fill="#009ABF"/>
                        </svg>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className='label'>Harga</div>
                    <span className='price'>Rp {thousand(detail.price)}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='label'>Premium Rate</div>
                    <span>{ detail.premium_price ? `Rp ${thousand(detail.premium_price)}` : '-'}</span>
                  </Grid>
                </Grid>

                <Divider variant="middle" className='my-3' />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className='label'>Uang Jalan</div>
                    <span>{ detail.default_expenses ? `Rp ${thousand(detail.default_expenses)}` : '-'}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='label'>Biaya Lain-lain</div>
                    <span>{ detail.default_other_fees ? `Rp ${thousand(detail.default_other_fees)}` : '-'}</span>
                  </Grid>
                </Grid>

                
                  <Grid container className='mt-2'>
                    <Grid item xs={12}>
                      <div className='label'>Harga LCL/LTL</div>
                    </Grid>
                  {detail.cbm_price > 0 || detail.min_cbm > 0 || detail.kg_price > 0 || detail.min_kg > 0 ?
                    <>
                      {detail.cbm_price > 0 || detail.min_cbm > 0 ?
                        <Grid item xs={6}>
                          <span>Rp {detail.cbm_price > 0 ? thousand(detail.cbm_price) : 0}/CBM</span>
                          <div>Min: {detail.min_cbm > 0 ? detail.min_cbm : 0} CBM</div>
                        </Grid> : ''
                      }
                      {detail.kg_price > 0 || detail.min_kg > 0 ?
                        <Grid item xs={6}>
                          <span>Rp {detail.kg_price > 0 ? thousand(detail.kg_price) : 0}/Kg</span>
                          <div>Min: {detail.min_kg ? detail.min_kg : 0} Kg</div>
                        </Grid> : ''
                      } 
                    </> : '-'
                  }
                  </Grid> 

                <Grid container className='mt-2'>
                  <Grid item xs={12}>
                    <div className='label'>Harga Multidrop</div>
                  </Grid>
                  { detail.same_city_drop_price <= 0 && detail.diff_city_drop_price <= 0 &&
                    <Grid item xs={6}>
                      -
                    </Grid>
                  }
                  { detail.same_city_drop_price > 0 &&
                    <Grid item xs={6}>
                      <span>Rp {thousand(detail.same_city_drop_price)}</span>
                      <div>Dalam Kota</div>
                    </Grid>
                  }
                  { detail.diff_city_drop_price > 0 &&
                    <Grid item xs={6}>
                        <span>Rp {thousand(detail.diff_city_drop_price)}</span>
                        <div>Luar Kota</div>
                    </Grid>
                  }
                </Grid>

                <Grid container className='mt-2'>
                  <Grid item xs={12}>
                    <div className='label'>Allocation</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>Max Burn Rate</div>
                    { detail.max_burn ?
                      <span>Rp {thousand(detail.max_burn)}</span>
                      : '-'
                    }
                  </Grid>
                  <Grid item xs={6} className='pl-2'>
                    <div>Maks TKBM</div> 
                    { detail.tkbm_price ?
                      <span>Rp {thousand(detail.tkbm_price)}</span>
                      : '-'
                    }
                  </Grid>
                </Grid>

                <Grid container className='mt-4'>
                  <Grid item xs={2}>
                      <Button variant='outlined' className='button-icon button-outlined-danger' onClick={()=>this.handleClickDelete(detail.id)}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16 5.625H20.25C20.6309 5.66905 20.9183 5.99159 20.9183 6.375C20.9183 6.75841 20.6309 7.08095 20.25 7.125H19V18.125C18.9973 19.6426 17.7677 20.8722 16.25 20.875H7.75001C6.23237 20.8722 5.00276 19.6426 5.00001 18.125V7.125H3.75001C3.36913 7.08095 3.08176 6.75841 3.08176 6.375C3.08176 5.99159 3.36913 5.66905 3.75001 5.625H8.00001V4.875C8.00001 3.9085 8.78351 3.125 9.75001 3.125H14.25C15.2165 3.125 16 3.9085 16 4.875V5.625ZM9.75001 4.625C9.61194 4.625 9.50001 4.73693 9.50001 4.875V5.625H14.5V4.875C14.5 4.73693 14.3881 4.625 14.25 4.625H9.75001ZM17.5 18.125C17.5 18.8154 16.9404 19.375 16.25 19.375H7.75001C7.05965 19.375 6.50001 18.8154 6.50001 18.125V7.125H17.5V18.125ZM9.50001 16.875C9.50001 17.2892 9.16422 17.625 8.75001 17.625C8.3358 17.625 8.00001 17.2892 8.00001 16.875V9.625C8.00001 9.21079 8.3358 8.875 8.75001 8.875C9.16422 8.875 9.50001 9.21079 9.50001 9.625V16.875ZM15.25 17.625C15.6642 17.625 16 17.2892 16 16.875V9.625C16 9.21079 15.6642 8.875 15.25 8.875C14.8358 8.875 14.5 9.21079 14.5 9.625V16.875C14.5 17.2892 14.8358 17.625 15.25 17.625ZM12.75 16.875C12.75 17.2892 12.4142 17.625 12 17.625C11.5858 17.625 11.25 17.2892 11.25 16.875V9.625C11.25 9.21079 11.5858 8.875 12 8.875C12.4142 8.875 12.75 9.21079 12.75 9.625V16.875Z" fill="#F23D3D"/>
                      </svg>
                      </Button>
                  </Grid>
                  <Grid item xs={10}>
                    <Button fullWidth variant='outlined' className='button-y-padding button-outlined-primary' onClick={()=>this.handleClickEdit(location)}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.635 5.36501C19.2423 5.94819 19.7656 6.61287 20.19 7.34001C20.5546 8.01951 20.4284 8.85789 19.88 9.40001L10.2 19.075C10.1014 19.1744 9.97652 19.2437 9.84001 19.275L5.13501 20.36C5.04362 20.3839 4.94948 20.3957 4.85501 20.395C4.52348 20.3945 4.2055 20.2634 3.97001 20.03C3.66586 19.7249 3.54105 19.2843 3.64001 18.865L4.72501 14.16C4.75627 14.0235 4.82562 13.8987 4.92501 13.8L14.575 4.12001C15.1171 3.57163 15.9555 3.44546 16.635 3.81001C17.371 4.2325 18.0442 4.75591 18.635 5.36501ZM6.76001 14.085C7.39491 14.4848 7.97915 14.96 8.50001 15.5C9.04007 16.0209 9.51518 16.6051 9.91501 17.24L16.415 10.74C16.0634 10.0713 15.6 9.4676 15.045 8.95501C14.5324 8.39997 13.9287 7.93663 13.26 7.58501L6.76001 14.085ZM6.00001 15.345C6.53464 15.6849 7.02649 16.0878 7.46501 16.545C7.9181 16.981 8.31758 17.4694 8.65501 18L5.19001 18.8L6.00001 15.345ZM17.5 9.63501L18.83 8.34501L18.82 8.33501C18.8865 8.26352 18.9041 8.15942 18.865 8.07001C18.5136 7.47034 18.0793 6.92327 17.575 6.44501C17.0967 5.94068 16.5497 5.5064 15.95 5.15501C15.8553 5.10655 15.7402 5.12473 15.665 5.20001L14.365 6.50001C14.9918 6.89492 15.5692 7.36323 16.085 7.89501C16.6251 8.41587 17.1002 9.00011 17.5 9.63501Z" fill="#27A397"/>
                      </svg>
                      <span className='button-text'>Edit</span>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        </IonContent>
      </Can>
    );
  }
}

const mapstp = (state, props) => {
  const {dataPage} = props.location.state || {};
  const {prefix} =  dataPage || ""

  return {
    detail: state[prefix]?.detail, 
    loading: state[prefix]?.loading, 
    originCoverage: state[prefix]?.originCoverage
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ toggleHeader, getOriginCoverage, getDestinationCoverage, deletePrice }, dispatch)
}

export default connect(mapstp, mapdtp)(DetailPriceAllPriceScreen)
