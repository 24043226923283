import { Card, CardContent, Grid } from '@material-ui/core';
import React, { Component } from 'react'
import thousand from '../../helpers/thousand';
import AirIcon from '../../assets/icons/air.svg';
import SeaIcon from '../../assets/icons/sea.svg';

class PriceCard extends Component {
  handleIconVehicleType = (type) => {
    if (type.toLowerCase() === 'air') {
      return <img src={AirIcon} width='24' height='24'/>
    }
    if (type.toLowerCase() === 'sea') {
      return <img src={SeaIcon} width='24' height='24'/>
    }
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.325 12.77L18.825 9.77C18.6832 9.5987 18.4724 9.49968 18.25 9.5H15V7.25C15 6.2835 14.2165 5.5 13.25 5.5H4.25C3.2835 5.5 2.5 6.2835 2.5 7.25V16.75C2.5 17.7165 3.2835 18.5 4.25 18.5H5.105C5.37386 19.3908 6.19451 20.0003 7.125 20.0003C8.05549 20.0003 8.87614 19.3908 9.145 18.5H14.855C15.1239 19.3908 15.9445 20.0003 16.875 20.0003C17.8055 20.0003 18.6261 19.3908 18.895 18.5H19.75C20.7165 18.5 21.5 17.7165 21.5 16.75V13.25C21.5005 13.0743 21.4385 12.9042 21.325 12.77ZM4 7.25C4 7.11193 4.11193 7 4.25 7H13.25C13.3881 7 13.5 7.11193 13.5 7.25V13.5H4V7.25ZM6.5 17.875C6.5 18.2202 6.77982 18.5 7.125 18.5C7.47018 18.5 7.75 18.2202 7.75 17.875C7.75 17.5298 7.47018 17.25 7.125 17.25C6.77982 17.25 6.5 17.5298 6.5 17.875ZM16.875 18.5C16.5298 18.5 16.25 18.2202 16.25 17.875C16.25 17.5298 16.5298 17.25 16.875 17.25C17.2202 17.25 17.5 17.5298 17.5 17.875C17.5 18.2202 17.2202 18.5 16.875 18.5ZM19.75 17C19.8881 17 20 16.8881 20 16.75V13.5L17.9 11H15V14.25C15 14.6642 14.6642 15 14.25 15H4V16.75C4 16.8881 4.11193 17 4.25 17H5.195C5.53587 16.2395 6.29159 15.7501 7.125 15.7501C7.95841 15.7501 8.71413 16.2395 9.055 17H14.945C15.2859 16.2395 16.0416 15.7501 16.875 15.7501C17.7084 15.7501 18.4641 16.2395 18.805 17H19.75Z" fill="#9E9E9E"/>
      </svg>
    )
  }
  render() {
    const { item } = this.props;

    return (
      <Card className='card-all-price cursor-pointer' onClick={this.props.onPress}>
        <CardContent>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M14 8H19.25C20.2165 8 21 8.7835 21 9.75V19.25C21 20.2165 20.2165 21 19.25 21H4.75C3.7835 21 3 20.2165 3 19.25V4.75C3 3.7835 3.7835 3 4.75 3H12.25C13.2165 3 14 3.7835 14 4.75V8ZM4.5 4.75V19.25C4.5 19.3881 4.61193 19.5 4.75 19.5H6V16.25C6 15.8358 6.33579 15.5 6.75 15.5H10.25C10.6642 15.5 11 15.8358 11 16.25V19.5H12.5V4.75C12.5 4.61193 12.3881 4.5 12.25 4.5H4.75C4.61193 4.5 4.5 4.61193 4.5 4.75ZM7.5 19.5V17H9.5V19.5H7.5ZM19.25 19.5C19.3881 19.5 19.5 19.3881 19.5 19.25V9.75C19.5 9.61193 19.3881 9.5 19.25 9.5H14V19.5H19.25ZM6.75 13.5H10.25C10.6642 13.5 11 13.1642 11 12.75C11 12.3358 10.6642 12 10.25 12H6.75C6.33579 12 6 12.3358 6 12.75C6 13.1642 6.33579 13.5 6.75 13.5ZM15.75 13.75H17.75C18.1642 13.75 18.5 14.0858 18.5 14.5C18.5 14.9142 18.1642 15.25 17.75 15.25H15.75C15.3358 15.25 15 14.9142 15 14.5C15 14.0858 15.3358 13.75 15.75 13.75ZM17.75 16.5H15.75C15.3358 16.5 15 16.8358 15 17.25C15 17.6642 15.3358 18 15.75 18H17.75C18.1642 18 18.5 17.6642 18.5 17.25C18.5 16.8358 18.1642 16.5 17.75 16.5ZM15.75 11H17.75C18.1642 11 18.5 11.3358 18.5 11.75C18.5 12.1642 18.1642 12.5 17.75 12.5H15.75C15.3358 12.5 15 12.1642 15 11.75C15 11.3358 15.3358 11 15.75 11ZM6.75 10.75H10.25C10.6642 10.75 11 10.4142 11 10C11 9.58579 10.6642 9.25 10.25 9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75ZM10.25 8H6.75C6.33579 8 6 7.66421 6 7.25C6 6.83579 6.33579 6.5 6.75 6.5H10.25C10.6642 6.5 11 6.83579 11 7.25C11 7.66421 10.6642 8 10.25 8Z" fill="#9E9E9E"/>
            </svg>
            <span className='mx-2'>{item.company && item.company.name}</span>
            <Grid container spacing={2} className="my-1">
              <Grid item xs={8}>
                <ul className="route-area">
                  <li className="route-area-item"><strong>{item.origin_area && item.origin_area.name}</strong></li>
                  <li className="route-area-item"><strong>{item.destination_area && item.destination_area.name}</strong></li>
                </ul>
              </Grid>
              <Grid container item xs={4} alignItems="center">
                <span className='route-code'>{item.route_code && item.route_code.code}</span>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {
                  this.handleIconVehicleType(item.transport_type)
                }
                <span className='mx-2'>{item.vehicle_type && item.vehicle_type.name}</span>
              </Grid>
              <Grid item xs={6}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.145 4.135L14.145 5.8C17.6031 6.76245 19.9968 9.91045 20 13.5V16.67C19.9945 19.0591 18.0591 20.9945 15.67 21H8.33C5.94089 20.9945 4.0055 19.0591 4 16.67V13.5C4.00192 9.91326 6.39084 6.76618 9.845 5.8L8.845 4.135C8.71216 3.90086 8.71216 3.61414 8.845 3.38C8.97944 3.14508 9.22934 3.0001 9.5 3H14.5C14.7671 3.00365 15.0124 3.14816 15.145 3.38C15.2778 3.61414 15.2778 3.90086 15.145 4.135ZM13.175 4.5H10.825L11.44 5.53C11.625 5.5 11.81 5.5 12 5.5C12.19 5.5 12.375 5.5 12.56 5.53L13.175 4.5ZM18.5 16.67C18.4972 18.2318 17.2318 19.4972 15.67 19.5H8.33C6.76818 19.4972 5.50275 18.2318 5.5 16.67V13.5C5.5 9.91015 8.41015 7 12 7C15.5899 7 18.5 9.91015 18.5 13.5V16.67ZM12.82 12.405H14.17H14.19C15.1649 12.4559 15.9292 13.2613 15.9292 14.2375C15.9292 15.2137 15.1649 16.0191 14.19 16.07H13.59V17.5C13.59 17.9142 13.2542 18.25 12.84 18.25C12.4258 18.25 12.09 17.9142 12.09 17.5V16.1L9.52 13.53V15.37C9.52 15.7842 9.18421 16.12 8.77 16.12C8.35579 16.12 8.02 15.7842 8.02 15.37V10.215C8.02 9.80078 8.35579 9.46499 8.77 9.46499H10.27C11.1667 9.4626 11.9506 10.069 12.1736 10.9375C12.3967 11.806 12.0019 12.7151 11.215 13.145L12.07 14V13.155C12.07 12.7408 12.4058 12.405 12.82 12.405ZM10.735 11.41L10.735 11.405V11.4149L10.735 11.41ZM10.27 10.945C10.5259 10.9477 10.7323 11.1546 10.735 11.41C10.7323 11.6645 10.5252 11.87 10.27 11.87H9.5V10.945H10.27ZM14.17 14.56H13.57V13.905H14.17C14.338 13.9256 14.4641 14.0683 14.4641 14.2375C14.4641 14.4067 14.338 14.5494 14.17 14.57V14.56Z" fill="#9E9E9E"/>
                </svg>
                <span className='price mx-2'>Rp {thousand(item.price)}</span>
              </Grid>
            </Grid>
        </CardContent>
      </Card>
    )
  }
}

export default PriceCard