import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { routes } from '../routes';
import { logout, can } from '../actions/user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { permissions } from '../routes';
import Can from './Can';
import SimpleLineIcon from 'react-simple-line-icons';
import SidebarSubList from './SidebarSubList'

class SidebarList extends Component {
  logout = () => {
    this.props.logout().then(res => {
      $('body').addClass('splash-bg')
    });
  }

  warnLogout = (e) => {
    Swal.fire({
      title: 'Logout',
      text: "Are you sure want to logout?",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.logout()
      }
    })
    e.preventDefault();
  }

  render() {
    const {pathname} = this.props.location;
    return (
      <ul className="nav ritase-nav flex-column">
        {routes.map((route, key) => !route.hide && <Can key={key} permissions={permissions(route.path)}>
          {
            route.child
            ? 
            <SidebarSubList route={route} pathname={pathname}/>
            :
            <li className={"nav-item "+(pathname===route.path?'active':'')}>
              <Link className="nav-link d-flex flex-row align-items-center rounded" to={route.path}>
                <img src={route.ico} width="45" height="45" className="rounded-circle float-left mr-3" alt=""/>
                {route.title}
              </Link>
            </li>
          }
        </Can>)}
        <li className={"nav-item"}>
          <a href="logout" className="nav-link d-flex flex-row align-items-center rounded" onClick={this.warnLogout}>
            <SimpleLineIcon name="logout" style={{paddingLeft: 5, paddingRight: 15, marginRight: 12, fontSize: 27}}></SimpleLineIcon>
            Logout
          </a>
        </li>
      </ul>
    )
  }
}

const mapstp = (state) => {
  const { role } = state.user.data;
  return {
    role
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ logout, can }, dispatch)
}

export default connect(mapstp, mapdtp)(withRouter(SidebarList))
