import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactFormSelect from '../../components/ReactFormSelect';

const validate = values => {
  const errors = {}
  if (!values.pod_track_id) {
    errors.pod_track_id = 'Harap pilih status'
  }
  if (!values.remark_id) {
    errors.remark_id = 'Harap pilih remark'
  }
  if (parseInt(values.remark_id) === 65 && !values.notes) {
    errors.notes = 'Harap isi keterangan'
  }
  return errors
}

class FieldForm extends Component {
  _isMounted = false;

  state = {
    remark: null,
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      if (!this.state.remark && this.props.initialValues.remark_id) {
        this.setState({remark: this.props.initialValues.remark_id.value});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { handleSubmit } = this.props
    // const renderSelectField = ({ input, label, type, meta: { touched, error, warning }, children }) => (
    //   <div>
    //     <select {...input} disabled={this.props.loading} className="form-control">
    //       {children}
    //     </select>
    //     {touched && ((error && <small className="text-danger">{error}</small>) || (warning && <small className="text-danger">{warning}</small>))}
    //   </div>
    // )

    const renderTextareaField = ({ input, label, type, meta: { touched, error, warning }, children }) => (
      <div>
        <textarea {...input} disabled={this.props.loading} className="form-control"></textarea>
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span className="text-danger">{warning}</span>))}
      </div>
    )

    return (
      <Form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="control-label">POD Status</label>
          <Field name="pod_track_id" disabled={this.props.loading} className="form-control" placeholder="Pilih" component={ReactFormSelect}
            options={this.props.pod_statuses.map((pd, key) => {
              return {
                value: pd.id,
                label: pd.description,
              }
            })}>
          </Field>
        </div>
        <div className="form-group">
          <label className="control-label">Remark</label>
          <Field name="remark_id" disabled={this.props.loading} className="form-control" onChange={(e) => this.setState({remark: e.value})} placeholder="Pilih"  component={ReactFormSelect}
            options={this.props.remarks.map((r, key) => {
              return {
                value: r.id,
                label: r.description,
              }
            })}>
          </Field>
        </div>
        {this.state.remark && parseInt(this.state.remark) === 65 && <div className="form-group">
          <label className="control-label">Other</label>
          <Field name="notes" disabled={this.props.loading} className="form-control" component={renderTextareaField} />
        </div>}
        <Button type="submit" disabled={this.props.loading} block>{this.props.loading?'Menyimpan...':'Simpan'}</Button>
      </Form>
    );
  }
}

const rf = reduxForm({
  form: 'field',
  validate,
})(FieldForm);

const mapstp = (state, {remarks, pod_statuses}) => {
  const { detail } = state.field;
  let pod_track_id, remark_id, notes;
  const selectedRemark = (id, options) => {
    const items = options.filter(x => x.id === parseInt(id));
    if (items.length > 0) {
      const item = items[0];
      return {
        value: item.id,
        label: item.description,
      }
    } else {
      return null;
    }
  }
  if (detail) {
    if (detail.document_tracks.length > 0) {
      // jika punya document tracks
      const doc = detail.document_tracks[0];
      pod_track_id = doc.pod_track_id?selectedRemark(doc.pod_track_id, pod_statuses):pod_track_id;
      remark_id = doc.remark_id?selectedRemark(doc.remark_id, remarks):remark_id;
      notes = remark_id && parseInt(remark_id.value) === 65?doc.notes:'';
    }
  }

  return {
    initialValues: {
      pod_track_id,
      remark_id,
      notes,
    },
    loading: state.field.storeLoading,
    detail
  }
}
export default connect(mapstp)(rf);
