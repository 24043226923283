import { combineReducers } from 'redux';
import { GET_LIST, DETAIL } from '../actions/report';
import { USER_IMPERSONATE } from '../actions/user';

const lists = (state = [], action) => {
  switch (action.type) {
    case USER_IMPERSONATE + '_SUCCESS':
      return [];
    case GET_LIST + '_SUCCESS':
      const { errorMessage, rows, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      if (offset > 0) {
        return state.concat(rows)
      }
      return rows;
    default:
      return state;
  }
}

const count = (state = 0, action) => {
  switch (action.type) {
    case USER_IMPERSONATE + '_SUCCESS':
      return 0;
    case GET_LIST + '_SUCCESS':
      const { errorMessage, count } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return count;
    default:
      return state;
  }
}

const offset = (state = 0, action) => {
  switch (action.type) {
    case USER_IMPERSONATE + '_SUCCESS':
      return 0;
    case GET_LIST + '_SUCCESS':
      const { errorMessage, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return offset;
    default:
      return state;
  }
}

const detail = (state = [], action) => {
  switch (action.type) {
    case DETAIL + '_RESET':
      return {};
    case DETAIL + '_SUCCESS':
      const { errorMessage, rows, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      if (offset > 0) {
        return state.concat(rows)
      }
      return rows;
    default:
      return state;
  }
}

const countDetail = (state = 0, action) => {
  switch (action.type) {
    case DETAIL + '_SUCCESS':
      const { errorMessage, count } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return count;
    default:
      return state;
  }
}

const offsetDetail = (state = 0, action) => {
  switch (action.type) {
    case DETAIL + '_SUCCESS':
      const { errorMessage, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return offset;
    default:
      return state;
  }
}

export default combineReducers({ lists, count, offset, detail, countDetail, offsetDetail });
