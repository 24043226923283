import React, { Component } from 'react';
import imgfail from '../assets/error.png';

export default class FailNotice extends Component {
  render() {
    return (
      <div className="h-100 d-flex flex-column p-3 justify-content-center align-items-center">
        <img src={imgfail} alt="fail" className="m-3" height="120"/>
        {this.props.children}
      </div>
    );
  }
}
