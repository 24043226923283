import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

export default class RitaseCard extends Component {
  render() {
    const danger = this.props.danger?' danger':'';
    const success = this.props.success?' success':'';
    return (
      <Card className={'ritase-card'+danger+success}>
        <Card.Body>
          {this.props.children}
        </Card.Body>
      </Card>
    );
  }
}
