import {combineReducers} from 'redux';
import { GET, DETAIL, CLEAR_ITEMS, CLEAR_ITEM, SET_FILTER, REMOVE, CLUSTER, GET_STATES, CLEAR_STATES, GET_CITIES, FILTER_CITIES, CLEAR_CITIES, GET_DISTRICTS, FILTER_DISTRICTS, CLEAR_DISTRICTS } from '../actions/areas';

const loading = (state = false, action) => {
  switch (action.type) {
    case GET:
      return true;
    case DETAIL:
      return true;
    case GET + '_SUCCESS':
      return false;
    case DETAIL + '_SUCCESS':
      return false;
    case GET + '_FAIL':
      return false;
    case DETAIL + '_FAIL':
      return false;
    default:
      return false;
  }
}

const list = (state = [], action) => {
    switch (action.type) {
        case GET + '_SUCCESS':
            const {errorMessage, rows, offset} = action.payload.data;
            if (errorMessage) {
                return state;
            }
            if (offset > 0) {
                return state.concat(rows)
            }
            return rows;
        case CLEAR_ITEMS:
          return [];
        default:
            return state;
    }
}

const count = (state = 0, action) => {
    switch (action.type) {
        case GET + '_SUCCESS':
            const {errorMessage, count} = action.payload.data;
            if (errorMessage) {
                return state;
            }
            return count;
        case CLEAR_ITEMS:
          return 0;
        default:
            return state;
    }
}

const offset = (state = 0, action) => {
    switch (action.type) {
        case GET + '_SUCCESS':
            const {errorMessage, offset} = action.payload.data;
            if (errorMessage) {
                return state;
            }
            return offset;
        case CLEAR_ITEMS:
          return 0;
        default:
            return state;
    }
}

const detail = (state = {}, action) => {
  switch (action.type) {
    case DETAIL + '_SUCCESS':
      const {errorMessage } = action.payload.data;
      if (errorMessage) {
          return state;
      }
      return action.payload.data;
    case CLEAR_ITEM:
      return {};
    default:
      return state;
  }
}

const filter = (state = {}, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        search: action.search
      }
    default:
      return state;
  }
}

const remove = (state = null, action) => {
  switch (action.type) {
    case REMOVE + '_SUCCESS':
      if (action.payload.data.errorMessage) {
        return state;
      }
      return action.payload.data;
    default:
      return state;
  }
}

const cluster = (state = [], action) => {
  switch (action.type) {
    case CLUSTER + '_SUCCESS':
        const {errorMessage} = action.payload.data;
        if (errorMessage) {
            return state;
        } else {
          return action.payload.data
        }
    default:
        return state;
  }
}

const states = (state = [], action) => {
  switch (action.type) {
    case GET_STATES + '_SUCCESS':
      const {errorMessage, rows} = action.payload.data;
      if (errorMessage) {
          return state;
      }
      return rows
    case CLEAR_STATES:
      return []
    default:
      return state
  }
}

const cities = (state = [], action) => {
  switch (action.type) {
    case GET_CITIES + '_SUCCESS':
      const {errorMessage, rows} = action.payload.data;
      if (errorMessage) {
          return state;
      }
      return state.concat(rows);
    case CLEAR_CITIES:
      return [];
    case FILTER_CITIES:
      return state.filter(city => city.parent_id !== action.id);
    default:
      return state;
  }
}

const districts = (state = [], action) => {
  switch (action.type) {
    case GET_DISTRICTS + '_SUCCESS':
      const {errorMessage, rows} = action.payload.data;
      if (errorMessage) {
          return state;
      }
      return state.concat(rows);
    case CLEAR_DISTRICTS:
      return [];
    case FILTER_DISTRICTS:
      return state.filter(district => district.parent_id !== action.id);
    default:
      return state;
  }
}

export default combineReducers({ list, count, offset, loading, detail, filter, remove, cluster, states, cities, districts});