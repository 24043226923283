import React, { Component } from 'react';
import RitaseCard from '../../components/RitaseCard';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

export default class TripList extends Component {
  render() {
    const { company_shipper, status, summary, created_at, company_template_report } = this.props;
    return (
      <Link to="/trip-monitoring-detail" className="ritase-card-link" onClick={this.props.onPress}>
        <RitaseCard danger={ status === 'rejected' } success={ status === 'approved'}>
          <Row>
            <Col xs={5}>
              <h3 className="card-title">{ company_shipper.name }</h3>
              <span>{ summary.count_order } Shipment</span>
              {_.get(summary, 'count_trip_flag', false) && 
                <div style={{color: '#f27130', fontSize: '14px', fontWeight: '900', marginTop: '7px'}}><i className="icon-tag"></i> &nbsp;{ summary.count_trip_flag } Shipment</div>
              }
            </Col>
            <Col xs={7} style={{alignItems: 'center', display: 'flex', justifyContent: 'flex-end', color: '#8C8C8C'}}>
              <div style={{textAlign: 'right'}}>
                <div>{ moment(created_at).format('DD MMMM YYYY HH:mm') }</div>
                {_.get(company_template_report, 'send_time', false) && 
                  <div style={{color: _.get(company_template_report, 'send_time', false) && moment(company_template_report.send_time, "HH:mm:ss").diff(moment().utc(), 'hours') <= 1 ? '#f23d3d' : '#009abf', fontSize: '12px', fontWeight: '900', marginTop: '7px'}}>Report at { moment.utc(company_template_report.send_time, "HH:mm:ss").local().format('HH:mm') }</div>
                }
              </div>
            </Col>
          </Row>
        </RitaseCard>
      </Link>
    );
  }
}
