/* eslint-disable no-duplicate-case, import/no-duplicates, no-redeclare */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import SimpleLineIcon from 'react-simple-line-icons';
import { Switch } from '@material-ui/core';
import { updateReport } from '../../actions/trip';
import _ from 'lodash';

const ReportList = props => {
  const [report, setReport] = useState(props.report);
  const [note, setNote] = useState('');
  const [ showRemark, setShowRemark] = useState(false);
  const toggleRemark = (showRemark) => {
    setShowRemark(showRemark);
  }

  useEffect(() => {
    setNote(report.note?report.note:'');
  }, [report.note]);

  useEffect(() => {
    setReport(props.report);
  }, [props.report]);

  const saveNote = () => {
    toggleRemark(false);
    let selected = {
      ...report,
      note,
    };
    setReport({
      ...selected
    });
    props.updateReport(props.index, selected);
  }
  const cancel = () => {
    toggleRemark(false);
    setNote(report.note?report.note:'');
  }
  const onChangeIsAppropriate = (e) => {
    if (props.reportStatus === 'approved') {
      return false;
    }
    let selected = {
      ...report,
      is_appropriate: e.target.checked,
    }
    setReport({...selected});
    props.updateReport(props.index, selected);
  }

  const tdsSingkat = (str) => {
    let tdsArr = str.split('-');
    tdsArr[2] = tdsArr[2].substr(-6);
    return tdsArr.join('-');
  }

  const editData = (val, field) => {
    let data_field;
    switch (field) {
      case 'last_update':
        data_field = 'latest_position';
        break;
      case 'pickup_date':
        data_field = 'pickup_at';
        break;
      case 'loading_start':
        data_field = 'start_loading_at';
        break;
      case 'loading_end':
        data_field = 'end_loading_at';
        break;
      default:
        data_field = field;
        break;
    }
    let selected = {
      ...report,
      [data_field]: val,
    };
    setReport({
      ...selected
    });
    props.updateReport(props.index, selected);
  }

  return (
    <tr>
      {/* <td>{tdsSingkat(report.trip_id)}</td>
      <td>{tdsSingkat(report.order_id)}</td>
      <td>{report.order?moment.utc(report.order.ready_time).format('DD MMM HH:mm'):'-'}</td>
      <td>{report.order?report.order.origin_contact_name:'-'}</td>
      <td>{report.order?report.order.destination_contact_name:'-'}</td>
      <td>{report.order?report.order.status?report.order.status.name:'-':'-'}</td>
      <td>{report.trip?report.trip.vehicle?report.trip.vehicle.license_plate:'-':'-'}</td>
      <td>{report.trip?report.trip.vehicle_type?report.trip.vehicle_type.name:'-':'-'}</td> */}
      {props.fields.map(field => {
        var pickup = {date: '', time: ''};
        var loading = {start: '', end: ''};
        var dropoff = {date: '', time: ''};
        var unloading = {date: '', time: ''};
        if (report.order.pickup) {
          if (report.order.pickup.start_at && report.order.pickup.start_at !== '0000-00-00 00:00:00') {
            var date = moment(report.order.pickup.start_at).format("YYYY-MM-DD");
            var time = moment(report.order.pickup.start_at).format("HH:mm");
          } else {
            var date = moment(report.order.pickup.completed_at).format("YYYY-MM-DD");
            var time = moment(report.order.pickup.completed_at).format("HH:mm");
          }

          pickup.date = date === 'Invalid date' ? '' : date;
          pickup.time = time === 'Invalid date' ? '' : time;
        }

        if (!_.isNil(report.loading_arrive_at)) {
          pickup.date = moment(report.loading_arrive_at).format("YYYY-MM-DD");
          pickup.time = moment(report.loading_arrive_at).format("HH:mm");
        }

        if (report.order.load) {
          loading.start = report.order.load.start_at ? moment(report.order.load.start_at).format("YYYY-MM-DDTHH:mm") : '';
          loading.end = report.order.load.completed_at ? moment(report.order.load.completed_at).format("YYYY-MM-DDTHH:mm") : '';
        }

        if (report.loading_date) {
          pickup.date = report.loading_date;
        }

        if (report.loading_time) {
          pickup.time = report.loading_time;
        }

        if (report.loading_start) {
          loading.start = report.loading_start;
        } else if (!_.isNil(report.start_loading_at)) {
          loading.start = moment(report.start_loading_at).format("YYYY-MM-DDTHH:mm");
        }

        if (report.loading_end) {
          loading.end = report.loading_end;
        } else if (!_.isNil(report.end_loading_at)) {
          loading.end = moment(report.end_loading_at).format("YYYY-MM-DDTHH:mm");
        }

        if (report.order.dropoff) {
          if (report.order.dropoff.start_at && report.order.dropoff.start_at !== '0000-00-00 00:00:00') {
            var date = moment(report.order.dropoff.start_at).format("YYYY-MM-DD");
            var time = moment(report.order.dropoff.start_at).format("HH:mm");
          } else {
            var date = moment(report.order.dropoff.completed_at).format("YYYY-MM-DD");
            var time = moment(report.order.dropoff.completed_at).format("HH:mm");
          }

          dropoff.date = date === 'Invalid date' ? '' : date;
          dropoff.time = time === 'Invalid date' ? '' : time;
        }

        if (!_.isNil(report.dropoff_at)) {
          dropoff.date = moment(report.dropoff_at).format("YYYY-MM-DD");
          dropoff.time = moment(report.dropoff_at).format("HH:mm");
        }

        if (report.dropoff_date) {
          dropoff.date = report.dropoff_date;
        }

        if (report.dropoff_time) {
          dropoff.time = report.dropoff_time;
        }

        if (report.order.unload) {
          if (report.order.unload.start_at && report.order.unload.start_at !== '0000-00-00 00:00:00') {
            var date = moment(report.order.unload.start_at).format("YYYY-MM-DD");
            var time = moment(report.order.unload.start_at).format("HH:mm");
          } else {
            var date = moment(report.order.unload.completed_at).format("YYYY-MM-DD");
            var time = moment(report.order.unload.completed_at).format("HH:mm");
          }

          unloading.date = date === 'Invalid date' ? '' : date;
          unloading.time = time === 'Invalid date' ? '' : time;
        }

        if (!_.isNil(report.unloading_at)) {
          unloading.date = moment(report.unloading_at).format("YYYY-MM-DD");
          unloading.time = moment(report.unloading_at).format("HH:mm");
        }

        if (report.unloading_date) {
          unloading.date = report.unloading_date;
        }

        if (report.unloading_time) {
          unloading.time = report.unloading_time;
        }

        var pickup_date;
        if (!_.isNil(report.pickup_at)) {
          pickup_date = moment(report.pickup_at).format("YYYY-MM-DDTHH:mm");
        } else {
          pickup_date = moment(report.order.ready_time).format("YYYY-MM-DDTHH:mm");
        }

        let data;
        switch (field.key) {
          case 'id':
          case 'no':
            data = props.index + 1;
            break;
          case 'trip_id':
            data = tdsSingkat(report.trip_id);
            break;
          case 'order_id':
            data = tdsSingkat(report.order_id);
            break;
          case 'status':
            data = _.get(report, 'trip.status', '');
            break;
          case 'trip_status':
            data = _.get(report, 'trip.status', '');
            break;
          case 'order_status':
            data = _.get(report, 'order.status.name', '');
            break;
          case 'license_plate':
            data = _.get(report, 'trip.vehicle.license_plate', '');
            break;
          case 'ready_time':
            data = report.order.ready_time ? moment(report.order.ready_time).format('DD MMMM YYYY hh:mm') : '';
            break;
          case 'pickup_date':
            data = pickup_date;
            break;
          case 'vehicle_type':
            data = _.get(report, 'trip.vehicle_type.name', '');
            break;
          case 'origin':
            data = _.get(report, 'order.origin_contact_name', '');
            break;
          case 'destination_name':
            data = _.get(report, 'order.destination_contact_name', '');
            break;
          case 'destination_city':
            data = _.get(report, 'order.destination.city', '');
            break;
          case 'loading_start':
            data = loading.start;
            break;
          case 'loading_end':
            data = loading.end;
            break;
          case 'loading_date':
            data = pickup.date;
            break;
          case 'loading_time':
            data = pickup.time;
            break;
          case 'dropoff_date':
            data = dropoff.date;
            break;
          case 'dropoff_time':
            data = dropoff.time;
            break;
          case 'unloading_date':
            data = unloading.date;
            break;
          case 'unloading_time':
            data = unloading.time;
            break;
          case 'shipment_number':
            data = _.get(report, 'order.shipment_number', '');
            break;
          case 'order_number':
            data = _.get(report, 'order.order_number', '');
            break;
          case 'po_number':
            data = _.get(report, 'order.po_number', '');
            break;
          case 'remarks':
            data = _.get(report, 'remarks', '');
            break;
          case 'last_update':
            data = _.get(report, 'latest_position', '');
            break;
          case 'latest_position':
            data = _.get(report, 'latest_position', '');
            break;
          default:
            data = '';
            break;
        }
        return <td key={field.key}><input type={field.type} value={data || ''} onChange={(e) => editData(e.target.value, field.key)} style={{border: field.readOnly ? 'none' : '1px solid #d9d9d9', background: 'transparent', color: (field.type !== 'text' && !field.readOnly && !data) ? '#fff' : '#363636'}} disabled={field.readOnly} /></td>
      })}
      {props.showPrev && <td className="text-muted">{report.prev_summary?report.prev_summary.status:"-"}</td>}
      {props.showPrev && <td>
        <Button variant="outline-dark text-muted" onClick={() => props.onPress({...props, ...props.prev_summary})} style={{minWidth: 160, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <span>Posisi sebelum</span> <SimpleLineIcon name="location-pin" />
        </Button>
      </td>}
      {props.showPrev && <td className="text-muted">{report.prev_summary?report.prev_summary.remarks:"-"}</td>}
      <td style={{display: 'flex', alignItems: 'center'}}>
        <Button onClick={() => props.onPress({...props})} variant="outline-primary" style={{minWidth: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <span>Posisi saat ini</span><SimpleLineIcon name="location-pin" />
        </Button>
        <span className="ml-3">{!report.is_appropriate && 'Tidak'} Sesuai</span>
        <Switch
          checked={report.is_appropriate?true:false}
          value={1}
          color="primary"
          onChange={(e) => onChangeIsAppropriate(e)}
          inputProps={{ 'aria-label': 'checkbox with default color' }}
        />
        {props.reportStatus === 'approved'?<p>{props.note}</p>:<Button variant="outline-primary" onClick={() => toggleRemark(true)}>
          <SimpleLineIcon name="note" />
        </Button>}
        <Modal centered show={showRemark} onHide={() => toggleRemark(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Tambah Catatan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea className="form-control" name="note" id="" cols="30" rows="5" placeholder="Masukan Catatan" onChange={(e) => setNote(e.target.value)} value={note}></textarea>
            <Row className="mt-3">
              <Col>
                <Button block variant="outline-dark" onClick={() => cancel()}>
                  Batal
                </Button>
              </Col>
              <Col>
                <Button block variant="outline-primary" onClick={() => saveNote()}>
                  Simpan
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </td>
    </tr>
  )
}

const mapstp = (state) => {
  return {
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ updateReport }, dispatch);
}

export default connect(mapstp, mapdtp)(ReportList);
