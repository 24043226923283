export const FIELD_SET_FILTER_VISIBILITY = 'FIELD_SET_FILTER_VISIBILITY';
export const GET_DOCUMENT = 'FIELD_GET_DOCUMENT';
export const CLEAR_DOCUMENTS = 'FIELD_CLEAR_DOCUMENTS';
export const FILTER_STATUS_ID = 'FIELD_FILTER_STATUS_ID';
export const FILTER = 'FIELD_FILTER';
export const FILTER_COMPANY_SHIPPER_ID = 'FIELD_FILTER_COMPANY_SHIPPER_ID';
export const DETAIL = 'FIELD_DETAIL';
export const STORE_DOCUMENT_TRACK = 'FIELD_STORE_DOCUMENT_TRACK';
export const LIMIT = 10;

export const setFilterVisibility = (visibility) => {
  return {
    type: FIELD_SET_FILTER_VISIBILITY,
    visibility
  }
};

export const storeDocument = (data) => {
  return {
    type: STORE_DOCUMENT_TRACK,
    payload: {
      request: {
        url: '/document_tracks',
        method: 'post',
        data
      }
    }
  }
}

export const getDocument = (limit = LIMIT, offset = 0, search = '', searchFields = 'order_number', params = '') => {
  offset = offset > 0 ? offset * limit : 0;
  return {
    type: GET_DOCUMENT,
    payload: {
      request: {
        url: '/document_tracks?is_search_transporter=1&populate=document_tracks,company_transport,company_shipper,status,origin,destination,document_tracks.pod_track,document_tracks.pod_remark,document_tracks.user,document_tracks.user.role,trip.vehicle.type,trip.driver&limit='+limit+'&offset='+offset+'&search='+search+'&searchFields='+searchFields+params,
        method: 'get',
      }
    }
  }
}

export const onChangeStatusId = (value) => {
  return {
    type: FILTER_STATUS_ID,
    value,
  }
}

export const onChangeCompanyShipperId = (value) => {
  return {
    type: FILTER_COMPANY_SHIPPER_ID,
    value,
  }
}

export const onChangeFilter = (value) => {
  return {
    type: FILTER,
    value,
  }
}

export const clearDocuments = () => {
  return {
    type: CLEAR_DOCUMENTS
  }
}

export const detail = (detail) => {
  return {
    type: DETAIL,
    detail,
  }
}
