export const GET = 'APPROVAL_LIST';
export const DETAIL = 'APPROVAL_DETAIL';
export const SET_DECISION = 'APPROVAL_SET_DECISION';
export const RESET_DECISION = 'APPROVAL_RESET_DECISION';
export const GET_HISTORY = 'APPROVAL_HISTORY_LIST';
export const RESET_HISTORY = 'APPROVAL_HISTORY_RESET';
export const COUNT_HISTORY = 'APPROVAL_HISTORY_COUNT';

export const getListApproval = () => {
  return {
    type: GET,
    payload: {
      request: {
        url: `/approvals`,
        method: 'get',
      },
    },
  };
};

export const getDetailApproval = (id) => {
  return {
    type: DETAIL,
    payload: {
      request: {
        url: `/approvals/${id}`,
        method: 'get',
      },
    },
  };
};

export const setApprovalDecision = (data) => {
  return {
    type: SET_DECISION,
    payload: {
      request: {
        url: `/approvals`,
        method: 'post',
        data
      },
    },
  };
};

export const resetApprovalDecision = () => {
  return {
    type: RESET_DECISION
  };
};

export const getListApprovalHistory = (offset = 0,  params = '') => {
  return {
    type: GET_HISTORY,
    payload: {
      request: {
        url: `/approvals/history?limit=10&offset=${offset}${params}`,
        method: 'get',
      },
    },
  };
};

export const countListApprovalHistory = (offset = 0, params = '') => {
  return {
    type: COUNT_HISTORY,
    payload: {
      request: {
        url: `/approvals/history?limit=10&offset=${offset}${params}`,
        method: 'get',
      },
    },
  };
}

export const resetListApprovalHistory = () => {
  return {
    type: RESET_HISTORY
  };
};