import { combineReducers } from 'redux';
import { GET_SUPERGROUP } from '../actions/companies';

const lists = (state = [], action) => {
  switch (action.type) {
    case GET_SUPERGROUP + '_SUCCESS':
      const { rows } = action.payload.data;
      if (rows) {
        return rows;
      }
      return state;
    default:
      return state;
  }
}

export default combineReducers({ lists });
