import { Card, CardContent } from '@material-ui/core';
import React, { Component } from 'react'
import CompanyBlock from '../../components/CompanyBlock';
import thousand from '../../helpers/thousand';
import { getDetail } from '../../actions/capacity_offer';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class CardCapacityOffer extends Component {
  gotoDetail = () => {
    this.props.getDetail(this.props.item.id);
    this.props.history.push('/capacity-offer-detail')
  }

  render() {
    const { item } = this.props;

    return (
      <Card classes={{ root: 'MuiCard-root card-capacity-offer' }} onClick={this.gotoDetail}>
        <CardContent>
          <div>
            <CompanyBlock company={item.company_transport} />
            <ul className="route-area">
              <li><strong>{item.origin.name}</strong></li>
              <li><strong>{item.destination.name}</strong></li>
            </ul>
            <div className="vehicle-type">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.325 12.77L18.825 9.77C18.6832 9.5987 18.4724 9.49968 18.25 9.5H15V7.25C15 6.2835 14.2165 5.5 13.25 5.5H4.25C3.2835 5.5 2.5 6.2835 2.5 7.25V16.75C2.5 17.7165 3.2835 18.5 4.25 18.5H5.105C5.37386 19.3908 6.19451 20.0003 7.125 20.0003C8.05549 20.0003 8.87614 19.3908 9.145 18.5H14.855C15.1239 19.3908 15.9445 20.0003 16.875 20.0003C17.8055 20.0003 18.6261 19.3908 18.895 18.5H19.75C20.7165 18.5 21.5 17.7165 21.5 16.75V13.25C21.5005 13.0743 21.4385 12.9042 21.325 12.77ZM4 7.25C4 7.11193 4.11193 7 4.25 7H13.25C13.3881 7 13.5 7.11193 13.5 7.25V13.5H4V7.25ZM6.5 17.875C6.5 18.2202 6.77982 18.5 7.125 18.5C7.47018 18.5 7.75 18.2202 7.75 17.875C7.75 17.5298 7.47018 17.25 7.125 17.25C6.77982 17.25 6.5 17.5298 6.5 17.875ZM16.875 18.5C16.5298 18.5 16.25 18.2202 16.25 17.875C16.25 17.5298 16.5298 17.25 16.875 17.25C17.2202 17.25 17.5 17.5298 17.5 17.875C17.5 18.2202 17.2202 18.5 16.875 18.5ZM19.75 17C19.8881 17 20 16.8881 20 16.75V13.5L17.9 11H15V14.25C15 14.6642 14.6642 15 14.25 15H4V16.75C4 16.8881 4.11193 17 4.25 17H5.195C5.53587 16.2395 6.29159 15.7501 7.125 15.7501C7.95841 15.7501 8.71413 16.2395 9.055 17H14.945C15.2859 16.2395 16.0416 15.7501 16.875 15.7501C17.7084 15.7501 18.4641 16.2395 18.805 17H19.75Z" fill="#9E9E9E"/>
              </svg>
              <span className="mx-2">{item.type.name}</span> <span style={{ color: '#9E9E9E' }}>{ item.vehicle && item.vehicle.license_plate } - { item.driver && item.driver.name }</span>
            </div>
            <div className="offer-price">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.145 4.135L14.145 5.8C17.6031 6.76245 19.9968 9.91045 20 13.5V16.67C19.9945 19.0591 18.0591 20.9945 15.67 21H8.33C5.94089 20.9945 4.0055 19.0591 4 16.67V13.5C4.00192 9.91326 6.39084 6.76618 9.845 5.8L8.845 4.135C8.71216 3.90086 8.71216 3.61414 8.845 3.38C8.97944 3.14508 9.22934 3.0001 9.5 3H14.5C14.7671 3.00365 15.0124 3.14816 15.145 3.38C15.2778 3.61414 15.2778 3.90086 15.145 4.135ZM13.175 4.5H10.825L11.44 5.53C11.625 5.5 11.81 5.5 12 5.5C12.19 5.5 12.375 5.5 12.56 5.53L13.175 4.5ZM18.5 16.67C18.4972 18.2318 17.2318 19.4972 15.67 19.5H8.33C6.76818 19.4972 5.50275 18.2318 5.5 16.67V13.5C5.5 9.91015 8.41015 7 12 7C15.5899 7 18.5 9.91015 18.5 13.5V16.67ZM12.82 12.405H14.17H14.19C15.1649 12.4559 15.9292 13.2613 15.9292 14.2375C15.9292 15.2137 15.1649 16.0191 14.19 16.07H13.59V17.5C13.59 17.9142 13.2542 18.25 12.84 18.25C12.4258 18.25 12.09 17.9142 12.09 17.5V16.1L9.52 13.53V15.37C9.52 15.7842 9.18421 16.12 8.77 16.12C8.35579 16.12 8.02 15.7842 8.02 15.37V10.215C8.02 9.80078 8.35579 9.46499 8.77 9.46499H10.27C11.1667 9.4626 11.9506 10.069 12.1736 10.9375C12.3967 11.806 12.0019 12.7151 11.215 13.145L12.07 14V13.155C12.07 12.7408 12.4058 12.405 12.82 12.405ZM10.735 11.41L10.735 11.405V11.4149L10.735 11.41ZM10.27 10.945C10.5259 10.9477 10.7323 11.1546 10.735 11.41C10.7323 11.6645 10.5252 11.87 10.27 11.87H9.5V10.945H10.27ZM14.17 14.56H13.57V13.905H14.17C14.338 13.9256 14.4641 14.0683 14.4641 14.2375C14.4641 14.4067 14.338 14.5494 14.17 14.57V14.56Z" fill="#9E9E9E"/>
              </svg>
              <span className="mx-2" style={{ color: '#F16B27' }}>Rp {thousand(item.price)}</span>
            </div>
            <div className="offer-date">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.5 5.25H18.25C19.7676 5.25275 20.9972 6.48236 21 8V17.5C20.9972 19.0176 19.7676 20.2472 18.25 20.25H5.75C4.23236 20.2472 3.00275 19.0176 3 17.5V8C3.00275 6.48236 4.23236 5.25275 5.75 5.25H6.5V4.5C6.5 4.08579 6.83579 3.75 7.25 3.75H8.25C8.66421 3.75 9 4.08579 9 4.5V5.25H15V4.5C15 4.08579 15.3358 3.75 15.75 3.75H16.75C17.1642 3.75 17.5 4.08579 17.5 4.5V5.25ZM18.25 18.75C18.9404 18.75 19.5 18.1904 19.5 17.5V8C19.5 7.30964 18.9404 6.75 18.25 6.75H17.5V7.25C17.5 7.66421 17.1642 8 16.75 8H15.75C15.3358 8 15 7.66421 15 7.25V6.75H9V7.25C9 7.66421 8.66421 8 8.25 8H7.25C6.83579 8 6.5 7.66421 6.5 7.25V6.75H5.75C5.05964 6.75 4.5 7.30964 4.5 8V17.5C4.5 18.1904 5.05964 18.75 5.75 18.75H18.25ZM6.5 11C6.5 10.5858 6.83579 10.25 7.25 10.25H8.25C8.66421 10.25 9 10.5858 9 11V11.75C9 12.1642 8.66421 12.5 8.25 12.5H7.25C6.83579 12.5 6.5 12.1642 6.5 11.75V11ZM15.75 10.25C15.3358 10.25 15 10.5858 15 11V11.75C15 12.1642 15.3358 12.5 15.75 12.5H16.75C17.1642 12.5 17.5 12.1642 17.5 11.75V11C17.5 10.5858 17.1642 10.25 16.75 10.25H15.75ZM10.75 11C10.75 10.5858 11.0858 10.25 11.5 10.25H12.5C12.9142 10.25 13.25 10.5858 13.25 11V11.75C13.25 12.1642 12.9142 12.5 12.5 12.5H11.5C11.0858 12.5 10.75 12.1642 10.75 11.75V11ZM7.25 14.125C6.83579 14.125 6.5 14.4608 6.5 14.875V15.625C6.5 16.0392 6.83579 16.375 7.25 16.375H8.25C8.66421 16.375 9 16.0392 9 15.625V14.875C9 14.4608 8.66421 14.125 8.25 14.125H7.25ZM15 14.875C15 14.4608 15.3358 14.125 15.75 14.125H16.75C17.1642 14.125 17.5 14.4608 17.5 14.875V15.625C17.5 16.0392 17.1642 16.375 16.75 16.375H15.75C15.3358 16.375 15 16.0392 15 15.625V14.875ZM11.5 14.125C11.0858 14.125 10.75 14.4608 10.75 14.875V15.625C10.75 16.0392 11.0858 16.375 11.5 16.375H12.5C12.9142 16.375 13.25 16.0392 13.25 15.625V14.875C13.25 14.4608 12.9142 14.125 12.5 14.125H11.5Z" fill="#9E9E9E"/>
              </svg>
              <span className="ml-2">
                {moment(item.start_time).format('D MMM YYYY HH:mm')} - {moment(item.end_time).format(moment(item.end_time).isSame(item.start_time, 'day') ? 'HH:mm' : 'D MMM YYYY HH:mm')}
              </span>
            </div>
          </div>
          
        </CardContent>
      </Card>
    )
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ getDetail }, dispatch)
}


export default connect(undefined, mapdtp)(CardCapacityOffer)