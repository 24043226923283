/* eslint-disable no-unused-vars, react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import BackButton from '../../components/BackButton';
import { Container, Row, Col } from 'react-bootstrap';
import truckmarker from '../../assets/truck-marker.png';
import Can from '../../components/Can';
import { permissions } from '../../routes';

const TripMap = (props) => {
  const [position, setPosition] = useState({
    lat: parseFloat(props.report.latitude),
    lng: parseFloat(props.report.longitude),
  });

  useEffect(() => {
    console.log('position', position);
  }, []);

  const google_api_key = process.env.REACT_APP_GOOGLE_API_KEY;
  const google_map_url = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=drawing&key=${google_api_key}`;
  const defaultOptions = {
    zoomControl: false,
  }
  const TMap = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={18}
      clickableIcons={false}
      defaultOptions={defaultOptions}
      defaultCenter={position}
      >
      <Marker position={position} icon={truckmarker} />
    </GoogleMap>
  ))
  const { report } = props;
  const { trip, summary } = report;
  const {pathname} = props.location;

  return (
    <Can redirect permissions={permissions(pathname)}>
      <div className="ritase-trip-map">
        <BackButton {...props} />
        <TMap
          isMarkerShown
          googleMapURL={google_map_url}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
        <div className="trip-detail pt-3 pb-3">
          <Container>
            <Row>
              <Col>
                <span className="float-right">{summary && summary.count_order} Shipment</span>
                <strong>{report.trip_id}</strong>
                <br/>
                {trip && trip.vehicle_type && trip.vehicle_type.name + ' -'} {trip && trip.vehicle && trip.vehicle.license_plate + ' -'} {trip && trip.driver && trip.driver.name}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Can>
  );
}

const mapstp = (state) => {
  const { report } = state.trip_approval;
  return {
    report,
  }
}

export default connect(mapstp)(TripMap);
