import { combineReducers } from 'redux';
import { GET } from '../actions/vehicle_type';

const lists = (state = [], action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      const { data } = action.payload;
      if (data && data.rows) {
        return data.rows;
      }
      return state;
    default:
      return state;
  }
}

export default combineReducers({ lists });
