import { combineReducers } from 'redux';
import { GET, GET_ALL } from '../actions/transporter';

const lists = (state = [], action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      const { data } = action.payload;
      if (data) {
        return data;
      }
      return state;
    default:
      return state;
  }
}

const all = (state = [], action) => {
  switch (action.type) {
    case GET_ALL + '_SUCCESS':
      const { data } = action.payload;
      if (data) {
        return data.rows;
      }
      return state;
    default:
      return state;
  }
}

export default combineReducers({ lists, all });
