import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Fab from '@material-ui/core/Fab';
import CropFreeIcon from '@material-ui/icons/CropFree';
import PodSearch from './PodSearch.js';
import PullToRefresh from 'pulltorefreshjs';
import PodListItem from './PodListItem';
import Filter from './Filter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDocument, clearDocuments, GET_DOCUMENT, detail } from '../../actions/field';
import $ from 'jquery';
import _ from 'lodash';
import Can from '../../components/Can';
import { permissions } from '../../routes';
import FailNotice from '../../components/FailNotice';
import { logout } from '../../actions/user';
import errorHandler from '../../errorHandler';
import ReactTooltip from 'react-tooltip'
import ls from 'local-storage';
import GetContacts from '../../components/GetContacts';
class FieldScreen extends Component {
    _isMounted = false;
    _ptr = null
    state = {
        isLoading: false,
        search: '',
        searchType: 'shipment_number',
        status_id: '',
        company_shipper_id: '',
        refreshing: false,
    }

    initPtr = () => {
        this._ptr = PullToRefresh.init({
            mainElement: 'blockquote',
            triggerElement: '#field',
            onRefresh: () => {
                this.props.clearDocuments();
                this.getDocuments();
            },
        });
    }

    comopnentDidUpdate () {
        if (this._isMounted) {
            if (this.props.refreshDocument && !this.state.refreshing) {
                this.props.clearDocuments();
                this.getDocuments();
                this.setState({refreshing: true});
            } else {
                this.setState({refreshing: false});
            }
        }
    }

    componentDidMount () {
        this._isMounted = true;
        if (this._isMounted) {
            this.initPtr()
            window.onscroll = () => {
                if(window.pageYOffset === 0) {
                    this.initPtr()
                } else {
                    this._ptr.destroy();
                }
                if ($(window).scrollTop() + $(window).height() === $(document).height()) {
                    const offset = this.props.offset + 1;
                    const isLast = parseInt(this.props.count) === this.props.documentTracks.length;
                    if (!this.props.loading && !this.state.isLoading && !isLast) {
                        this.getDocuments(offset);
                    }
                    this.setState({isLoading: true})
                    setTimeout(() => {
                        this.setState({isLoading: false})
                    }, 1000)
                }
            };

            if (ls.get('FIELD_SEARCH')) {
                this.setState({ search: ls.get('FIELD_SEARCH') });
            }
            if (ls.get('FIELD_SEARCH_TYPE')) {
                this.setState({ searchType: ls.get('FIELD_SEARCH_TYPE') });
            }
        }
    }

    getDocuments (offset=0) {
        const search = ls.get('FIELD_SEARCH')?ls.get('FIELD_SEARCH'):this.state.search;
        const searchType = ls.get('FIELD_SEARCH_TYPE') ? ls.get('FIELD_SEARCH_TYPE') : this.state.searchType;
        
        let params = this.props.filter;

        if (this.props.user_permission_actions.includes("view_field_ops_pod_track_id") &&
        !this.props.user_permission_actions.includes("view_pod_admin_pod_track_id")) {
            params = `${params}&status_ids=10,20,40,50`
        }

        this.props.getDocument(10, offset, search, searchType, params).then(res => {
            if (res.type === GET_DOCUMENT + '_FAIL') {
                errorHandler(this.props, res);
            }
        });
    }

    componentWillUnmount() {
        this._ptr.destroy();
        this._isMounted = false;
    }

    handleSearch = (search) => {
        this.setState({search});
        this.search();
    }

    handleChangeSearchType = (searchType) => {
        this.setState({searchType});
        this.search();
    }

    search = _.debounce(() => {
        this.props.clearDocuments();
        ls.set('FIELD_SEARCH', this.state.search);
        ls.set('FIELD_SEARCH_TYPE', this.state.searchType);
        if (this.state.search === '') return;
        this.getDocuments();
    }, 500);

    handleFilter () {
        this.props.clearDocuments();
        this.getDocuments();
    }

    detail = (e, detail) => {
        this.props.detail(detail);
        this.props.history.push('/field-detail');
        e.preventDefault();
    }

    render() {
        const {pathname} = this.props.location;
        const { search, searchType } = this.state;
        return (
            <Can redirect permissions={permissions(pathname)}>
                <ReactTooltip />
                <GetContacts />
                <Container className="ritase-field mt-3">
                    <Filter onSubmit={(filter) => this.handleFilter(filter)} />
                    <Row>
                        <Col md={{span: 6, offset: 3}}>
                            <PodSearch searchTypeValue={searchType} searchValue={search} onSearch={this.handleSearch} onChangeType={this.handleChangeSearchType} />
                        </Col>
                    </Row>
                    <blockquote></blockquote>
                    <div id="field" className="m-0 p-0" style={{minHeight: 500}}>
                        {this.props.fail && <FailNotice>
                            <h3 className="text-danger text-center">Gagal mengambil data</h3>
                            <Button onClick={() => this.getDocuments()} block variant="outline-info"><i className="fa fa-refresh"></i> Coba lagi</Button>
                        </FailNotice>}
                        {!this.props.fail && !this.props.loading && this.props.documentTracks.length === 0 && <FailNotice>
                            <h3 className="text-danger text-center">Tidak ada data. Silakan gunakan pencarian diatas atau scan QR Code.</h3>
                        </FailNotice>}
                        {!this.props.fail && <Row>
                            <Col md={{span: 6, offset: 3}}>
                                {this.props.documentTracks.map((d, k) => <PodListItem key={k} {...d} onClick={(e) => this.detail(e, d)} />)}
                                {this.props.loading && [0,0,0,0].map((x, k) => <PodListItem key={k} loading />)}
                            </Col>
                        </Row>}
                    </div>
                    <Fab variant="extended" className="floating-qr-code" onClick={() => this.props.history.push('/scan-qr-code')}>
                        <CropFreeIcon />
                        Scan QR Code
                    </Fab>
                </Container>
            </Can>
        );
    }
}

const mapstp = (state) => {
    const user_permission_actions = state.user.data.role.permissions.map(permission => permission.action)
    return {
        ...state.field,
        user_permission_actions
    };
}

const mapdtp = (dispatch) => {
    return bindActionCreators({ getDocument, clearDocuments, logout, detail }, dispatch);
}

export default connect(mapstp, mapdtp)(FieldScreen)
