import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

export default class MyComponent extends Component {
  render() {
    const List = (props) => <Card className="shadow-sm ritase-card mb-3">
      <Card.Body className="p-3">
        {props.children}
      </Card.Body>
    </Card>

    if (this.props.loading) {
      return (
        <List>
          <ContentLoader height={55}>
            <rect x="0" y="0" rx="6" ry="6" width="65%" height="20" />
            <rect x="0" y="30" rx="6" ry="6" width="30%" height="20" />
          </ContentLoader>
          <hr className="m-1"/>
          <ContentLoader height={20}>
            <rect x="0" y="0" rx="6" ry="6" width="30%" height="20" />
            <rect x="70%" y="0" rx="6" ry="6" width="30%" height="20" />
          </ContentLoader>
        </List>
      )
    }

    const { company_shipper, destination_contact_name, status, po_number, shipment_number, order_number } = this.props;
    return (
      <a href="/" className="text-dark" onClick={this.props.onClick} >
        <List>
          <i className="icon-arrow-right float-right" style={{fontSize: 32}}></i>
          <p className="m-0">
            <strong>{company_shipper && company_shipper.name}</strong>
          </p>
          <p className="m-0"><i className="icon-location-pin"></i> {destination_contact_name}</p>
          <hr className="m-1"/>
          <div className="row m-0 p-0">
            <div className="col p-0">
              <p className="m-0 text-small text-primary">Shipment Number</p>
            </div>
            <div className="col p-0 text-right">
              <p className="m-0 text-small">{shipment_number?shipment_number:'-'}</p>
            </div>
          </div>
          <div className="row m-0 p-0">
            <div className="col p-0">
              <p className="m-0 text-small text-primary">Order Number</p>
            </div>
            <div className="col p-0 text-right">
              <p className="m-0 text-small">{order_number?order_number.replace(new RegExp(',', 'g'), ', '):'-'}</p>
            </div>
          </div>
          <div className="row m-0 p-0">
            <div className="col p-0">
              <p className="m-0 text-small text-primary">PO Number</p>
            </div>
            <div className="col p-0 text-right">
              <p className="m-0 text-small">{po_number?po_number:'-'}</p>
            </div>
          </div>
          <div className="row m-0 p-0">
            <div className="col p-0">
              <p className="m-0 text-small text-primary">POD Status</p>
            </div>
            <div className="col p-0 text-right">
              <p className="m-0 text-small">{status && status.name}</p>
            </div>
          </div>
        </List>
      </a>
    );
  }
}
