import { GET, DETAIL, SET_SORT, SET_FILTER, CLEAR_LIST } from '../actions/capacity_offer';
import { combineReducers } from 'redux';

const lists = (state = [], action) => {
  switch (action.type) {
    case GET+'_SUCCESS':
      if (action.payload.data) {
        const { rows = [] } = action.payload.data
        return [
          ...state,
          ...rows
        ];
      }
      return [];
    case CLEAR_LIST:
    case SET_FILTER:
    case SET_SORT:
      return [];
    default:
      return state;
  }
}

const detail = (state = {}, action) => {
  switch (action.type) {
    case DETAIL+'_SUCCESS':
      if (action.payload.data) {
        return action.payload.data;
      }
      return [];
    default:
      return state;
  }
}

const sort = (state = {}, action) => {
  switch (action.type) {
    case SET_SORT:
    return action.payload;
    default:
    return state;
  }
}

const filter = (state = {}, action) => {
  switch (action.type) {
    case SET_FILTER:
    return action.payload;
    default:
    return state;
  }
}

const count = (state = 0, action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      const { errorMessage, count } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return count;
    default:
      return state;
  }
}

const offset = (state = 0, action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      const { errorMessage, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return offset;
    case SET_FILTER:
    case SET_SORT:
      return 0;
    default:
      return state;
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case GET:
    case DETAIL:
    return true;
    case GET + '_SUCCESS':
    case GET + '_FAIL':
    case DETAIL + '_SUCCESS':
    case DETAIL + '_FAIL':
    return false;
    default:
    return state;
  }
}

export default combineReducers({lists, detail, sort, filter, count, offset, loading});
