import { combineReducers } from 'redux';
import { SHOW, GET, DETAIL, ADD_COMMENT, CLOSE } from '../actions/issue';

const show = (state = [], action) => {
  switch (action.type) {
    case SHOW + '_SUCCESS':
      if (action.payload.data.errorMessage) {
        return state;
      }
    return action.payload.data;
    default:
      return state;
  }
}

const list = (state = [], action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      const { errorMessage, rows, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      if (offset > 0) {
        return state.concat(rows)
      }
      return rows;
    default:
      return state;
  }
}

const count = (state = 0, action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      const { errorMessage, count } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return count;
    default:
      return state;
  }
}

const offset = (state = 0, action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      const { errorMessage, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return offset;
    default:
      return state;
  }
}

const detail = (state = [], action) => {
  switch (action.type) {
    case DETAIL + '_RESET':
      return {};
    case DETAIL + '_SUCCESS':
      if (action.payload.data.errorMessage) {
        return state;
      }
      return action.payload.data;
    default:
      return state;
  }
}

const addComment = (state = null, action) => {
  switch (action.type) {
    case ADD_COMMENT + '_SUCCESS':
      if (action.payload.data.errorMessage) {
        return state;
      }
      return action.payload.data;
    default:
      return state;
  }
}

const close = (state = null, action) => {
  switch (action.type) {
    case CLOSE + '_SUCCESS':
      if (action.payload.data.errorMessage) {
        return state;
      }
      return action.payload.data;
    default:
      return state;
  }
}

export default combineReducers({ show, list, count, offset, detail, addComment, close });
