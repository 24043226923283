import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleSidebar } from '../../actions';
import { Button, Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonSpinner } from '@ionic/react';
import { permissions } from '../../routes';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import AreaCard from './AreaCard.js';
import { get, GET, clearItems, setFilter, clearItem } from '../../actions/areas';
import errorHandler from '../../errorHandler';
import PullToRefresh from 'pulltorefreshjs';
import FailNotice from '../../components/FailNotice';
import SimpleLineIcon from 'react-simple-line-icons';
import Can from '../../components/Can';
import { Add, Menu } from '@material-ui/icons';
import _ from 'lodash';


class AreaScreen extends Component {
  _ptr = null
  state = {
    isLoading: false,
    height: 0,
    showFilter: false,
    search: '',
    completed: false,
    items: []
  }

  componentDidMount () {
    this.props.clearItems();
    this.updateWindowDimensions();
    window.addEventListener('resize', () => this.updateWindowDimensions());
    this.initPtr();
    if (this.props.search) {
      this.setState({
        search: this.props.search
      })
      this.getItems(0, this.props.search);
    } else {
      this.getItems(0);
    }
  }

  updateWindowDimensions() {
    this.setState({ height: window.innerHeight });
  }

  initPtr = () => {
    this._ptr = PullToRefresh.init({
      mainElement: '.area-container',
      triggerElement: '.area-container',
      onRefresh: () => {
        this.props.clearItems();
        this.getItems(0, this.state.search);
      },
    });
  }

  

  getItems (offset = 0, search = '') {
    this.props.get(offset, search).then(res => {
      if (res.type === GET + '_FAIL') {
        errorHandler(this.props, res);
      } else if (res.type === GET + '_SUCCESS') {
        const tempItems = _.cloneDeep(this.props.items);
        tempItems.map(item => {
          item.states = [];
          item.cities = [];

          item.administrative_divisions.map((i) => {
            if (i.administrative_division.parent_id) {
              let parent = i.administrative_division.parent;
              if (i.administrative_division.division_level === 1 && !_.find(item.states, parent)) item.states.push(parent);
              if (i.administrative_division.division_level === 2 && !_.find(item.cities, parent)) item.cities.push(parent);
            }
          })
    
          item.states = _.uniqBy(_.concat(item.states, _.map(_.filter(item.administrative_divisions, (division) => {
            return division.administrative_division.division_level === 0;
          }), 'administrative_division')), 'id');

          item.cities = _.uniqBy(_.concat(item.cities, _.map(_.filter(item.administrative_divisions, (division) => {
            return division.administrative_division.division_level === 1;
          }), 'administrative_division')), 'id');

          return item;
        })

        this.setState({
          items: tempItems
        })

      }
    });
  }

  loadMore = (e) => {
    if (!this.props.loading) {
      this.getItems(this.props.offset + 10, this.state.search);

      e.target.complete();

      if (this.props.count > this.props.items.length) {
        this.setState({completed: false});
      } else {
        this.setState({completed: true});
      };
    }
  };

  getCurrentItems = () => {
    this.getItems(0, this.state.search);
  }

  onSearchChange = (e) => {
    this.setState({
      search: e.target.value
    })
  }

  onSearch = () => { 
    this.props.setFilter(this.state.search)
    this.props.clearItems();
    this.getItems(0, this.state.search);
  }

  handleClickAdd = () => {
    this.props.clearItem();
    this.props.history.push({
      pathname: '/all-price-add-area'
    })
  }

  handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  }


  render() {
      return (
        <Can redirect permissions={permissions(this.props.location.pathname)}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                edge="start"
                onClick={() => this.props.toggleSidebar(true)}
                style={{ color: "white" }}
                aria-label="Menu"
              >
                <Menu />
              </IconButton>
              <Typography variant="h6" style={{ color: "white", flexGrow: 1 }}>
                Area
              </Typography>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="add"
                sx={{ mr: 2 }}
                onClick={this.handleClickAdd}
              >
                <Add style={{color:'white'}}/>
              </IconButton>
            </Toolbar>
          </AppBar>

          <IonContent style={{ "--background": "#F0F6F6" }}>
            <div className="ritase-area-container" id="area-container" style={{maxHeight: this.state.height}}>
              <Container className="pb-3 pt-3">
                <Row>
                  <Col md={12}>
                    <InputGroup className="my-3 ritase-search ritase-search-input">
                      <FormControl onChange={e => this.onSearchChange(e)} onKeyDown={this.handleSearchKeyDown} value={this.state.search} placeholder="Cari Area" />
                      <SimpleLineIcon name="magnifier" onClick={() => this.onSearch() } />
                    </InputGroup>
                    <div className="area-container pt-3">
                      { !this.props.isLoading && this.props.fail && <FailNotice>
                        <h3 className="text-danger text-center">Gagal mengambil data</h3>
                        <Button onClick={() => this.getCurrentItems()} block variant="outline-info"><i className="fa fa-refresh"></i> Coba lagi</Button>
                      </FailNotice>}
                      { !this.props.isLoading && !this.props.fail && this.state.items.length === 0 && <FailNotice>
                        <h3 className="text-danger text-center">Tidak ada data</h3>
                      </FailNotice>}
                      { !this.props.fail && this.state.items.length > 0 && this.state.items.map(item => {
                            return (
                              <AreaCard key={item.id} className="pb-3" {...item} />
                            )
                          }
                        )}
                      { this.props.isLoading && <p class="text-center"><IonSpinner name="crescent"/></p>}
                    </div>
                    <IonInfiniteScroll 
                      threshold="10px"
                      disabled={this.state.completed}
                      onIonInfinite={this.loadMore}
                    >
                      <IonInfiniteScrollContent loadingSpinner="circular" />
                    </IonInfiniteScroll>
                  </Col>
                </Row>
              </Container>
            </div>
          </IonContent>
        </Can>
      );
  }
}

const mapstp = (state) => {
  const { areas } = state;
  return {
    loading: areas.loading,
    items: areas.list,
    count: areas.count,
    offset: areas.offset,
    search: (areas.filter && areas.filter.search) || ''
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ get, clearItems, setFilter, toggleSidebar, clearItem }, dispatch);
}

export default connect(mapstp, mapdtp)(AreaScreen);

