import React, { Component } from 'react';
import './PodSearch.scss';
import SimpleLineIcon from 'react-simple-line-icons';
import { Col, InputGroup, DropdownButton, Dropdown, FormControl } from 'react-bootstrap';
import { setFilterVisibility } from '../../actions/field';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const searchTypes = [
  {
    label: 'Shipment Number',
    value: 'shipment_number'
  },
  {
    label: 'Order Number',
    value: 'order_number'
  },
  {
    label: 'PO Number',
    value: 'po_number'
  },
  {
    label: 'Order ID',
    value: 'id'
  },
  {
    label: 'Trip ID',
    value: 'trip_id'
  },
];

const getlabelSelect = (value) => {
  for (let i = 0; i < searchTypes.length; i++) {
    if (searchTypes[i].value === value) {
      return searchTypes[i].label
    }
    
  }
}
class PodSearch extends Component {
  toggleFilter = () => {
    this.props.setFilterVisibility(true);
  }

  render() {
    const { searchTypeValue, searchValue, onSearch, onChangeType } = this.props;
    return (
      <div className="row p-fixed">
        <Col xs="12" className="pr-0">
          <InputGroup className="my-3 ritase-search">
            <DropdownButton
              title={getlabelSelect(searchTypeValue)}
              variant={null}
            >
              {searchTypes.map(type => <Dropdown.Item key={type.value} onClick={() => onChangeType(type.value)}>{type.label}</Dropdown.Item>)}
            </DropdownButton>
            <FormControl onChange={e => onSearch(e.target.value)} value={searchValue} placeholder="Mulai Cari" />
            <SimpleLineIcon name="magnifier" onClick={() => onSearch(searchValue)} />
          </InputGroup>
        </Col>
        {/* <Col xs="2" className="d-flex justify-content-flex-end align-items-center pl-0">
          <button className="btn btn-transparent p-0 pl-3 pr-3" onClick={() => this.toggleFilter()}>
            <SimpleLineIcon name="equalizer"></SimpleLineIcon>
          </button>
        </Col> */}
      </div>
    );
  }
}

const mapstp = (state) => {
  return {
    show: state.field.filterShow
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({setFilterVisibility}, dispatch)
}

export default connect(mapstp, mapdtp)(PodSearch)
