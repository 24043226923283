export const GET_NOTIF = 'NOTIF_GET';
export const GET_TOPIC = 'NOTIF_TOPICS';
export const UPDATE_TOPIC = 'NOTIF_UPDATE';
export const MARK_AS_READ_NOTIF = 'MARK_AS_READ_NOTIF';
export const SET_NOTIF = 'NOTIF_SET';

export const getAllNotifications = (offset = 0) => {
  return {
    type: GET_NOTIF,
    payload: {
      request: {
        url: `/notification_logs?populate=notification_data&limit=10&offset=${offset}`,
        method: 'get',
      },
    },
  };
};

export const getAllTopics = () => {
  return {
    type: GET_TOPIC,
    payload: {
      request: {
        url: '/notif_topics',
        method: 'get',
      },
    },
  };
};

export const createOrUpdate = (data) => {
  return {
    type: UPDATE_TOPIC,
    payload: {
      request: {
        url: '/notif_topics',
        method: 'post',
        data
      }
    }
  }
}

export const markAsRead = (data) => {
  return {
    type: MARK_AS_READ_NOTIF,
    payload: {
      request: {
        url: '/notification_logs/read',
        method: 'post',
        data
      }
    }
  }
}

export const setNotification = (data) => {
  return {
    type: SET_NOTIF,
    payload: {
      data
    }
  }
}