import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class Can extends React.Component {
  can = () => {
    let allow = false;
    if (this.props.role && this.props.permissions) {
      const permissions = this.props.role.permissions.map(({id, application, module, action, description}) => {
        return {
          id,
          application,
          module,
          action,
          description,
        };
      });
      let perms = this.props.permissions;
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        perms.push({
          id: 1,
          action: "*",
          application: "admin",
          description: "Access all admin modules",
          module: "*",
        });
      }
      perms.map((p) => {
        if (permissions.filter(x => x.application === p.application && x.module === p.module && x.id === p.id && x.action === p.action && x.description === p.description).length > 0) {
          allow = true;
          return false;
        }
        return false;
      });
    }
    return allow;
  }

  render() {
    if (this.props.role && !this.can()) {
      if (!this.props.redirect) {
        return null;
      }
      return <Redirect to="/403" />
    }
    return this.props.children;
  }
};

const mapstp = (state) => {
  const { role } = state.user.data;
  return {
    role
  }
}

export default connect(mapstp)(Can);
