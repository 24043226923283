import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import {
  IonContent,
  IonToast,
  IonSpinner
} from "@ionic/react";
import { ArrowBack } from '@material-ui/icons';
import Can from '../../components/Can';
import { permissions } from '../../routes';
import _ from 'lodash'
import './Area.scss';
import editIcon from '../../assets/icons/edit.svg';
import trashIcon from '../../assets/icons/trash.svg';
import Swal from 'sweetalert2';
import { remove as deleteArea } from '../../actions/areas';

const DetailScreen = props => {
  const { item, loading } = props;
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);

  const [showInfoToast, setShowInfoToast] = useState(false);
  const [msgInfoToast, setMsgInfoToast] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const {pathname} = props.location;

  const goBack = () => {
    props.history.goBack();
  }

  const refreshPostalCode = () => {
    let areas = _.concat(states, cities, districts);
    const parentIds = _.map(_.uniqBy(areas, 'parent_id'), 'parent_id');
    areas = _.filter(areas, (area) => {
      return typeof _.find(parentIds, (parentId) => {
        return area.id === parentId
      }) === 'undefined';
    });

    const postalCodes = _.map(_.uniqBy(areas, (area) => {
      return area.parent_id === null ? area.id : area.zipcode;
    }), (area) => {
      return area.zipcode;
    });

    setZipcodes(postalCodes);
  }

  useEffect(() => {
    if (!loading && (!item || (item && Object.keys(item).length === 0))) {
      goBack();
    } else if (!loading && item && Object.keys(item).length > 0) {
      let tempItem = _.cloneDeep(item);
      let tempStates = [];
      let tempCities = [];
      
      _.map(tempItem.administrative_divisions, (i) => {
        if (i.administrative_division.parent_id) {
          let parent = i.administrative_division.parent;
          if (i.administrative_division.division_level === 1 && !_.find(tempStates, parent)) tempStates.push(parent);
          if (i.administrative_division.division_level === 2 && !_.find(tempCities, parent)) tempCities.push(parent);
        }
      });

      
      setStates(_.uniqBy(_.concat(tempStates, _.map(_.filter(item.administrative_divisions, (division) => {
        return division.administrative_division.division_level === 0;
      }), 'administrative_division')), 'id'));

      setCities(_.uniqBy(_.concat(tempCities, _.map(_.filter(item.administrative_divisions, (division) => {
        return division.administrative_division.division_level === 1;
      }), 'administrative_division')), 'id'));

      setDistricts(_.map(_.filter(item.administrative_divisions, (division) => {
        return division.administrative_division.division_level === 2;
      }), 'administrative_division'));
    }
  }, [item, loading])

  useEffect(() => {
    refreshPostalCode();
  }, [states, cities, districts])

  const badgeStyle = {
    display: 'inline-block',
    border: '1px solid #D9D9D9',
    borderRadius: '50px',
    float: 'none'
  }

  const scrollableContainer = {
    overflowX: 'auto',
    whiteSpace: 'nowrap'
  }

  const handleToastMessage = (msg) => {
    setMsgInfoToast(msg);
    setShowInfoToast(true);
  }

  const handleDeleteArea = (id) => {
    if (!isLoading) {
      Swal.fire({
        title: 'Apakah anda yakin akan menghapus data ini?',
        html: 'Aksi yang anda lakukan tidak dapat dibatalkan, ketik <b>HAPUS INI</b> untuk konfirmasi',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'swal2-btn-area swal2-confirm-area',
          cancelButton: 'swal2-btn-area swal2-cancel-area'
        },
        allowOutsideClick: false,
        confirmButtonText: 'Hapus  ',
        cancelButtonText: 'Kembali',
        preConfirm: (command) => {
          if (!command) {
            Swal.showValidationMessage(
              'Harus diisi'
            )
          } else if (command && command !== 'HAPUS INI') {
            Swal.showValidationMessage(
              'Konfirmasi salah'
            )
          }
        },
      }).then((result) => {
        if (result.value) {
          setIsLoading(true);
          props.deleteArea(id, {})
            .then((result) => {
              if (result.payload.data && result.payload.data.errorMessage) {
                throw result.payload.data;
              } else {
                setIsLoading(false);

                Swal.fire({
                  title: 'Area berhasil dihapus',
                  showCancelButton: false,
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'swal2-btn-area swal2-cancel-area',
                  },
                  confirmButtonText: 'Tutup',
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.value) {
                    goBack();
                  }
                })
              }
          }).catch(error => {
              handleToastMessage((error && error.errorMessage) || 'Terdapat kesalahan');
              setIsLoading(false);
          })
        }
      })
    }
  }

  return (
    <Can redirect permissions={permissions(pathname)}>
        <IonContent>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                edge="start"
                onClick={() => goBack()}
                style={{ color: "white" }}
                aria-label="Menu"
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" style={{ color: "white" }}>
                Detail Area
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="app-container-1 detail-area-container">
            <Container className="detail-area pt-3">
              <Row>
                <Col>
                  <span className="title">
                    Nama Area
                  </span>
                  <span className="content">{item.name || '-'}</span>
                </Col>
                <Col>
                <span className="title">
                    ID
                  </span>
                  <span className="content">{item.id || '-'}</span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col>
                  <span className="title">
                    Cluster
                  </span>
                  <span className="content">
                  {
                    item && item.cluster_areas ? 
                    (
                      item.cluster_areas.map((cluster_area, index) =>
                        <span key={`cluster-${index}`}>
                          {cluster_area.cluster.description}
                          {item.cluster_areas.length - 1 === index ? '' : ', '}
                        </span>
                      )
                    ) : <span>-</span>
                  }
                  </span>
                </Col>
              </Row>
              <hr />
              <Row style={{ marginTop: '10px' }}>
                <Col>
                  <span className="title">Provinsi</span>
                  <div className="p-2" style={scrollableContainer}>
                    {
                      states.length ?
                      (
                        states.map((state, index) => 
                          <div key={`state-${index}`} className="p-1 pl-3 pr-3 mr-3" style={ badgeStyle }>
                            {_.get(state, 'name', '')}
                          </div>
                        )
                      ) : <span>-</span>
                    }
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col>
                  <span className="title">Kota / Kabupaten</span>
                  <div className="p-2" style={scrollableContainer}>
                    {
                      cities.length ?
                      (
                        cities.map((city, index) => {
                          const division_type = _.get(city, 'division_type', null);
                          const name = _.get(city, 'name', '');
                          const fullCityName = (division_type ? division_type + ' ' : '') + name;
                          return (
                            <div key={`city-${index}`} className="p-1 pl-3 pr-3 mr-3" style={ badgeStyle }>
                              { fullCityName }
                            </div>
                          )
                        })
                      ) : <span>-</span>
                    }
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col>
                  <span className="title">Kecamatan</span>
                  <div className="p-2" style={scrollableContainer}>
                    {
                      districts.length ?
                      (
                        districts.map((district, index) => 
                          <div key={`district-${index}`} className="p-1 pl-3 pr-3 mr-3" style={ badgeStyle }>
                            {_.get(district, 'name', '')}
                          </div>
                        )
                      ) : <span>-</span>
                    }
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px', marginBottom: '30px' }}>
                <Col>
                  <span className="title">Kode Pos</span>
                  {
                    zipcodes.length ?
                    (
                      zipcodes.map((zipcode, index) => 
                        <div key={`zipcode-${index}`}  style={{ display: 'inline' }}>
                          {zipcode}
                          {zipcodes.length - 1 === index ? '' : ', '}
                        </div>
                      )
                    ) : <span>-</span>
                  }
                </Col>
              </Row>
            </Container>
            <Container className="fixed-bottom d-flex justify-content-center" style={{ bottom: '20px' }}>
              <Row className="pt-2 pb-2" style={{ width: '100%' }}>
                <Col xs={4} md={3}>
                  <Button block variant="outline-danger" onClick={() => handleDeleteArea(item.id)}>
                    { isLoading ? <IonSpinner name="crescent"/> : <img src={trashIcon} alt='' /> }
                  </Button>
                </Col>
                <Col xs={8} md={9}>
                  <Button block variant="outline-primary" onClick={() => props.history.push('/all-price-update-area')}>
                    { isLoading ? <IonSpinner name="crescent"/> : (<span><img src={editIcon} alt='' />&nbsp;Edit</span>) }
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
          <IonToast
            isOpen={showInfoToast}
            onDidDismiss={() => { setShowInfoToast(false); setMsgInfoToast('');} }
            message={msgInfoToast}
            position="top"
            duration={4000}
        />
        </IonContent>
    </Can>
  );
}

const mapstp = (state) => {
  const { areas } = state
  return {
    item: areas.detail,
    loading: areas.loading
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ deleteArea }, dispatch);
}
export default connect(mapstp, mapdtp)(DetailScreen);
