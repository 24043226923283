export const GET_LIST = 'REPORT_GET_LIST';
export const DETAIL = 'REPORT_GET_DETAIL';
export const EXPORT = 'REPORT_EXPORT';

export const getList = (offset = 0, params = '') => {
  return {
    type: GET_LIST,
    payload: {
      request: {
        url: `/trips/monitoring?populate=company_template_report&limit=10&offset=${offset}${params}`,
        method: 'get',
      },
    },
  };
};

export const getDetail = (id, offset = 0, params = '') => {
  return {
    type: DETAIL,
    payload: {
      request: {
        url: `/trips/monitoring/report/${id}?populate=header.company_shipper,trip,trip.vehicle,trip.vehicle_type,order,order.destination,order.pickup,order.load,order.dropoff,order.unload&searchField=trip_id,order_id&limit=10&offset=${offset}${params}`,
        method: 'get',
      },
    },
  };
};

export const resetDetail = () => {
  return {
    type: DETAIL + '_RESET',
  }
}

export const exportReport = (id, params) => {
  return {
    type: EXPORT,
    payload: {
      request: {
        url: `/trips/monitoring/report/${id}/export?populate=header.company_shipper,trip,order,order.status,order.origin_contact,order.destination_contact,order.destination,order.load,order.dropoff,order.unload,trip.vehicle,trip.vehicle_type${params}`,
        method: 'get',
      }
    }
  }
}