import queryString from 'query-string';

export const GET = 'TRIP_GET';
export const SHOW = 'TRIP_SHOW';
export const STORE = 'TRIP_STORE';
export const UPDATE = 'TRIP_UPDATE';
export const DELETE = 'TRIP_DELETE';
export const CLEAR_ITEMS = 'TRIP_CLEAR_ITEMS';
export const SET_DETAIL = 'TRIP_SET_DETAIL';
export const UPDATE_REPORT = 'TRIP_UPDATE_REPORT';
export const SET_DETAIL_REPORT = 'TRIP_SET_DETAIL_REPORT';
export const SET_FILTER = 'TRIP_SET_FILTER';
export const FIND_TRIP = 'FIND_TRIP';
export const FIND_TRIP_BY_SHIPMENT_NUMBER = 'FIND_TRIP_BY_SHIPMENT_NUMBER';
export const UPDATE_TRIP = 'UPDATE_TRIP';
export const SUMMARY_TRIP = 'SUMMARY_TRIP';
export const SUMMARY_TRIP_TODAY = 'SUMMARY_TRIP_TODAY';
export const GET_DETAIL = 'TRIP_GET_DETAIL';
export const GET_DETAIL_DEFINE = 'TRIP_GET_DETAIL_DEFINE';
export const GET_DETAIL_TRIP = 'TRIP_GET_DETAIL_TRIP';

export const get = (prefix = 'trip_approval', offset = 0, status = 'submitted,rejected', company_shipper_id = '', params = '') => {
  const paramCompany = company_shipper_id?'&company_shipper_id='+company_shipper_id:'';
  return {
    type: prefix+GET,
    payload: {
      request: {
        url: '/trips/monitoring?limit=10&offset='+offset+'&populate=company_shipper,company_template_report&status=' + status+paramCompany+params,
      }
    }
  }
}

export const clearItems = (prefix = 'trip_approval') => {
  return {
    type: prefix + CLEAR_ITEMS
  }
}

export const detail = (item) => {
  return {
    type: SET_DETAIL,
    item,
  }
}

export const updateReport = (key, item) => {
  return {
    type: UPDATE_REPORT,
    key,
    item,
  }
}

export const update = (id, report_status, data) => {
  data = data.map(x => {
    const { trip_id, order_id, is_appropriate, note, pickup_at, start_loading_at, end_loading_at, loading_arrive_at, dropoff_at, unloading_at } = x;
    return {
      trip_id,
      order_id,
      is_appropriate: is_appropriate?true:false,
      note: note?note:'',
      pickup_at, 
      start_loading_at, 
      end_loading_at, 
      loading_arrive_at, 
      dropoff_at, 
      unloading_at
    };
  });
  return {
    type: UPDATE,
    payload: {
      request: {
        url: `/trips/monitoring/report/${id}`,
        method: 'PUT',
        data: {
          data,
          report_status,
        }
      }
    }
  }
}

export const setDetailReport = (report) => {
  return {
    type: SET_DETAIL_REPORT,
    report,
  }
}

export const setFilter = (filter) => {
  return {
    type: SET_FILTER,
    filter,
  }
}

export const findTrip = (trip_id, params=null) => ({
  type: FIND_TRIP,
  payload: {
    request: {
      url: `/trips/${trip_id}${params?`?${queryString.stringify(params)}`:``}`,
    },
  },
});

export const findTripByShipmentNumber = (shipment_number, params = {}) => ({
  type: FIND_TRIP_BY_SHIPMENT_NUMBER,
  payload: {
    request: {
      url: `/trips?${queryString.stringify({...params, limit: 1, shipment_number})}`,
    },
  },
});

export const updateTrip = (trip_id, data) => ({
  type: UPDATE_TRIP,
  payload: {
    request: {
      url: `/trips/${trip_id}`,
      method: 'put',
      data,
    },
  },
});

export const show = (report_id, company_shipper_id, is_compare_report=0, params = '') => {
  const prefix = is_compare_report?'_WITH_PREVIOUS':'';

  return (dispatch, getState) => {
    // const { filter } = getState().trip_approval;

    dispatch({
      type: SHOW + prefix,
      payload: {
        request: {
          url: `/trips/monitoring/report/${report_id}?company_shipper_id=${company_shipper_id}&is_compare_report=${is_compare_report}&populate=trip.driver,header.company_shipper,trip,order,order.status,order.origin_contact,order.destination_contact,order.destination,order.load,order.unload,trip.vehicle,trip.vehicle_type,order.pickup,order.load,order.dropoff,order.unload`,
        }
      }
    })
  }
}

export const getTripSummary = () => {
  return {
    type: SUMMARY_TRIP,
    payload: {
      request: {
        url: '/trips/summary',
        method: 'get',
      },
    },
  };
}

export const getTripSummaryToday = () => {
  return {
    type: SUMMARY_TRIP_TODAY,
    payload: {
      request: {
        url: '/trips/summary?created_at=today',
        method: 'get',
      },
    },
  };
}

export const getDetail = (id) => {
  return {
    type: GET_DETAIL,
    payload: {
      request: {
        url: `/trips/${id}?populate=vehicle,driver,company_transport,company_shipper.config,vehicle.media,detail_reports,issues,issues.reporter,issues.resolver,issues.reasons,vehicle.pool.location,all_order_offers,destination,vehicle.gps_vehicle,company_broker,vehicle.type&populate_active_trip=1&view_group=ongoing`,
        method: 'get',
      },
    },
  };
}

export const getDetailDefine = (id, params, type = GET_DETAIL_DEFINE) => {
  return {
    type,
    payload: {
      request: {
        url: `/trips/${id}${params?`?${queryString.stringify(params)}`:``}`,
        method: 'get',
      },
    },
  }
}
