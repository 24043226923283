import React from 'react'
import {Container, Row, Col, Card} from 'react-bootstrap'
import moment from 'moment'
import {login} from '../../actions/user'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import LoginForm from './LoginForm'
import Meta from 'react-meta-tags'
import logo from '../../assets/logo.png'
import { USER_LOGIN } from '../../actions/user'
import { Redirect } from 'react-router-dom'
import { version } from '../../../package.json'

class LoginScreen extends React.Component {
  state = {
    year: moment().format('Y'),
    loading: false,
    errorMessage: ''
  }

  onSubmit = (data) => {
    this.setState({errorMessage: ''});
    const body = {
      ...data,
      user_type: 'superadmin',
    };
    this.props.login(body).then(res => {
      if (res.type===USER_LOGIN+'_SUCCESS') {
        const { data, status, statusText } = res.payload;
        switch (status) {
          case 200:
            const errorMessage = data.errorMessage;
            if (errorMessage) {
              // if has error
              this.setState({errorMessage})
              return false;
            } else {
              this.props.history.push('/')
            }
            break;
          default:
            this.setState({errorMessage: statusText})
        }
      } else if (res.type===USER_LOGIN+'_FAIL') {
        const {error} = res;
        var msg;
        switch (error.status) {
          default:
            msg = error.data
        }
        this.setState({errorMessage: msg})
      }
    })
  }

  render () {
    if (this.props.token) {
      return (
        <Redirect to="/"></Redirect>
      )
    }
    return (
      <div className="ritase-login-wrapper w-100 h-100 d-inline-block p-sm-relative" style={{position: 'absolute'}}>
        <Meta>
          <title>Ritase.com - Internal</title>
        </Meta>
        <Container fluid className="h-100">
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md="6" sm="8" className="text-center mt-3 mb-3">
              <Card>
                <Card.Body>
                  <img src={logo} className="text-center mb-3" height="50" alt=""/>
                  <h4 className="text-primary">Ritase Internal</h4>
                  <h6 className="card-title bold text-center" style={{fontWeight: 'bold'}}>Please login to your account</h6>
                  <LoginForm errorMessage={this.state.errorMessage} onSubmit={this.onSubmit} />
                  <p className="text-center m-0 p-0 mt-3 text-dark">©PT Digital Truk Indonesia · {this.state.year}</p>
                  <small className="text-muted">Ritase Internal - Version {version}</small>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

// Map state to props
const mapstp = (state) => {
  return {
    token: state.user.token,
  }
}

// Map dispatch to props
const mapdtp = (dispatch) => {
  return bindActionCreators({login}, dispatch)
}

export default connect(mapstp, mapdtp)(LoginScreen);
