export const GET = 'CONTACT_GET';

export const getLists = (params = '') => {
  return {
    type: GET,
    payload: {
      request: {
        url: 'contacts?limit=10&populate=location,contact_areas.area&searchFields=name,id'+params,
        method: 'get',
      }
    }
  }
}
