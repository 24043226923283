import React, { Component } from "react";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setTitlePage, toggleHeader, toggleSidebar } from '../../actions';
import { getPrices, detail, getAreas, getTransportRoute, setFilter } from '../../actions/all_prices';
import { get as getVehicleType } from '../../actions/vehicle_type';
import { get as getCompanies } from '../../actions/companies';
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { Button, Card, Col, Container, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { permissions } from '../../routes';
import { AppBar, FormControl, Drawer, Toolbar, Typography, IconButton } from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import PriceCard from './PriceCard';
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { Add, Menu, CheckBox as CheckBoxIcon  } from '@material-ui/icons';
import PullToRefresh from 'pulltorefreshjs';
import Can from '../../components/Can';
import './style.scss'
import debounce from 'lodash/debounce'

import {client_params, transporter_params} from './params'

class PriceListScreen extends Component {
  constructor(props){
    super(props);
    this.state = {
      showFilter: false,
      completed: false,
      loading: true,
      dataPage: null
    }
  }

  async getDetailPage() {
    const {pathname} = await this.props.location;
    if (pathname === '/all-price/transporter-prices') {
      return {
        titlePage: 'Transporter',
        params: transporter_params,
        prefix: 'all_price_transporter',
      }
    } else {
      return {
        titlePage: 'Client',
        params: client_params,
        prefix: 'all_price_client'
      }
    }
  }

  detail (e, item) {
    e.preventDefault();
    this.props.detail({prefix: this.state.dataPage.prefix, item});
    this.props.history.push({
      pathname: '/all-price/price-detail',
      state: {dataPage: this.state.dataPage}
    })
  }

  handleClickAdd = () => {
    this.props.history.push({
      pathname: '/all-price/tambah-harga',
      state: {dataPage: this.state.dataPage}
    })
  }
  
  async componentDidMount() {
    this.props.toggleHeader(false);
    const dataPage = await this.getDetailPage();
    this.props.setFilter({}, dataPage.prefix)
    this.props.getPrices({prefix: dataPage.prefix, params: dataPage.params}).then(() => {
      this.setState({loading: false, dataPage: dataPage})
    });
  }

  componentWillUnmount() {
    this.props.toggleHeader(true);
    PullToRefresh.destroyAll();
  }

  showFilterDrawer = (show) => {
    this.setState({ showFilter: show })
  }

  toggleDrawer = (open) => (event) => {
    if (event.type && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ showFilter: open });
  };

  handleSort = ({asc, sort}) => {
    this.props.setSort(sort, asc)
    this.props.getPrices({offset: 0, params: this.props.filter, sort: {asc, sort}})
  }
  
  handleFilter = (filter) => {
    this.setState({loading: true, completed: false})
    this.props.setFilter(filter, this.state.dataPage.prefix)
    this.props.getPrices({prefix: this.state.dataPage.prefix, offset:0, params: {...this.state.dataPage.params, ...filter}}).then(() => {
      this.setState({loading: false})
    });
  }

  clearFilter = () => {
    this.setState({loading: true, completed: false})
    this.props.setFilter({}, this.state.dataPage.prefix)
    this.props.getPrices({prefix: this.state.dataPage.prefix, params: this.state.dataPage.params}).then(() => {
      this.setState({loading: false})
    });
  }

  loadMore = (e) => {
    this.props.getPrices({prefix: this.state.dataPage.prefix, offset: this.props.offset + 10, params: {...this.state.dataPage.params, ...this.props.filter}});

    e.target.complete();
    
    if (this.props.count > this.props.list.length) {
      this.setState({completed: false});
    } else {
      this.setState({completed: true});
    };
  };

  render() {
    const { showFilter, completed, loading, dataPage } = this.state;
    const { filter, list, loading: loadingFetcher, toggleHeader } = this.props;

    dataPage && toggleHeader(dataPage.titlePage);
    
    return (
      <Can redirect permissions={permissions(this.props.location.pathname)}>
        
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              size='large'
              edge="start"
              onClick={() => this.props.toggleSidebar(true)}
              style={{ color: "white" }}
              aria-label="Menu"
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" style={{ color: "white", flexGrow: 1 }}>
              {dataPage && dataPage.titlePage}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="filter"
              sx={{ mr: 2 }}
              onClick={()=>this.showFilterDrawer(true)}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0686 20.425C10.3231 20.5588 10.6061 20.6291 10.8936 20.63C11.2389 20.6292 11.5764 20.5266 11.8636 20.335L14.3636 18.67C14.8518 18.3471 15.1449 17.8003 15.1436 17.215V12.51L19.7986 6.65C20.3358 5.97411 20.4389 5.05043 20.0639 4.27275C19.6889 3.49506 18.902 3.00053 18.0386 3H6.24863C5.38525 3.00053 4.59832 3.49506 4.22333 4.27275C3.84834 5.05043 3.95142 5.97411 4.48863 6.65L9.14363 12.51V18.88C9.14412 19.526 9.49945 20.1195 10.0686 20.425ZM5.57363 4.92C5.69697 4.6599 5.9608 4.49574 6.24863 4.5H18.0586C18.3465 4.49574 18.6103 4.6599 18.7336 4.92C18.8618 5.17977 18.8266 5.49046 18.6436 5.715L13.8086 11.78C13.701 11.9129 13.6427 12.079 13.6436 12.25V17.215C13.6431 17.3051 13.5932 17.3877 13.5136 17.43L11.0136 19.1C10.9335 19.1475 10.8338 19.1475 10.7536 19.1C10.6725 19.0569 10.6223 18.9719 10.6236 18.88V12.25C10.6246 12.079 10.5663 11.9129 10.4586 11.78L5.64363 5.715C5.46715 5.48637 5.43982 5.17595 5.57363 4.92Z" fill="white"/>
              </svg>
            </IconButton>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="add"
              sx={{ mr: 2 }}
              onClick={this.handleClickAdd}
            >
              <Add style={{color:'white'}}/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <IonContent style={{ '--background': '#F0F6F6' }}>
          <Container fluid className="container-all-price">
            {!loadingFetcher && list.length === 0 && (
              <Card body>
                No data found
              </Card>
            )}

            {loading && (<React.Fragment>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </React.Fragment>)}

            {!loading && (
              <React.Fragment>
                {list.map((item, i) =>
                  (<PriceCard key={`${i}-${item.id}`} item={item} history={this.props.history} onPress={(e) => this.detail(e, item)} dataPage={this.state.dataPage}/>)
                )}

                <IonInfiniteScroll 
                  threshold="10px"
                  disabled={completed}
                  onIonInfinite={this.loadMore}
                >
                  <IonInfiniteScrollContent loadingSpinner="circular" />
                </IonInfiniteScroll>
              </React.Fragment>
            )}
          </Container>
          <DrawerFilter 
            open={showFilter} 
            initialValue={filter || {}} 
            handleCancel={()=>this.showFilterDrawer(false)} 
            onSubmit={this.handleFilter}
            transport_types={this.props.transport_types}
            has_quotations={this.props.has_quotations}
            dataPage={dataPage}
            clearFilter={this.clearFilter}/>
        </IonContent>
      </Can>
    );
  }
}

const DrawerFilter = ({ open,initialValue, handleCancel, onSubmit, transport_types, dataPage, clearFilter, has_quotations }) => {
  const [company, setCompany] = React.useState(initialValue.company_id ? initialValue.company_id?.split(",").map((item)=>{return {label: item, value: item}}) : [])
  const [shipper, setShipper] = React.useState(initialValue.company_shipper_id)
  const [transport_type, setTransportType] = React.useState(initialValue.transport_type)
  const [origin, setOrigin] = React.useState(initialValue.origin_area_id)
  const [destination, setDestination] = React.useState(initialValue.destination_area_id)
  const [unit, setUnit] = React.useState(initialValue.vehicle_type_id)
  const [transportRoute, setTransportRoute] = React.useState(initialValue.route_code_id)
  const [hasEquotation, setHasEquotation] = React.useState(initialValue.has_equotation)
  const dispatch = useDispatch()

  const loadVehicleType = debounce((inputValue, callback) => {
    const query = `searchFields=name&search=${inputValue}&format=dropdown`
    dispatch(getVehicleType(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d.id, label: d.name })).sort((a,b)=>a.label>b.label?1:-1) : []
      callback([
        {label: 'Semua Unit', value: ''},
        ...data
      ])
    })
  }, 500);

  const loadTransporter = debounce((inputValue, callback) => {
    const query = `searchFields=name&search=${inputValue}&company_type=transport&fields=id,name&sort=name`
    dispatch(getCompanies(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d.id, label: d.name })) : []
      callback([
        {label: 'Semua Perusahaan', value: ''},
        ...data
      ])
    })
  }, 500);

  const loadShipper = debounce((inputValue, callback) => {
    const query = `searchFields=name&search=${inputValue}&company_type=client&fields=id,name&sort=name`
    dispatch(getCompanies(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d.id, label: d.name })) : []
      callback([
        {label: 'Semua Perusahaan', value: ''},
        ...data
      ])
    })
  }, 500);

  const loadOrigin = debounce((inputValue, callback) => {
    const query = `searchFields=name&limit=15&search=${inputValue}&populate=administrative_divisions`
    dispatch(getAreas(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d, label: d.name })) : []
      callback([
        {label: 'Semua Area', value: ''},
        ...data
      ])
    })
  }, 500);

  const loadDestination = debounce((inputValue, callback) => {
    const query = `searchFields=name&limit=15&search=${inputValue}&populate=administrative_divisions`
    dispatch(getAreas(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d, label: d.name })) : []
      callback([
        {label: 'Semua Area', value: ''},
        ...data
      ])
    })
  }, 500);

  const loadTransportRoute = debounce((inputValue, callback) => {
    const query = `&searchFields=code&limit=15&search=${inputValue}`
    dispatch(getTransportRoute(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d.id, label: d.code })).sort((a,b)=>a.label>b.label?1:-1) : []
      callback([
        {label: 'Semua Route Code', value: ''},
        {label: 'Tanpa Route Code', value: 0},
        ...data
      ])
    })
  }, 500);

  const handleClearFilter = () => {
    setCompany();
    setShipper();
    setTransportRoute();
    setOrigin();
    setDestination();
    setUnit();
    setTransportType();
    setHasEquotation();
    clearFilter();
    handleCancel({});
  }

  const apply = () => {
    const filter = {}
    if(hasEquotation && hasEquotation.value>=0) {
      filter.has_equotation = hasEquotation.value
    }
    if(company && company.length) {
      filter.company_id = company.map(item=>{return item.value}).join(",");
    }
    if(transport_type && transport_type.value) {
      filter.transport_type = transport_type.value
    }
    if(origin && origin.value) {
      filter.origin_area_id = origin.value.id
    }
    if(destination && destination.value) {
      filter.destination_area_id = destination.value.id
    }
    if(unit && unit.value) {
      filter.vehicle_type_id = unit.value
    }
    if(transportRoute && transportRoute.value) {
      filter.route_code_id = transportRoute.value
    }
    if(shipper && shipper.value) {
      filter.company_shipper_id = shipper.value
    }
    onSubmit(filter);
    handleCancel({});
  }
  
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleCancel}
      classes={{ root: "MuiDrawer-paper borderless-top" }}
    >
      <Card body>
        
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <strong>Filter</strong>
          </FormLabel>
        </FormControl>

        <Form>
          <FormGroup className="mb-3">
            <FormLabel>Tipe Transporter</FormLabel>
            <Select
              value={transport_type}
              placeholder="Semua Transporter Type"
              options={transport_types}
              onChange={(val) => setTransportType(val)}
              isClearable
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel>Perusahaan</FormLabel>
            <AsyncSelect
              isMulti
              placeholder="Semua Perusahaan"
              cacheOptions
              defaultOptions
              value={company}
              loadOptions={dataPage && dataPage.params.company_type==='transport' ? loadTransporter : loadShipper}
              onChange={(val) => setCompany(val)}
              getOptionLabel={({ label }) => label} 
              getOptionValue={({ value }) => value}
              isClearable
            />
          </FormGroup>
          <FormGroup className="mb-3">
          <FormLabel>Unit</FormLabel>
            <AsyncSelect
              placeholder="Semua Unit"
              cacheOptions
              defaultOptions
              value={unit}
              loadOptions={loadVehicleType}
              onChange={(val) => setUnit(val)}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel>Asal</FormLabel>
            <AsyncSelect
              placeholder="Semua Asal"
              cacheOptions
              defaultOptions
              value={origin}
              loadOptions={loadOrigin}
              onChange={(val) => setOrigin(val)}
              getOptionLabel={({ value, label }) => {
                return (
                  <div>
                    <CheckBoxIcon color={value.administrative_divisions ? value.administrative_divisions.length ? 'primary' : 'error' : 'primary'}/>
                    {label}
                  </div>
                )
              }}
              getOptionValue={({ value }) => value}
              isClearable 
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel>Tujuan</FormLabel>
            <AsyncSelect
              placeholder="Semua Tujuan"
              cacheOptions
              defaultOptions
              value={destination}
              loadOptions={loadDestination}
              onChange={(val) => setDestination(val)}
              getOptionLabel={({ value, label }) => {
                return (
                  <div>
                    <CheckBoxIcon color={value.administrative_divisions ? value.administrative_divisions.length ? 'primary' : 'error' : 'primary'}/>
                    {label}
                  </div>
                )
              }}
              getOptionValue={({ value }) => value}
              isClearable
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel>Route Code</FormLabel>
            <AsyncSelect
              placeholder="Semua Route Code"
              cacheOptions
              defaultOptions
              value={transportRoute}
              loadOptions={loadTransportRoute}
              onChange={(val) => setTransportRoute(val)}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </FormGroup>
          {
            dataPage && dataPage.params.company_type==='transport' && (
              <FormGroup className="mb-3">
                <FormLabel>Shipper</FormLabel>
                <AsyncSelect
                  placeholder="Semua Shipper"
                  cacheOptions
                  defaultOptions
                  value={shipper}
                  loadOptions={loadShipper}
                  onChange={(val) => setShipper(val)}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                />
              </FormGroup>
            )
          }
          {
            dataPage && dataPage.params.company_type==='client' && (
              <FormGroup className="mb-3">
                <FormLabel>E-quotation</FormLabel>
                <Select
                  value={hasEquotation}
                  placeholder="E-quotation"
                  options={has_quotations}
                  onChange={(val) => setHasEquotation(val)}
                  isClearable
                />
              </FormGroup>
            )
          }
        </Form>

        <hr />
        <Row>
          <Col>
            <Button
              size="lg"
              block
              variant="outline-secondary"
              onClick={handleClearFilter}
            >
              Clear
            </Button>
          </Col>
          <Col>
            <Button size="lg" block onClick={apply}>
              Apply
            </Button>
          </Col>
        </Row>
      </Card>
    </Drawer>
  );
}

const LoadingCard = () => {
  return (
    <Card className="shadow-sm ritase-card mb-3">
      <Card.Body className="p-3">
        <ContentLoader height={55}>
          <rect x="0" y="0" rx="6" ry="6" width="65%" height="20" />
          <rect x="0" y="30" rx="6" ry="6" width="50%" height="20" />
        </ContentLoader>
        <ContentLoader height={20}>
          <rect x="0" y="0" rx="6" ry="6" width="30%" height="20" />
        </ContentLoader>
      </Card.Body>
    </Card>
    )
}

const mapstp = (state, props) => {
  const {pathname} = props.location;
  const stateName =  pathname === '/all-price/transporter-prices' ? 'all_price_transporter' : 'all_price_client';

  const transport_type_array = ['Land', 'Sea', 'Air']
  const transport_types = transport_type_array.map((item)=> {
    return {
      value: item,
      label: item
    }
  })

  const has_quotation_array = ['Semua harga', 'Tidak memiliki e-quotation', 'Memiliki e-quotation' ]
  const has_quotations = has_quotation_array.map((item, index)=> {
    return {
      value: index-1,
      label: item
    }
  })

  const { list, loading, count, filter, sort, offset } = state[stateName];
  return {
    list,
    loading,
    filter,
    sort,
    count,
    offset,
    transport_types,
    has_quotations
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ setTitlePage, getPrices, toggleHeader, detail, getAreas, getVehicleType, getTransportRoute, getCompanies, toggleSidebar, setFilter }, dispatch)
}

export default connect(mapstp, mapdtp)(PriceListScreen)