import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Container, Row, Col } from 'react-bootstrap';
import TripList from './TripList';
import Select from 'react-select';
import { get, GET, clearItems, detail } from '../../actions/trip';
import { logout } from '../../actions/user';
import ContentLoader from 'react-content-loader';
import RitaseCard from '../../components/RitaseCard';
import errorHandler from '../../errorHandler';
import PullToRefresh from 'pulltorefreshjs';
import FailNotice from '../../components/FailNotice';
import $ from 'jquery';

class TripLists extends Component {
  _ptr = null
  state = {
    company_shipper_id: '',
    isLoading: false,
    height: 0,
    showFilter: false,
  }

  componentDidMount () {
    this.updateWindowDimensions();
    window.addEventListener('resize', () => this.updateWindowDimensions());
    this.initPtr();
    const tripContainer = $('#'+this.props.trip);
    const obj = document.getElementById(this.props.trip);
    tripContainer.scroll(() => {
      if(window.pageYOffset === 0) {
        this.initPtr()
      } else {
        this._ptr.destroy();
      }
      if (obj.scrollTop === (obj.scrollHeight - obj.offsetHeight)) {
        const offset = this.props.offset + 1;
        const isLast = parseInt(this.props.count) === this.props.items.length;
        if (!this.props.loading && !this.state.isLoading && !isLast) {
          this.getItems(offset, this.state.company_shipper_id);
        }
        this.setState({isLoading: true})
        setTimeout(() => {
          this.setState({isLoading: false})
        }, 1000)
      }
    });
    this.getItems();
  }

  updateWindowDimensions() {
    this.setState({ height: window.innerHeight });
  }

  initPtr = () => {
    this._ptr = PullToRefresh.init({
      mainElement: '.'+this.props.trip,
      triggerElement: '.'+this.props.trip,
      onRefresh: () => {
        this.props.clearItems(this.props.trip);
        this.getItems(0, this.state.company_shipper_id);
      },
    });
  }

  detail (item) {
    this.props.detail(item);
  }

  getItems (offset = 0, company_shipper_id = '') {
    this.props.get(this.props.trip, offset, this.props.status, company_shipper_id).then(res => {
      if (res.type === GET + '_FAIL') {
        errorHandler(this.props, res);
      }
    });
  }

  onChangeCompanyShipper = (company_shipper_id) => {
    this.setState({ company_shipper_id });
    this.props.clearItems(this.props.trip);
    this.getItems(0, company_shipper_id);
  }

  getCurrentItems = () => {
    this.getItems(0, this.state.company_shipper_id);
  }

  toggleFilter = () => {
    this.setState({ showFilter: true });
  }

  onFilter = () => {
    this.props.clearItems(this.props.trip);
    this.getItems(0, this.state.company_shipper_id);
  }

  render() {
    const LoadingBars = () => [0,0,0,0,0].map((x, k) => <RitaseCard key={k}>
      <ContentLoader
        height={54}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="0" y="0" rx="5" ry="5" width="117" height="15" />
        <rect x="0" y="30" rx="5" ry="5" width="150" height="15" />
        <rect x="50%" y="20" rx="5" ry="5" width="30%" height="15" />
        <rect x="85%" y="20" rx="5" ry="5" width="15%" height="15" />
      </ContentLoader>
    </RitaseCard>);

    const customStyles =   {
      container: styles => ({
        ...styles,
        marginBottom: '0px !important',
      }),
      control: styles => ({
        ...styles,
        padding: 5,
        borderRadius: 35,
      }),
      menu: styles => ({
        ...styles,
        height: 180,
        maxHeight: 180,
      }),
      menuList: styles => ({
        ...styles,
        maxHeight: 180,
      }),
    }

    return (
      <div className="ritase-trip-container" id={this.props.trip} style={{maxHeight: this.state.height}}>
        <Container className="pb-3 pt-3">
          <Row>
            <Col md={12}>
              <Select className="mb-3" styles={customStyles} classNamePrefix="ritase-shipper" placeholder="Pilih Shipper" options={this.props.shippers} isClearable onChange={(e) => this.onChangeCompanyShipper(e?e.value:'')} />
              <div className={this.props.trip+' pt-3'}>
                {this.props.fail && <FailNotice>
                  <h3 className="text-danger text-center">Gagal mengambil data</h3>
                  <Button onClick={() => this.getCurrentItems()} block variant="outline-info"><i className="fa fa-refresh"></i> Coba lagi</Button>
                </FailNotice>}
                {!this.props.fail && !this.props.loading && this.props.items.length === 0 && <FailNotice>
                  <h3 className="text-danger text-center">Tidak ada data</h3>
                </FailNotice>}
                { !this.props.fail && this.props.items.map((item, key) => <TripList key={key} {...item} onPress={() => this.detail(item)} />)}
                { this.props.loading && <LoadingBars /> }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapstp = (state, props) => {
  const { shipper } = state;
  const shippers = shipper.lists.map(shipper => {
    return {
      value: shipper.id,
      label: shipper.name,
    }
  });
  const { items, loading, offset, fail, count, filter } = state[props.trip];
  return {
    shippers,
    items,
    loading,
    offset,
    fail,
    count,
    filter
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ get, clearItems, detail, logout }, dispatch);
}

export default connect(mapstp, mapdtp)(TripLists);
