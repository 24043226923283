export const GET_PRICES = 'ALL_PRICE_GET_PRICES';
export const SET_SORT = 'ALL_PRICE_SET_SORT';
export const SET_FILTER = 'ALL_PRICE_SET_FILTER';
export const CLEAR_LIST = 'ALL_PRICE_CLEAR_LIST';
export const SET_DETAIL_PRICES = 'ALL_PRICE_SET_DETAIL_PRICES';
export const GET_ORIGIN_COVERAGE = 'ALL_PRICE_GET_ORIGIN_COVERAGE';
export const GET_DESTINATION_COVERAGE = 'ALL_PRICE_GET_DESTINATION_COVERAGE';
export const GET_COMPANIES = 'ALL_PRICE_GET_COMPANIES';
export const GET_VEHICLE_TYPE = 'ALL_PRICE_GET_VEHICLE_TYPE';
export const GET_AREAS = 'ALL_PRICE_GET_AREAS';
export const GET_TRANSPORT_ROUTE = 'ALL_PRICE_GET_TRANSPORT_ROUTE';
export const UPDATE_ADD_PRICES = 'UPDATE_ADD_PRICES';
export const DELETE_PRICES = 'DELETE_PRICES';

export const getPrices = ({prefix= 'all_price_transporter',offset = 0, params={}, sort = {}}) => {
  return {
    type: prefix+GET_PRICES,
    payload: {
      request: {
        url: `/prices`,
        method: 'get',
        params: {
          limit: 10,
          offset,
          ...params,
          ...sort
        }
      }
    }
  }
}

export const setSort = (column, type = 'DESC') => {
  return {
    type: SET_SORT,
    payload: {
      sort: column,
      asc: type
    }
  }
}

export const setFilter = (filters, prefix) => {
  return {
    type: prefix+SET_FILTER,
    payload: filters
  }
}

export const clear = (filters, prefix) => {
  return {
    type: prefix+CLEAR_LIST,
    payload: filters
  }
}

export const detail = ({prefix= 'all_price_transporter', item}) => {
  return {
    type: prefix+SET_DETAIL_PRICES,
    item
  };
}

const coveragePayload = (prefix, action, id) => {
  return {
    type: prefix+action,
    payload: {
      request: {
        url: `/areas/${id}`,
        method: 'get',
        params: {
          populate: 'administrative_divisions.administrative_division'
        }
      }
    }
  }
}

export const getOriginCoverage = ({prefix= 'all_price_transporter', id}) => {
  return coveragePayload(prefix, GET_ORIGIN_COVERAGE, id)
}

export const getDestinationCoverage = ({prefix= 'all_price_transporter', id}) => {
  return coveragePayload(prefix, GET_DESTINATION_COVERAGE, id)
}

export const getCompanies = ({prefix= 'all_price_transporter'}) => {
  return {
    type: prefix+GET_COMPANIES,
    payload: {
      request: {
        url: `/companies?company_type=${prefix=== 'all_price_transporter'?'transport':'client'}&fields=id,name,code,company_type`,
        method: 'get'
      }
    }
  }
}

export const getVehicleType = () => {
  return {
    type: GET_VEHICLE_TYPE,
    payload: {
      request: {
        url: `/vehicle_type?format=dropdown`,
        method: 'get'
      }
    }
  }
}

export const getAreas = (params = 'is_filter=1') => {
  return {
    type: GET_AREAS,
    payload: {
      request: {
        url: `/areas?${params}`,
        method: 'get'
      }
    }
  }
}

export const getTransportRoute = (params = 'is_filter=1') => {
  return {
    type: GET_TRANSPORT_ROUTE,
    payload: {
      request: {
        url: `/transport_routes?format=dropdown${params}`,
        method: 'get'
      }
    }
  }
}

export const updateAddPrices = ({payload={}, id}) => {
  return {
    type: UPDATE_ADD_PRICES,
    payload: {
      request: {
        url: `/prices${id ? `/${id}` : ''}`,
        method: 'put',
        data: {
          ...payload,
        }
      }
    }
  }
}

export const deletePrice = (id) => {
  return {
    type: DELETE_PRICES,
    payload: {
      request: {
        url: `/prices/${id}`,
        method: 'delete',
      }
    }
  }
}