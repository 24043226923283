import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from '../actions/shipper';

class GetShipper extends React.Component {

  componentDidMount () {
    this.get(this.props);
  }

  componentDidUpdate() {
    this.get(this.props);
  }

  get = (props) => {
    if (props.token) {
      props.get('fields=id,name,company_type,code')
    }
  }

  render() {
    return null
  }
}

const mapstp = (state) => {
  return {
    token: state.user.token,
  };
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ get }, dispatch);
}

export default connect(mapstp, mapdtp)(GetShipper);
