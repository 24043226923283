import { combineReducers } from 'redux';
import { GET } from '../actions/shipper';
import ls from 'local-storage';

const initLists = ls.get('SHIPPER_LISTS')?ls.get('SHIPPER_LISTS'):[];
const lists = (state = initLists, action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      const { rows } = action.payload.data;
      if (rows) {
        ls.set('SHIPPER_LISTS', rows);
        return rows;
      }
      return state;
    default:
      return state;
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case GET + '_FAIL':
      return false;
    case GET + '_SUCCESS':
      return false;
    default:
      return state;
  }
}

export default combineReducers({ lists, loading });
