import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getData } from '../actions/user'

class GetUserData extends React.Component {
  componentDidMount() {
    this.getData(this.props.token, this.props.data);
  }

  componentDidUpdate() {
    this.getData(this.props.token, this.props.data);
  }

  getData (token, data) {
    if (token && !data.role) {
      this.props.getData();
    }
  }

  render() {
    return null
  }
}

const mapstp = (state) => {
  const { user } = state
  return {
    data: user.data,
    token: user.token
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ getData }, dispatch);
}

export default connect(mapstp, mapdtp)(GetUserData)
