import {combineReducers} from 'redux';
import get from 'lodash/get';
import {SHOW, GET, DETAIL, EXPORT, GET_INBOUND} from '../actions/order';
import { USER_IMPERSONATE } from '../actions/user';

const show = (state = [], action) => {
    switch (action.type) {
        case SHOW + '_SUCCESS':
            if (action.payload.data.errorMessage) {
                return state;
            }
            return action.payload.data;
        default:
            return state;
    }
}

const initOrderDetail = {
    loading: false,
    data: null,
    error: null,
}

const orderDetail = (state = initOrderDetail, {type, payload, error}) => {
    switch (type) {
        case SHOW:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case SHOW + '_FAIL':
        case SHOW + '_SUCCESS':
            return {
                ...state,
                loading: false,
                data: !get(payload, 'data.errorMessage') && get(payload, 'data', null),
                // eslint-disable-next-line no-mixed-operators
                error: error || get(payload, 'data.errorMessage') && get(payload, 'data') || null,
            }
        default:
            return state;
    }
}

const list = (state = [], action) => {
    switch (action.type) {
        case USER_IMPERSONATE + '_SUCCESS':
            return [];
        case GET + '_SUCCESS':
            const {errorMessage, rows, offset} = action.payload.data;
            if (errorMessage) {
                return state;
            }
            if (offset > 0) {
                return state.concat(rows)
            }
            return rows;
        default:
            return state;
    }
}

const count = (state = 0, action) => {
    switch (action.type) {
        case USER_IMPERSONATE + '_SUCCESS':
            return 0;
        case GET + '_SUCCESS':
            const {errorMessage, count} = action.payload.data;
            if (errorMessage) {
                return state;
            }
            return count;
        default:
            return state;
    }
}

const offset = (state = 0, action) => {
    switch (action.type) {
        case USER_IMPERSONATE + '_SUCCESS':
            return 0;
        case GET + '_SUCCESS':
            const {errorMessage, offset} = action.payload.data;
            if (errorMessage) {
                return state;
            }
            return offset;
        default:
            return state;
    }
}

const detail = (state = {}, action) => {
    switch (action.type) {
        case DETAIL + '_RESET':
            return {};
        case DETAIL + '_SUCCESS':
            if (action.payload.data.errorMessage) {
                return {
                    ...state,
                    error: action.payload.data.errorMessage
                };
            }
            return action.payload.data;
        default:
            return state;
    }
}

const exportOrder = (state = [], action) => {
    switch (action.type) {
        case EXPORT + '_SUCCESS':
            if (action.payload.data.errorMessage) {
                return state;
            }
            return action.payload.data;
        default:
            return state;
    }
}

const initInbound = {
    loading: false,
    error: null,
    data: {
        offset: 0,
        rows: [],
    },
}
const inbound = (state = initInbound, {type, payload, error, }) => {
    const requestOffset = get(payload, 'request.params.offset', 0);
    const payloadOffset = get(payload, 'data.offset', 0);
    switch (type) {
        case GET_INBOUND:
            return {
                ...state,
                loading: true,
                error: null,
                data: {
                    ...state.data,
                    rows: requestOffset === 0 ? [] : get(state, 'data.rows', []),
                },
            }
        case `${GET_INBOUND}_SUCCESS`:
        case `${GET_INBOUND}_FAIL`:
            return {
                ...state,
                // eslint-disable-next-line no-mixed-operators
                error: error || get(payload, 'data.errorMessage') && get(payload, 'data') || null,
                data: {
                    ...get(payload, 'data'),
                    // push data if page > 1
                    rows: payloadOffset > 0 ? [...get(state, 'data.rows', []), ...get(payload, 'data.rows', [])] : get(payload, 'data.rows', get(state, 'data.rows', [])),
                },
                loading: false,
            }
        default:
            return state;
    }
}

export default combineReducers({show, list, count, offset, detail, exportOrder, inbound, orderDetail});
