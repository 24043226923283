import { combineReducers } from 'redux'
import {
  USER_LOGIN,
  USER_SET_LOGIN_LOADING,
  USER_LOGOUT,
  USER_REQUEST_DATA,
  USER_TIMEOUT,
  USER_CLEAR_TIMEOUT,
  USER_PROFILE,
  USERS,
  ROLES,
  USER_REQUEST_GROUP,
  USER_GET,
  USER_IMPERSONATE,
} from "../actions/user";
import ls from 'local-storage';
import $ from 'jquery';
import _ from 'lodash';

const logout = () => {
  ls.remove('USER_PAYLOAD');
  ls.remove('USER_TOKEN');
  ls.remove('USER_ROUTE');
  $('body').addClass('splash-bg');
}

const initialUser = ls.get('USER_PAYLOAD')?ls.get('USER_PAYLOAD'):null;

const data = (state=initialUser?initialUser:null, action) => {
  switch (action.type) {
    case USER_LOGIN+'_SUCCESS':
      var {data, status} = action.payload;
      if (status===200 && !data.errorMessage) {
        // jika ada data user
        ls.set('USER_PAYLOAD', data.user)
        state = data.user;
      }
      return state;
    case USER_LOGOUT+'_SUCCESS':
      logout();
      return null;
    case USER_LOGOUT+'_FAIL':
      logout();
      return null;
    case USER_REQUEST_DATA+'_FAIL':
      logout();
      return null;
    case USER_REQUEST_DATA+'_SUCCESS':
      if (!action.payload.data.role) {
        action.payload.data.role = {
          permissions: []
        }
      }
      ls.set('USER_PAYLOAD', action.payload.data);
      return action.payload.data;
    default:
      return state;
  }
}

const initialToken = ls.get('USER_TOKEN')?ls.get('USER_TOKEN'):null;
const token = (state=initialToken?initialToken:null, action) => {
  switch (action.type) {
    case USER_IMPERSONATE+'_SUCCESS':
    case USER_LOGIN+'_SUCCESS':
      var {data, status} = action.payload
      if (status===200 && !data.errorMessage) {
        ls.set('USER_TOKEN', data.token)
        state = data.token
      }
      return state
    case USER_LOGOUT+'_SUCCESS':
      logout();
      return null;
    case USER_LOGOUT+'_FAIL':
      logout();
      return null;
    case USER_REQUEST_DATA+'_FAIL':
      logout();
      return null;
    case USER_TIMEOUT:
      logout();
      return null;
    default:
      return state;
  }
}

const loadingLogin = (state=false, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return true;
    case USER_LOGIN+'_SUCCESS':
      return false;
    case USER_LOGIN+'_FAIL':
      return false;
    case USER_SET_LOGIN_LOADING:
      return action.loading;
    default:
      return state;
  }
}

const isTimeout = (state=false, action) => {
  switch (action.type) {
    case USER_TIMEOUT:
      return true;
    case USER_CLEAR_TIMEOUT:
      return false
    default:
      return state;
  }
}

const profile = (state = { data: null, isLoading: false }, action) => {
  switch (action.type) {
    case USER_PROFILE: 
      return {
        ...state,
        isLoading: true
      }
    case USER_PROFILE+'_SUCCESS':
      var {data, status} = action.payload;
      if (status===200 && !data.errorMessage) {
        return {
          ...state,
          isLoading: false,
          data
        }
      }
      return {
        ...state,
        isLoading: false
      }
      
    default:
      return state;
  }
}

const initUsers = {
  loading: false,
  error: null,
  data: {
    offset: 0,
    rows: [],
  },
}
const users = (state = initUsers, {type, payload, error}) => {
  const requestOffset = _.get(payload, 'request.params.offset', 0);
  const payloadOffset = _.get(payload, 'data.offset', 0);
  switch (type) {
    case USERS: 
      return {
        ...state,
        loading: true,
        error: null,
        data: {
          ...state.data,
          rows: requestOffset === 0 ? [] : _.get(state, 'data.rows', []),
        },
      }
    case `${USERS}_SUCCESS`:
    case `${USERS}_FAIL`:
        return {
          ...state,
          // eslint-disable-next-line no-mixed-operators
          error: error || _.get(payload, 'data.errorMessage') && _.get(payload, 'data') || null,
          data: {
            ..._.get(payload, 'data'),
            // push data if page > 1
            rows: payloadOffset > 0 ? [..._.get(state, 'data.rows', []), ..._.get(payload, 'data.rows', [])] : _.get(payload, 'data.rows', _.get(state, 'data.rows', [])),
          },
          loading: false,
        }
      
    default:
      return state;
  }
}

const roles = (state = { data: null, isLoading: false }, action) => {
  switch (action.type) {
    case ROLES: 
      return {
        ...state,
        isLoading: true
      }
    case ROLES+'_SUCCESS':
      var {data, status} = action.payload;
      if (status===200 && !data.errorMessage) {
        return {
          ...state,
          isLoading: false,
          data
        }
      }
      return {
        ...state,
        isLoading: false
      }
      
    default:
      return state;
  }
}

const company_groups = (state = [], action) => {
  switch (action.type) {
    case USER_REQUEST_GROUP + "_SUCCESS":
      const { data } = action.payload;
      if (data.rows) {
        return data.rows;
      }
      return state;
    default:
      return state;
  }
};

const user = (state = { data: null, isLoading: false }, action) => {
  switch (action.type) {
    case USER_GET: 
      return {
        ...state,
        isLoading: true
      }
    case USER_GET+'_SUCCESS':
      var {data, status} = action.payload;
      if (status===200 && !data.errorMessage) {
        return {
          ...state,
          isLoading: false,
          data
        }
      }
      return {
        ...state,
        isLoading: false
      }
      
    default:
      return state;
  }
}

export default combineReducers({
  data,
  token,
  loadingLogin,
  isTimeout,
  profile,
  users,
  roles,
  company_groups,
  user
});
