import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import QrReader from 'react-qr-reader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Field, Form, Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { storeDocument, STORE_DOCUMENT_TRACK } from '../../actions/field';
import { detail as getOrder } from '../../actions/order';
import { validateOrderId } from './validate';
import Skeleton from 'react-loading-skeleton';

const scanQRSchema = Yup.object().shape({
  pod_track_id: Yup.mixed().required('Harap pilih status'),
  remark_id: Yup.mixed().required('Harap pilih remark'),
  notes: Yup.string(),
});

const FieldSelectPodReason = props => {
  const { field, options, form, disabled } = props;
  const error = form.errors[field.name]
  return (
    <div>
      <Select
        name={field.name}
        getOptionLabel={option => option && option.description}
        getOptionValue= {option => option}
        value={field.value}
        isDisabled={disabled}
        onChange={(option) => {
          form.setFieldValue(field.name, option)
          if (field.name === 'remark_id') {
            if (option.id === 65) {
              form.setFieldValue('notes', "")
            } else {
              form.setFieldValue('notes', option.description)
            }
          }
        }}
        onBlur={field.onBlur}
        options={options}
      />
      {form.touched && error && <small className="text-danger">{error}</small>}
    </div>
  )
}

const FieldOther = ({ field, form, disabled }) => {
  const error = form.errors[field.name]
  return (
    <div>
      <textarea name={field.name} value={field.value} onChange={e => form.setFieldValue(field.name, e.target.value)} onBlur={field.onBlur} disabled={disabled} className="form-control"></textarea>
      {form.touched && error && <span className="text-danger">{error}</span>}
    </div>
  )
}

class ScanQRScreen extends Component {
  constructor(props) {
    super(props);

    this.formScan = React.createRef();

    this.state = {
      scanned_id: null,
      orderDetail: {},
      loading: false
    };
  }

  onSubmit = async (data) => {
    const { id } = this.state.orderDetail;
    if (!id || this.state.loading) {
      return;
    }

    this.setState({ loading: true })

    let { notes, pod_track_id, remark_id } = data;
    if (remark_id && parseInt(remark_id.id) !== 65) {
      notes = remark_id.description;
    }
    data = {
      pod_track_id: pod_track_id.id,
      remark_id: remark_id.id,
      notes,
      order_id: id,
      document_type: 'pod',
      action: 'receive',
      action_at: moment().format(),
      role: this.props.role,
    }
    this.props.storeDocument(data).then(res => {
      this.setState({ loading: false, scanned_id: null })
      if (res.type === STORE_DOCUMENT_TRACK + '_SUCCESS') {
        if (res.payload.data.errorMessage) {
          return Swal.fire(
            'Gagal menyimpan!',
            res.payload.data.errorMessage,
            'error'
          );
        } else {
          return Swal.fire(
            'Berhasil!',
            'Perubahan berhasil disimpan!',
            'success'
          );
        }
      }
    })
  }

  handleScan = async data => {
    if (data) {
      const { getOrder, orderDetail } = this.props;
      try {
        data = JSON.parse(data);
      } catch (e) {
        console.log('ERROR SCANNING:', e)
      }

      const validate = await this.formScan.current.validateForm()

      if (Object.keys(validate).length > 0) {
        await Swal.fire(
          'Gagal!',
          'Silahkan Pilih POD Status dan Remark terlebih dahulu.',
          'error'
        );
        return;
      }

      if (!validateOrderId(data)) {
        await Swal.fire(
          'Gagal!',
          'QR Code Tidak Valid',
          'error'
        );
        return;
      }

      
      if (data !== this.state.scanned_id) {
        const res = await getOrder(data);
      
        if (res.payload.data.errorMessage) {
          Swal.fire(
            'Gagal!',
            orderDetail.error.split('\n')[0],
            'error'
          );
          return;
        }
        if (Object.keys(validate).length === 0) {
          this.setState({ scanned_id: data, orderDetail: res.payload.data })
          this.formScan.current.submitForm();
        }
      }
    }
  }

  handleError = err => {
    console.error(err)
  }

  render() {
    return (
      <Container>
        <div className="qr-reader-box" style={{ width: '100%' }}>
          {this.state.loading ? <Skeleton height={420} style={{ marginTop: 32, marginBottom: 32 }} />: <QrReader
            delay={1000}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%', marginTop: 32, marginBottom: 32 }}
          />}
        </div>
        <Formik
          innerRef={this.formScan}
          initialValues={{
            pod_track_id: null,
            remark_id: null,
            notes: '',
          }}
          validationSchema={scanQRSchema}
          onSubmit={this.onSubmit}
        >
          {({ errors, touched, values }) => (
            <Form>
              <div className="form-group">
                <label className="control-label">POD Status</label>
                <Field name="pod_track_id" component={FieldSelectPodReason} options={this.props.pod_statuses} disabled={this.props.loading} />
                {errors.pod_track_id && touched.pod_track_id ? (
                  <small class="text-danger">{errors.pod_track_id}</small>
                ) : null}
              </div>
              <div className="form-group">
                <label className="control-label">Remark</label>
                <Field name="remark_id" component={FieldSelectPodReason} options={this.props.remarks} disabled={this.props.loading} />
                {errors.remark_id && touched.remark_id ? (
                  <small class="text-danger">{errors.remark_id}</small>
                ) : null}
              </div>
              {values.remark_id && values.remark_id.id === 65 && <div className="form-group">
                  <label className="control-label">Other</label>
                <Field name="notes" component={FieldOther} disabled={this.props.loading} />
              </div>}
            </Form>
          )}
        </Formik>
      </Container>
    )
  }
}

const mapstp = (state) => {
  const { reason } = state;
  const { data } = state.user;
  let role = {};

  if (data.role) {
    const { id, name, company_type, created_at, updated_at, description, description_en } = data.role
    role = { id, name, company_type, created_at, updated_at, description, description_en };
  }

  const pod_status_code = '01';
  const remarks_code = '02';

  let rolekey;

  switch(role.name.toLowerCase()) {
    case 'field ops':
      rolekey = 'field_ops';
      break;
    case 'admin pod':
      rolekey = 'admin_pod';
      break;
    case 'pod':
      rolekey = 'admin_pod';
      break;
    case 'finance':
      rolekey = 'finance';
      break;
    case 'head finance':
      rolekey = 'finance';
      break;
    case 'master':
      rolekey = 'master';
      break;
    default:
      rolekey = null;
  }

  const pod_statuses = reason.reasons.filter(r => {
    const hasPermission = (rolekey && r.objCode[rolekey] === '1') || rolekey === 'master';
    return hasPermission && r.objCode.code === 'ritase' && r.objCode.type === pod_status_code && reason.allowed_pod_tracks_id.includes(r.id);
  });
  const remarks = reason.reasons.filter(r => {
    const hasPermission = (rolekey && r.objCode[rolekey] === '1') || rolekey === 'master';
    return hasPermission && r.objCode.code === 'ritase' && r.objCode.type === remarks_code;
  });

  

  return {
    pod_statuses,
    remarks,
    role,
    orderDetail: state.order.detail,
    loading: state.field.storeLoading,
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ storeDocument, getOrder }, dispatch)
}

export default connect(mapstp, mapdtp)(ScanQRScreen);