export const SHOW = 'ORDER_SHOW';
export const GET = 'ORDER_GET_LIST';
export const DETAIL = 'ORDER_GET_DETAIL';
export const EXPORT = 'ORDER_EXPORT';
export const CREATE = 'ORDER_CREATE';
export const GET_INBOUND = 'GET_INBOUND';

/**
 * Get orders inbound data
 * @param params
 * @returns {{payload: {request: {method: string, params: *, url: string}}, type: string}}
 */
export const getInbound = (params = {}) => ({
  type: GET_INBOUND,
  payload: {
    request: {
      url: '/orders/inbound',
      params,
      method: 'get',
    }
  }
});

export const show = (id, params= { populate: 'trip.vehicle_type,trip.vehicle'}) => {
  return {
    type: SHOW,
    payload: {
      request: {
        url: '/orders/'+id,
        method: 'get',
        params,
      },
    },
  };
};

const default_populate = "company_transport,company_shipper,type,origin,destination,status,trip,trip.onbid_tender_offers"
export const get = (offset = 0, params = '', populate = default_populate) => {
  return {
    type: GET,
    payload: {
      request: {
        url: `/orders/shipper?populate=${populate}&limit=10&offset=${offset}${params}`,
        method: 'get',
      },
    },
  };
};

export const detail = (id, offset = 0, params = '') => {
  return {
    type: DETAIL,
    payload: {
      request: {
        url: `/orders/${id}?populate=company_transport,company_shipper,type,origin,destination,status,trip.driver,trip.vehicle,trip.vehicle_type,pickup,load,dropoff,unload,pod,trip.container_tasks,subtrips,next_trip,relatedtrips,trip.origin,relatedtrips.origin,subtrips.origin,trip.destination,relatedtrips.destination,subtrips.destination,trip.tasks,relatedtrips.tasks,subtrips.tasks,destination_area&limit=10&offset=${offset}${params}`,
        method: 'get',
      },
    },
  };
};

export const exportOrder = (params) => {
  return {
    type: EXPORT,
    payload: {
      request: {
        url: `/orders/export?${params}`,
        method: 'get',
      }
    }
  }
}

export const resetOrderDetail = () => {
  return {
    type: DETAIL + '_RESET',
  }
}

export const create = (data) => {
  return {
    type: CREATE,
    payload: {
      request: {
        url: '/orders/shipper',
        method: 'post',
        data
      }
    }
  }
}
