import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { toggleHeader } from '../../actions';
import { createAttendance } from '../../actions/attendance';
import { findTrip, FIND_TRIP } from '../../actions/trip';
import QrReader from 'react-qr-reader';
import Select from 'react-select';
import tickImg from '../../assets/tick.png';
import { validateQR } from './validator';

class HomeScreen extends Component {
  state = {
    isQrError: false,
    showModalDriver: false,
    showModalSuccess: false,
    showModalError: false,
    showQrReader: false,
    status: '',
    trip: null,
    google_api_key: process.env.REACT_APP_GOOGLE_API_KEY,
    position: {
      latitude: 0,
      longitude: 0
    },
    address: null
  }

  getGoogleMaps() {
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise((resolve) => {
        window.resolveGoogleMapsPromise = (google) => {
          resolve(google);

          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement("script");
        const API = process.env.REACT_APP_GOOGLE_API_KEY;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&callback=resolveGoogleMapsPromise`;
        script.async = true;
        if (!window.google) document.body.appendChild(script);
      });
    }

    return this.googleMapsPromise;
  }

  componentWillMount() {
    this.getGoogleMaps();
  }

  componentDidMount() {
    this.getGoogleMaps().then((google) => {
      let geocoder = new window.google.maps.Geocoder();

      navigator.geolocation.getCurrentPosition(
        position => {
          var latlng = {lat: position.coords.latitude, lng: position.coords.longitude};

          geocoder.geocode({ 'location': latlng }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              const address = results[0].formatted_address;
              this.setState({ 
                address,
                position: position.coords
              })
            } else {
              console.log('Geocode was not successful for the following reason: ' + status);
            }
          });
        },
        err => console.log(err)
      );
    });
  }

  toggleScan = (isShow) => {
    this.setState({
      showQrReader: isShow
    })
  }

  toggleModal = (type, isShow) => {
    this.setState({
      [`showModal${type}`]: isShow
    })
  }
 
  handleScan = data => {
    if (data) {
      try {
        data = JSON.parse(data);
      } catch (e) {

      }

      if (validateQR(data, process.env.REACT_APP_KEY)) {
        this.setState({
          trip: null
        })

        this.props.findTrip(data.data.value, {populate: 'vehicle,driver,vehicle_type'}).then(res => {
          if (res.type === `${FIND_TRIP}_FAIL` && res.error && res.error.response) {
            // switch (res.error.response.status) {
            //   case 401:
            //     window.history.push('/login');
            //     break;
            //   default:
            //     break;
            // }
          } else if (res.payload.data) {
            if (res.payload.data.errorMessage) {
              this.setState({
                isQrError: res.payload.data.errorMessage.split(/\r?\n/)[0]
              })
            } else {
              this.toggleModal('Driver', true)
              this.setState({
                trip: res.payload.data
              })
            }
          }
        });
      }
    }
  }

  submitAttendance = () => {
    const { status, trip, position } = this.state;
    // console.log(position.latitude)
    let data = {
      type_id : status.value,
      company_transport_id: trip.company_transport_id,
      vehicle_id: trip.vehicle.id,
    };

    if (position.latitude) data.latitude = position.latitude
    if (position.longitude) data.longitude = position.longitude

    this.props.createAttendance(data).then(res => {
      this.toggleScan(false)
      this.toggleModal('Driver', false)
      this.toggleModal('Success', true)
    });
  }

  handleError = err => {
    console.error(err)
  }

  render() {
    const { isQrError, showQrReader, showModalDriver, showModalSuccess, status, trip, address } = this.state;
    const { statuses, toggleHeader } = this.props;

    const shipperStyles = {
      menu: styles => ({
        ...styles, height: 150,
      }),
      menuList: styles => ({
        ...styles, height: 150,
      }),
    };

    toggleHeader('Attendance');

    return (
      <div className="ritase-home">
        <Container>
          {!showQrReader ? (
            <Row className="justify-content-center">
              <Col md={12}>
                <Select placeholder="Choose Attendance Type" value={status} onChange={(status) => this.setState({status})} options={statuses} isClearable styles={shipperStyles} />
                <Button className="shadow-lg mt-4" block disabled={!status} size="lg" onClick={() => this.toggleScan(true)}>Scan QR Code</Button>
              </Col>
            </Row>
          ) : (
            <Row>
              <h5 className="instruction text-center w-100 m-0"><b>{status.label}</b></h5>
              <div className="qr-reader-box" style={{ width: '100%' }}>
                <QrReader
                  delay={500}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: '100%', marginTop: 32, marginBottom: 32 }}
                />
                {/* {!isQrError && <p>Arahkan QR code driver ke kotak diatas</p>} */}
                {isQrError && (
                  <p className="w-100 text-center">{isQrError}</p>
                )}
              </div>
              <Col className="fixed bottom" style={{
                position: 'fixed',
                bottom: 0,
                padding: 15
              }}>
                <Button className="shadow-lg btn-danger" block size="lg" onClick={() => this.toggleScan(false)}>Cancel</Button>
              </Col>
            </Row>
          )}
          <Modal animation={true} className="p-0" dialogClassName="modal-bottom" show={showModalDriver} onHide={() => this.toggleModal('Driver', false)}>
            <Modal.Header closeButton>
              <Modal.Title>Unit Information</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'scroll', overflowX: 'hidden'}}>
              <div className="form-group">
                <label className="control-label m-0">Vehicle Type :</label>
                <p><b>{trip && trip.vehicle_type && trip.vehicle_type.new_code}</b></p>
              </div>
              <div className="form-group">
                <label className="control-label m-0">Vehicle Number :</label>
                <p><b>{trip && trip.vehicle && trip.vehicle.license_plate}</b></p>
              </div>
              <div className="form-group">
                <label className="control-label m-0">Location :</label>
                <p><b>{ address }</b></p>
              </div>
              <div className="form-group m-0">
                <label className="control-label m-0">Date and Time :</label>
                <p className="m-0"><b>{moment().format('DD/MM/YYYY HH:mm')}</b></p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Col className="p-0">
                <button className="btn btn-outline-danger btn-block" onClick={() => this.toggleModal('Driver', false)}>Cancel</button>
              </Col>
              <Col className="p-0">
                <button className="btn btn-primary btn-block" onClick={() => this.submitAttendance()}>Submit</button>
              </Col>
            </Modal.Footer>
          </Modal>
          <Modal animation={true} className="p-0" dialogClassName="modal-bottom" show={showModalSuccess} onHide={() => this.toggleModal('Success', false)}>
            <Modal.Body style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'scroll', overflowX: 'hidden'}}>
              <div className="form-group text-center mt-3">
                <div className="text-center w-100">
                  <img src={tickImg} alt="" style={{
                    padding: '23px 20px',
                    height: 72,
                    width: 72,
                    border: '1px solid #fe6600',
                    borderRadius: '50%'
                  }}/>
                </div>
                <h4 className="mt-4">Driver Attendance Submitted!</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Col className="p-0">
                <button className="btn btn-outline-default btn-block" onClick={() => this.toggleModal('Success', false)}>Close</button>
              </Col>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

// Map state to props
const mapstp = () => {
  const statuses = [
    {
      label: 'OFF',
      value: '01',
    },
    {
      label: 'READY POOL BASE',
      value: '03',
    },
    {
      label: 'READY POOL BACKHAUL',
      value: '04',
    },
    {
      label: 'VEHICLE BOOKED',
      value: '05',
    },
    {
      label: 'STORING',
      value: '07',
    },
  ];

  return {
    statuses,
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ toggleHeader, createAttendance, findTrip }, dispatch)
}

export default connect(mapstp, mapdtp)(HomeScreen)
