export const SHOW = 'ISSUE_SHOW';
export const GET = 'ISSUE_GET_LIST';
export const DETAIL = 'ISSUE_GET_DETAIL';
export const ADD_COMMENT = 'ISSUE_ADD_COMMENT';
export const CLOSE = 'ISSUE_CLOSE';
export const SUMMARY = 'ISSUE_SUMMARY';
export const CREATE = 'ISSUE_CREATE';

export const show = (id) => {
  return {
    type: SHOW,
    payload: {
      request: {
        url: `/collab_tickets/${id}?populate=conversation.messages.sender,reason,logs.user,contact`,
        method: 'get',
      },
    },
  };
};

export const get = (offset = 0, params = '') => {
  return {
    type: GET,
    payload: {
      request: {
        url: `/collab_tickets?populate=conversation.messages.sender,reason,logs.user,contact&limit=10&offset=${offset}${params}`,
        method: 'get',
      },
    },
  };
};

export const detail = (id, offset = 0, params = '') => {
  return {
    type: DETAIL,
    payload: {
      request: {
        url: `/collab_tickets/${id}?populate=conversation.messages.sender,reason,logs.user,contact&limit=10&offset=${offset}${params}`,
        method: 'get',
      },
    },
  };
};

export const addComment = (id, data) => {
  return {
    type: ADD_COMMENT,
    payload: {
      request: {
        url: `/collab_tickets/${id}/comments`,
        method: 'post',
        data
      }
    }
  }
}

export const close = (id, data) => {
  return {
    type: CLOSE,
    payload: {
      request: {
        url: `/collab_tickets/${id}/close`,
        method: 'post',
        data
      }
    }
  }
}

export const getIssueSummary = (params = '') => {
  return {
    type: SUMMARY,
    payload: {
      request: {
        url: `/collab_tickets/summary?${params}`,
        method: 'get',
      },
    },
  };
}

export const resetIssueDetail = () => {
  return {
    type: DETAIL + '_RESET',
  }
}

export const create = (data) => {
  return {
    type: CREATE,
    payload: {
      request: {
        url: '/collab_tickets',
        method: 'post',
        data
      }
    }
  }
}