import React, { Component } from 'react';
import {connect} from 'react-redux'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/ritase-logo-white.png';
import { toggleSidebar } from '../actions';
import { bindActionCreators } from 'redux';

class Header extends Component {
  toggleDrawer = () => {
    this.props.toggleSidebar(true);
  }

  render() {
    if (this.props.showHeader) {
      return (
        <div>
          <AppBar position="fixed" className="ritase-appbar">
            <Toolbar style={{backgroundColor: '#3caf9c'}}>
              <IconButton edge="start" onClick={() => this.toggleDrawer()} className="ritase-menu-button" style={{color: 'white'}} aria-label="Menu">
                <MenuIcon />
              </IconButton>
              {this.props.showHeader === true && this.props.titleScreen ? (
                <div className="w-100" style={{color: 'white'}}>
                  <h5 className="mb-0">{this.props.titleScreen}</h5>
                </div>
              ) : this.props.showHeader === true ? (
                <div className="text-center w-100">
                  <img src={logo} height="30" alt=""/>
                </div>
              ) : (
                <div className="w-100" style={{color: 'white'}}>
                  <h5 className="m-0">{this.props.showHeader}</h5>
                </div>
              )}
            </Toolbar>
          </AppBar>
        </div>
      );
    }
    return null;
  }
}

// Map state to props
const mapstp = (state) => {
  return {
    showHeader: state.showHeader,
    titleScreen: state.titleScreen
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({toggleSidebar}, dispatch)
}

export default connect(mapstp, mapdtp)(Header)
