export const STORE_CONSIGNEE_REPORT = 'STORE_CONSIGNEE_REPORT';
export const RESET_CONSIGNEE_REPORT = 'RESET_CONSIGNEE_REPORT';

export const storeConsigneeReport = (data) => ({
    type: STORE_CONSIGNEE_REPORT,
    payload: {
        request: {
            url: '/consignees/reports',
            data,
            method: 'post',
        }
    }
});

export const resetConsigneeReport = () => ({
    type: RESET_CONSIGNEE_REPORT,
});
