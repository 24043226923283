import { combineReducers } from 'redux';
import {GET, GET_ALLOWED_POD_TRACK, GET_PARAMS, GET_REASONS_CATEGORIES} from '../actions/reason';
import get from 'lodash/get';

const reasons = (state = [], action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      const { data } = action.payload
      if (data.rows) {
        return data.rows.map(reason => {
          let code = reason.code.split(':');
          reason.objCode = { 'code': code[0], 'type': code[1], 'sequence': code[2], 'field_ops': code[3], 'admin_pod': code[4], 'finance': code[5] };
          return reason
        });
      }
      return state;
    default:
      return state;
  }
}

const reasonsParams = (state = [], action) => {
  switch (action.type) {
    case GET_PARAMS + '_SUCCESS':
      const { data } = action.payload
      if (data.rows) {
        return data.rows;
      }
      return state;
    default:
      return state;
  }
}

const initCategory = {
  list: {
    loading: false,
    data: {
      rows: [],
    },
    error: null,
  },
}

const category = (state = initCategory, {type, payload, error}) => {
  switch (type) {
    case GET_REASONS_CATEGORIES:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
        },
      }
    case `${GET_REASONS_CATEGORIES}_SUCCESS`:
    case `${GET_REASONS_CATEGORIES}_FAIL`:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          // eslint-disable-next-line no-mixed-operators
          error: error || get(payload, 'data.errorMessage') && get(payload, 'data') || null,
          // eslint-disable-next-line no-mixed-operators
          data: !get(payload, 'data.errorMessage') && get(payload, 'data') || get(state, 'list.data'),
        }
      }
    default:
      return state;
  }
}

const allowed_pod_tracks_id = (state = [], action) => {
  switch (action.type) {
    case GET_ALLOWED_POD_TRACK + '_SUCCESS':
      return [...action.payload.data]
  
    default:
      return state;
  }
}

export default combineReducers({ reasons, reasonsParams, category, allowed_pod_tracks_id });
