import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { logout } from '../../actions/user';
import { bindActionCreators } from 'redux';
import $ from 'jquery';

class LostScreen extends Component {
  logout = () => {
    this.props.logout().then(res => {
      $('body').addClass('splash-bg');
    });
  }

  render() {
    return (
      <Container className="h-100 d-flex flex-column justify-content-center align-items-center text-center">
        <h1 className="text-primary">Oops!</h1>
        <h3 className="text-primary">403 Forbidden</h3>
        <p>You don't have permission to access this menu.</p>
        <Button onClick={() => this.logout()}><i className="fa fa-home"></i> Logout</Button>
      </Container>
    );
  }
}

const mapstp = (state) => {
  return {}
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ logout }, dispatch);
}

export default connect(mapstp, mapdtp)(LostScreen);
