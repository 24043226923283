import axiosMiddleware from 'redux-axios-middleware'
import axios from 'axios'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'
import { handleTimeout } from './actions/user'
import get from 'lodash/get';

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json'
});

const middlewareConfig = {
  interceptors: {
    request: [
      ({getState, dispatch, getSourceAction}, req) => {
        if (getState().user.token) {
          req.headers['Authorization'] = getState().user.token
        }
        return req;
      }
    ]
  },
  onError: ({action, error, next, dispatch}) => {
    if (get(error, 'response.status') === 401) {
      dispatch(handleTimeout());
    }

    let nextAction = {
      error,
      meta: {
        previousAction: action,
      },
    };
    if (action.types && action.types.length === 3) {
      nextAction.type = action.types[2];
    } else {
      nextAction.type = action.type + '_FAIL';
    }
    next(nextAction);
    return nextAction;
  }
};

const store = createStore(reducers, applyMiddleware(thunk, axiosMiddleware(client, middlewareConfig)));

export default store
