import { combineReducers } from 'redux';
import {
  GET,
  CLEAR_ITEMS,
  SET_DETAIL,
  SHOW,
  UPDATE_REPORT,
  SET_DETAIL_REPORT,
  SET_FILTER,
} from '../actions/trip';
import moment from 'moment';

const formatDate = (date, type='startOf') => {
  return moment.utc(moment(date)[type]('day')).format();
}

const items = (state = [], action) => {
  switch (action.type) {
    case 'trip_approval' + CLEAR_ITEMS:
      return [];
    case 'trip_approval' + GET + '_SUCCESS':
      const { errorMessage, rows, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      if (offset > 0) {
        return state.concat(rows)
      }
      return rows;
    default:
      return state;
  }
}

const count = (state = 0, action) => {
  switch (action.type) {
    case 'trip_approval' + CLEAR_ITEMS:
      return 0;
    case 'trip_approval' + GET + '_SUCCESS':
      const { errorMessage, count } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return count;
    default:
      return state;
  }
}

const offset = (state = 0, action) => {
  switch (action.type) {
    case 'trip_approval' + CLEAR_ITEMS:
      return 0;
    case 'trip_approval' + GET + '_SUCCESS':
      const { errorMessage, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return offset;
    default:
      return state;
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case 'trip_approval' + GET:
      return true;
    case 'trip_approval' + GET + '_SUCCESS':
      return false;
    case 'trip_approval' + GET + '_FAIL':
      return false;
    default:
      return state;
  }
}

const fail = (state = false, action) => {
  switch (action.type) {
    case 'trip_approval' + GET:
      return false;
    case 'trip_approval' + GET + '_FAIL':
      return true;
    default:
      return state;
  }
}

const detail = (state = {}, action) => {
  switch (action.type) {
    case SET_DETAIL:
      return action.item;
    default:
      return state;
  }
}

const loadingReport = (state = false, action) => {
  switch (action.type) {
    case SHOW:
      return true;
    case SHOW + '_SUCCESS':
      return false;
    case SHOW + '_FAIL':
      return false;
    default:
      return state;
  }
}

const reports = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case UPDATE_REPORT:
      const items = state;
      const key = items.map(x => x.id).indexOf(action.item.id);
      const selected = items[key];
      if (selected) {
        items[key].note = action.item.note;
        items[key].is_appropriate = action.item.is_appropriate;
        items[key].remarks = action.item.remarks;
        items[key].latest_position = action.item.latest_position;
        items[key].pickup_at = action.item.pickup_at;
        items[key].start_loading_at = action.item.start_loading_at;
        items[key].end_loading_at = action.item.end_loading_at;
        items[key].loading_date = action.item.loading_date;
        items[key].loading_time = action.item.loading_time;
        items[key].dropoff_date = action.item.dropoff_date;
        items[key].dropoff_time = action.item.dropoff_time;
        items[key].unloading_date = action.item.unloading_date;
        items[key].unloading_time = action.item.unloading_time;
      }
      return items;
    case SHOW:
      return [];
    case SHOW + '_SUCCESS':
      if (payload.data.errorMessage) {
        return state;
      }
      return payload.data.rows;
    case SHOW + '_WITH_PREVIOUS_SUCCESS':
      if (payload.data.errorMessage) {
        return state;
      }
      const data = state.map((report, key) => {
        const find = payload.data.rows.filter(x => x.id === report.id);
        const res = find[0]?find[0]:{};
        const item = {
          ...report,
          ...res,
        }
        return item;
      });
      return data;
    default:
      return state;
  }
}

const report = (state = {}, action) => {
  switch (action.type) {
    case SHOW:
      return {};
    case SET_DETAIL_REPORT:
      return action.report;
    default:
      return state;
  }
}

const filter = (state = '', action) => {
  switch (action.type) {
    case SET_FILTER:
      const filter = action.filter;
      const paramStatus = filter.status_id?'&status_id='+filter.status_id.value:'';
      const paramReadyTime = filter.dateFrom && filter.dateTo?'&ready_time='+formatDate(filter.dateFrom)+','+formatDate(filter.dateTo, 'endOf'):'';

      return paramStatus+paramReadyTime;
    default:
      return state;
  }
}

export default combineReducers({
  items,
  loading,
  count,
  offset,
  fail,
  detail,
  reports,
  loadingReport,
  report,
  filter,
});
