import { IonContent } from '@ionic/react';
import { AppBar, Card, CardContent, CircularProgress, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleHeader } from '../../actions';
import Can from '../../components/Can';
import { permissions } from '../../routes';
import "./style.scss";

class ViewCoverageAllPriceScreen extends Component {
  componentWillUnmount() {
    this.props.toggleHeader(true);
  }

  goBack = () => {
    this.props.history.goBack();
  }

  NoResultRender = () => (
    <Can redirect permissions={permissions(this.props.location.pathname)}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" onClick={() => this.goBack()} style={{color: 'white'}} aria-label="Menu">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" style={{color: 'white'}}>
            View Coverage
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Row>
          <Col>
            <center>
            {this.props.loading ? <CircularProgress /> : <h2 className="text-danger">
              Terjadi kesalahan saat menampilkan halaman.
            </h2>}
            </center>
          </Col>
        </Row>
      </Container>
    </Can>
  )

  render() {

    const { detail, originCoverage, destinationCoverage, loading } = this.props;

    if (!detail || !originCoverage || !destinationCoverage || loading) {
      return this.NoResultRender()
    }  
    
    return (
      <Can redirect permissions={permissions(this.props.location.pathname)}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => this.goBack()}
              style={{ color: "white" }}
              aria-label="Menu"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" style={{ color: "white" }}>
              View Coverage
            </Typography>
          </Toolbar>
        </AppBar>
        <IonContent style={{ "--background": "#F0F6F6" }}>
          <Container fluid className='container-detail'>
            <Card className="card-all-price">
              <CardContent>
                <div className="mb-2">
                  <div className="label">Route Code</div>
                  <span>{detail.route_code && detail.route_code.code}</span>
                </div>

                <Grid container spacing={2} className="my-1">
                  <Grid item xs={12}>
                    <ul className="route-area-with-title">
                      <li className="route-area-with-title-item">
                        <div className="label">Asal</div>
                        <strong>{originCoverage.name && originCoverage.name}</strong>
                        <ul className='coverage-list label'>
                          {originCoverage.administrative_divisions.map((item, key) => (
                            <li key={key}>
                              {item.administrative_division.zipcode} - {item.administrative_division.division_type} {item.administrative_division.name}
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li className="route-area-with-title-item">
                        <div className="label">Tujuan</div>
                        <strong>{destinationCoverage.name && destinationCoverage.name}</strong>
                        <ul className='coverage-list label'>
                          {destinationCoverage.administrative_divisions.map((item, key) => (
                            <li key={key}>
                              {item.administrative_division.zipcode} - {item.administrative_division.division_type} {item.administrative_division.name}
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        </IonContent>
      </Can>
    );
  }
}

const mapstp = (state, props) => {
  const {dataPage} = props.location.state || {};
  const {prefix} =  dataPage || "";  

  return {
    detail: state[prefix]?.detail, 
    loading: state[prefix]?.loading, 
    originCoverage: state[prefix]?.originCoverage, 
    destinationCoverage: state[prefix]?.destinationCoverage
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ toggleHeader }, dispatch)
}

export default connect(mapstp, mapdtp)(ViewCoverageAllPriceScreen)
