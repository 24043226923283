import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { permissions } from '../routes';

import Can from './Can';

export default function SidebarSubList({route, pathname}) {
  const [show, setShow]=useState(false)
  return(
    <li className='nav-item'>
      <div className="nav-link d-flex flex-row align-items-center rounded" 
        style={{
          cursor: 'pointer',
          background: pathname.includes(`${route.path}/`) && 'white',
          color: pathname.includes(`${route.path}/`) && '#3caf9c'
        }} 
        onClick={()=>{setShow(!show)}}>
        <img src={route.ico} width="45" height="45" className="rounded-circle float-left mr-3" alt=""/>
        {route.title}
      </div>
      {
        (show || pathname.includes(`${route.path}/`)) &&
        <ul className="nav flex-column">
        {
          route.child.map((subRoute, subKey)=> !subRoute.hide && <Can key={subKey} permissions={permissions(subRoute.path)}>
            <li className="rounded" style={{background: pathname===subRoute.path && "#0E8175"}}>
              <Link className="nav-link d-flex flex-row align-items-center rounded" to={subRoute.path}>
                <div className='d-flex align-items-center justify-content-center' style={{width:45, height:25}}>
                  <img src={subRoute.ico} className="float-left mr-3" alt=""/>
                </div>
                {subRoute.title}
              </Link>
             </li>
          </Can>)
        }
        </ul>
      }
    </li>
  )
}