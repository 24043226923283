
import {hot} from 'react-hot-loader/root'
import React from 'react';
import { PrivateRoutes, PublicRoutes } from './routes';
import { BrowserRouter, Route } from 'react-router-dom';
import Header from './components/Header';
import GetUserData from './components/GetUserData';
import GetShippers from './components/GetShippers';
import GetPodRemarks from './components/GetPodRemarks';
import Sidebar from './components/Sidebar';
import store from './store';
import { Offline } from 'react-detect-offline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LostScreen from './screens/LostScreen/404';
import { Provider } from 'react-redux';

if (process.env.REACT_APP_APP_NAME === 'minternal') {
  import('./App.scss').then(res => res);
}

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3caf9c',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});

class App extends React.Component {
  componentDidMount() {
    const elem = document.getElementById('startingLoader');
    window.onload = () => {
      if (elem) {
        elem.remove();
      }
    };
  }

  render () {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GetUserData />
          <GetShippers />
          <GetPodRemarks />
          <BrowserRouter>
            <Header />
            <Offline polling={{enabled: false}}>
              <div className="offline-notice animated slideInDown">
                Tidak ada koneksi internet
              </div>
            </Offline>
            <Sidebar />
            <PublicRoutes {...this.props} />
            <PrivateRoutes {...this.props} />
            <Route component={LostScreen} />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default hot(App);
