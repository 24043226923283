export const GET = 'AREAS_GET';
export const DETAIL = 'AREAS_DETAIL';
export const CLEAR_ITEMS = 'AREAS_CLEAR_ITEMS';
export const CLEAR_ITEM = 'AREAS_CLEAR_ITEM';
export const SET_FILTER = 'AREAS_SET_FILTER';
export const REMOVE = 'AREAS_REMOVE';
export const CLUSTER = 'AREAS_CLUSTER';
export const GET_STATES = 'AREAS_GET_STATES';
export const CLEAR_STATES = 'AREAS_CLEAR_STATES';
export const GET_CITIES = 'AREAS_GET_CITIES';
export const CLEAR_CITIES = 'AREAS_CLEAR_CITIES';
export const FILTER_CITIES = 'AREAS_FILTER_CITIES';
export const GET_DISTRICTS = 'AREAS_GET_DISTRICTS';
export const CLEAR_DISTRICTS = 'AREAS_CLEAR_DISTRICTS';
export const FILTER_DISTRICTS = 'AREAS_FILTER_DISTRICTS';
export const ADD = 'AREAS_ADD';
export const UPDATE = 'AREAS_UPDATE';
export const UPDATE_ADMINISTRATIVE_DIVISION = 'AREAS_UPDATE_ADMINISTRATIVE_DIVISION';
export const ADD_CLUSTER = 'AREAS_ADD_CLUSTER';

export const get = (offset = 0, search = '') => {
  return {
    type: GET,
    payload: {
      request: {
        url: '/areas?limit=10&offset='+offset+'&populate=administrative_divisions.administrative_division.parent,cluster_areas,cluster_areas.cluster&searchFields=name&search='+search,
      }
    }
  }
}

export const detail = (id) => {
  return {
    type: DETAIL,
    payload: {
      request: {
        url: '/areas/'+id+'?populate=administrative_divisions.administrative_division.parent,cluster_areas,cluster_areas.cluster'
      }
    }
  }
}

export const clearItems = () => {
  return {
    type: CLEAR_ITEMS
  }
}

export const clearItem = () => {
  return {
    type: CLEAR_ITEM
  }
}

export const setFilter = (search) => {
  return {
    type: SET_FILTER,
    search
  }
}

export const remove = (id, data = {}) => {
  return {
    type: REMOVE,
    payload: {
      request: {
        url: `/areas/${id}/delete`,
        method: 'post',
        data
      }
    }
  }
}

export const cluster = () => {
  return {
    type: CLUSTER,
    payload: {
      request: {
        url: '/transport_routes/clusters?format=dropdown',
      }
    }
  }
}

export const states = () => {
  return {
    type: GET_STATES,
    payload: {
      request: {
        url: '/administrative_divisions?division_level=0'
      }
    }
  }
}

export const clearStates = () => {
  return {
    type: CLEAR_STATES
  }
}

export const cities = (id) => {
  return {
    type: GET_CITIES,
    payload: {
      request: {
        url: `/administrative_divisions/${id}/subdivisions`
      }
    }
  }
}

export const filterCities = (state_id) => {
  return {
    type: FILTER_CITIES,
    id: state_id
  }
}

export const clearCities = () => {
  return {
    type: CLEAR_CITIES
  }
}

export const districts = (id) => {
  return {
    type: GET_DISTRICTS,
    payload: {
      request: {
        url: `/administrative_divisions/${id}/subdivisions`
      }
    }
  }
}

export const filterDistricts = (city_id) => {
  return {
    type: FILTER_DISTRICTS,
    id: city_id
  }
}

export const clearDistricts = () => {
  return {
    type: CLEAR_DISTRICTS
  }
}

export const add = (data) => {
  return {
    type: ADD,
    payload: {
      request: {
        url: '/areas',
        method: 'post',
        data
      }
    }
  }
}

export const add_cluster = (id, data) => {
  return {
    type: ADD_CLUSTER,
    payload: {
      request: {
        url: `/areas/${id}/cluster`,
        method: 'post',
        data
      }
    }
  }
}

export const update_administrative_division = (id, data) => {
  return {
    type: UPDATE_ADMINISTRATIVE_DIVISION,
    payload: {
      request: {
        url: `/areas/${id}/administrative_divisions`,
        method: 'put',
        data
      }
    }
  }
}

export const update = (id, data) => {
  return {
    type: UPDATE,
    payload: {
      request: {
        url: `/areas/${id}`,
        method: 'put',
        data
      }
    }
  }
}

