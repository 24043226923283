import React from 'react';
import { Form } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import ucfirst from 'ucfirst'
import { toggleHeader } from '../../actions';

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Please fill your email address'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = "Please fill your password"
  }
  return errors
}

const renderField = ({ disabled, input, label, type, meta: { touched, error, warning } }) => (
  <div className="text-left">
    <input {...input} placeholder={label} disabled={disabled} type={type} className="form-control"/>
    {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
  </div>
)

const warn = values => {
  const warnings = {}
  if (!values.email) {
    warnings.email = 'Please fill your email address'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    warnings.email = 'Invalid email address'
  }
  if (!values.password) {
    warnings.password = "Please fill your password"
  }
  return warnings
}

class LoginForm extends React.Component {
  render () {
    const { handleSubmit, errorMessage, toggleHeader } = this.props
    toggleHeader(false);

    return (
      <Form onSubmit={handleSubmit}>
        <div className="form-group">
          <Field type="text" name="email" disabled={this.props.loading} component={renderField} label="Your Email Address" />
        </div>
        <div className="form-group">
          <Field type="password" name="password" disabled={this.props.loading} component={renderField} label="Your Password" className="form-control" />
        </div>
        {errorMessage && <p className="text-danger">{ucfirst(errorMessage)}</p>}
        <button type="submit" disabled={this.props.loading} className="btn btn-danger btn-block text-bold">
          {this.props.loading?<CircularProgress size={20} color='inherit'/>:'LOGIN'}
        </button>
      </Form>
    )
  }
}

const LF = reduxForm({
  form: 'login',
  validate,
  warn
})(LoginForm);

// Map state to props
const mapstp = (state) => {
  const {user} = state;
  return {
    loading: user.loadingLogin
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ toggleHeader }, dispatch)
}

export default connect(mapstp, mapdtp)(LF);
