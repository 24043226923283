export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_HEADER = 'TOGGLE_HEADER'
export const SET_TITLE = 'SET_TITLE'
export const toggleSidebar = (open) => {
  return {
    type: TOGGLE_SIDEBAR,
    open
  }
}
export const toggleHeader = (show) => {
  return {
    type: TOGGLE_HEADER,
    show
  }
}
export const setTitlePage = (value) => {
  return {
    type: SET_TITLE,
    value
  }
}
