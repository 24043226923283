import { combineReducers } from 'redux';
import { GET_NOTIF, GET_TOPIC, UPDATE_TOPIC, MARK_AS_READ_NOTIF, SET_NOTIF } from '../actions/notification';

const getTopics = (state = { data: [], isLoading: false }, action) => {
  switch (action.type) {
    case GET_TOPIC:
      return {
        ...state,
        isLoading: true
      }
    case GET_TOPIC + '_SUCCESS':
      if (action.payload.data.errorMessage) {
        return {
          ...state,
          isLoading: false
        }
      }
      return {
        ...state,
        isLoading: false,
        data: action.payload.data.rows
      }
    default:
      return state;
  }
}

const listNotifications = (state = [], action) => {
  switch (action.type) {
    case SET_NOTIF:
      state = action.payload.data;
      return state;
    case GET_NOTIF + '_SUCCESS':
      const { errorMessage, rows, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      if (offset > 0) {
        return state.concat(rows)
      }
      return rows;
    default:
      return state;
  }
}

const countNotifications = (state = 0, action) => {
  switch (action.type) {
    case GET_NOTIF + '_SUCCESS':
      const { errorMessage, count } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return count;
    default:
      return state;
  }
}

const offsetNotifications = (state = 0, action) => {
  switch (action.type) {
    case GET_NOTIF + '_SUCCESS':
      const { errorMessage, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return offset;
    default:
      return state;
  }
}

const loadNotifications = (state = false, action) => {
  switch (action.type) {
    case GET_NOTIF:
      return true;
    case GET_NOTIF + '_SUCCESS':
      return false;
    default:
      return state;
  }
}

const createOrUpdateTopic = (state = { data: null, isLoading: false, error: null }, action) => {
  switch (action.type) {
    case UPDATE_TOPIC:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_TOPIC + '_SUCCESS':
      if (action.payload.data.errorMessage) {
        return {
          ...state,
          isLoading: false,
          error: action.payload.data.errorMessage
        };
      }

      return {
        ...state,
        isLoading: false,
        data: action.payload.data
      };
    default:
      return state;
  }
}

const markAsReadNotification = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case MARK_AS_READ_NOTIF:
      return {
        ...state,
        isLoading: true
      };
    case MARK_AS_READ_NOTIF + '_SUCCESS':
      if (action.payload.data.errorMessage) {
        return {
          ...state,
          isLoading: false,
          error: action.payload.data.errorMessage
        };
      }

      return {
        ...state,
        isLoading: false,
        data: action.payload.data
      };
    default:
      return state;
  }
}


export default combineReducers({ getTopics, createOrUpdateTopic, listNotifications, countNotifications, offsetNotifications, loadNotifications, markAsReadNotification });
