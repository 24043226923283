import { GET } from '../actions/contact';
import { combineReducers } from 'redux';

const lists = (state = [], action) => {
  switch (action.type) {
    case GET+'_SUCCESS':
      if (action.payload.data) {
        return action.payload.data.rows;
      }
      return [];
    default:
      return state;
  }
}

export default combineReducers({lists});
