import React, { Component } from 'react';
import { Container, Button, Card, Row, Col, FormLabel, Form, FormGroup } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select/async';
import Can from '../../components/Can';
import { permissions } from '../../routes';
import { setTitlePage } from '../../actions';
import { getLists, setSort, setFilter, clear } from '../../actions/capacity_offer';
import { getAll as getTransporter } from '../../actions/transporter';
import { get as getVehicleType } from '../../actions/vehicle_type';
import ContentLoader from 'react-content-loader';
import PullToRefresh from 'pulltorefreshjs';
import FilterIcon from '../../assets/icons/filter.svg'
import SortIcon from '../../assets/icons/sort.svg'

import './style.scss'
import { AppBar, Drawer, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import CardCapacityOffer from './CardCapacityOffer';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import debounce from 'lodash/debounce'
import moment from 'moment';

class ListCapacityOfferScreen extends Component {

  state = {
    showSort: false,
    showFilter: false,
    completed: false,
    loading: true,
  }

  initPtr = () => {
    this._ptr = PullToRefresh.init({
      mainElement: '.container-capacity-offer',
      triggerElement: '.container-capacity-offer',
      onRefresh: () => {
          this.props.clear();
          this.props.getLists().then(() => {
            this.setState({loading: false})
          });
      },
    });
  }

  componentDidMount() {
    this.props.setTitlePage('Looking for Shipment');
    this.props.getLists().then(() => {
      this.setState({loading: false})
    });

  }

  componentWillUnmount() {
    this.props.setTitlePage(null);
    PullToRefresh.destroyAll();
  }

  showDrawer = (type) => {
    this.setState({ [`show${type}`]: true })
  }

  toggleDrawer = (type, open) => (event) => {
    if (event.type && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ [`show${type}`]: open });
  };

  handleSort = ({asc, sort}) => {
    this.props.setSort(sort, asc)
    this.props.getLists(0, this.props.filter, {asc, sort})
  }
  
  handleFilter = (filter) => {
    this.props.setFilter(filter)
    this.props.getLists(0, filter, this.props.sort)
  }

  loadMore = (e) => {
    this.props.getLists(this.props.offset + 10, this.props.filter, this.props.sort);

    e.target.complete();

    if (this.props.count > this.props.list.length) {
      this.setState({completed: false});
    } else {
      this.setState({completed: true});
    };
  };


  render() {
    const { showSort, showFilter, completed, loading } = this.state;
    const { sort, filter, list, loading: loadingFetcher } = this.props;
    
    return (
      <Can redirect permissions={permissions('/capacity-offer')}>
        <IonContent style={{ '--background': '#F0F6F6' }}>
          <FilterSortBlock handleClick={this.showDrawer} />
          <Container fluid className="container-capacity-offer">
            {!loadingFetcher && list.length === 0 && (
              <Card body>
                No data found
              </Card>
            )}

            {loading && (<React.Fragment>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </React.Fragment>)}

            {!loading && (
              <React.Fragment>
                {list.map((item, i) =>
                  (<CardCapacityOffer key={`${i}-${item.id}`} item={item} history={this.props.history} />)
                )}

                <IonInfiniteScroll 
                  threshold="10px"
                  disabled={completed}
                  onIonInfinite={this.loadMore}
                >
                  <IonInfiniteScrollContent loadingSpinner="circular" />
                </IonInfiniteScroll>
              </React.Fragment>
            )}
          </Container>
          <DrawerSort open={showSort} initialValue={sort.sort} handleCancel={this.toggleDrawer('Sort', false)} onSubmit={this.handleSort} />
          <DrawerFilter open={showFilter} initialValue={filter} handleCancel={this.toggleDrawer('Filter', false)} onSubmit={this.handleFilter} />
        </IonContent>
      </Can>
    );
  }
}


const DrawerSort = ({ open, initialValue, handleCancel, onSubmit }) => {
  const [sort, setTempSort] = React.useState(initialValue);
  
  const apply = () => {
    onSubmit({
      asc: 'ASC',
      sort
    })
    handleCancel({});
  }
  
  return (
    <Drawer anchor="bottom" open={open} onClose={handleCancel} classes={{ root: 'MuiDrawer-paper borderless-top' }}>
      <Card body>
        <FormControl component="fieldset">
          <FormLabel component="legend"><strong>Sort By</strong></FormLabel>
          {open && <RadioGroup aria-label="sortBy" name="sortBy" defaultValue={initialValue} onChange={e => setTempSort(e.target.value)}>
            <FormControlLabel value="start_time" control={<Radio color="primary" />} label="Date & Time" />
            <FormControlLabel value="price" control={<Radio color="primary" />} label="Price" />
            <FormControlLabel value="companies.name" control={<Radio color="primary" />} label="Company Name" />
          </RadioGroup>}
        </FormControl>
        <hr/>
        <Row>
          <Col>
            <Button size="lg" block variant="outline-secondary" onClick={handleCancel}>Back</Button>
          </Col>
          <Col>
            <Button size="lg" block onClick={apply}>Apply</Button>
          </Col>
        </Row>
      </Card>
    </Drawer>
  )
}

const DrawerFilter = ({ open, initialValue, handleCancel, onSubmit }) => {
  const [start, setStart] = React.useState(initialValue.start || null)
  const [end, setEnd] = React.useState(initialValue.end || null)
  const [company_transport, setCompany] = React.useState(initialValue.company_transport_id)
  const [vehicle_type, setType] = React.useState(initialValue.vehicle_type_id)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!end) {
      setEnd(start)
    }
    if (start === null) {
      setEnd(null)
    }

  }, [start, end])

  const loadTransporter = debounce((inputValue, callback) => {
      dispatch(getTransporter(inputValue)).then(res => {
        const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d.id, label: d.name })) : []
        callback([
          {label: 'All Transporter', value: ''},
          ...data
        ])
      })
  }, 500);

  const loadVehicleType = debounce((inputValue, callback) => {
    const query = `searchFields=name&limit=10&search=${inputValue}`
    dispatch(getVehicleType(query)).then(res => {
      const data = res.payload.data ?res.payload.data.rows.map(d => ({ value: d.id, label: d.name })) : []
      callback([
        {label: 'All Type', value: ''},
        ...data
      ])
    })
  }, 500);
  

  const apply = () => {
    console.log({
      company_transport,
      vehicle_type,
      start, end
    })
    const filter = {}
    if (company_transport && company_transport.value) {
      filter.company_transport_id = company_transport.value
    }
    if (vehicle_type && vehicle_type.value) {
      filter.vehicle_type_id = vehicle_type.value
    }
    if (start && end) {
      filter.start = moment(start).startOf("day").toISOString();
      filter.end = moment(end).endOf("day").toISOString();
    }
    if (start && !end) {
      return;
    }

    onSubmit(filter)
    handleCancel({});
  }
  
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleCancel}
      classes={{ root: "MuiDrawer-paper borderless-top" }}
    >
      <Card body>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <strong>Filter</strong>
          </FormLabel>
        </FormControl>

        <Form>
          <FormGroup className="mb-3">
            <FormLabel>Transporter</FormLabel>
            <Select
              placeholder="All Transporter"
              cacheOptions
              defaultOptions
              value={company_transport}
              loadOptions={loadTransporter}
              onChange={(val) => setCompany(val)}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Vehicle Type</FormLabel>
            <Select
              placeholder="All Type"
              cacheOptions
              defaultOptions
              value={vehicle_type}
              loadOptions={loadVehicleType}
              onChange={(val) => setType(val)}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Date</FormLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Row>
                <Col>
                  <KeyboardDatePicker
                    style={{ width: "100%" }}
                    clearable
                    value={start}
                    minDate={new Date()}
                    format="MM-dd-yyyy"
                    onChange={(date) => setStart(date)}
                  />
                </Col>
                <Col>
                  <KeyboardDatePicker
                    style={{ width: "100%" }}
                    clearable
                    disabled={!start}
                    value={end}
                    minDate={start}
                    format="MM-dd-yyyy"
                    onChange={(date) => setEnd(date)}
                  />
                </Col>
              </Row>
            </MuiPickersUtilsProvider>
          </FormGroup>
        </Form>

        <hr />
        <Row>
          <Col>
            <Button
              size="lg"
              block
              variant="outline-secondary"
              onClick={handleCancel}
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button size="lg" block onClick={apply}>
              Apply
            </Button>
          </Col>
        </Row>
      </Card>
    </Drawer>
  );
}


const FilterSortBlock = ({ handleClick }) => {
  return (
    <AppBar color="inherit" classes={{ root: 'MuiAppBar-root floating-filter-fort' }}>
    <Row>
      <Col>
      <Card as="button" className="text-center card-filter" body onClick={() => handleClick('Filter')}>
        <img alt="Filter" src={FilterIcon} style={{ marginRight: 5 }} />
        Filter
        </Card>
      </Col>
      <Col>
      <Card as="button" className="text-center card-sort" body onClick={() => handleClick('Sort')}>
        <img alt="Sort" src={SortIcon} style={{ marginRight: 5 }} />
        Sort
        </Card>
      </Col>
    </Row>
    </AppBar>
  )
}

const LoadingCard = () => {
  return (
    <Card className="shadow-sm ritase-card mb-3">
      <Card.Body className="p-3">
        <ContentLoader height={55}>
          <rect x="0" y="0" rx="6" ry="6" width="65%" height="20" />
          <rect x="0" y="30" rx="6" ry="6" width="50%" height="20" />
        </ContentLoader>
        <ContentLoader height={20}>
          <rect x="0" y="0" rx="6" ry="6" width="30%" height="20" />
        </ContentLoader>
      </Card.Body>
    </Card>
    )
}

// Map state to props
const mapstp = (state) => {
  const { capacity_offer } = state;

  return {
    list: capacity_offer.lists,
    count: capacity_offer.count,
    offset: capacity_offer.offset,
    filter: capacity_offer.filter,
    sort: capacity_offer.sort,
    loading: capacity_offer.loading,
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ setTitlePage, getLists, setSort, setFilter, clear }, dispatch)
}

export default connect(mapstp, mapdtp)(ListCapacityOfferScreen)
