import React from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { logout } from '../../actions/user';
import { bindActionCreators } from 'redux';
import { routes as privateRoutes, publicRoutes } from '../../routes';

const LostScreen = ({ location }) => {
  const routes = privateRoutes.concat(publicRoutes);
  if (routes.filter(x => x.path === location.pathname).length > 0) {
    return null;
  }
  return (
    <Container className="h-100 d-flex flex-column justify-content-center align-items-center text-center">
      <h1 className="text-primary">Oops!</h1>
      <h3 className="text-primary">404 Not found</h3>
      <p>I think you are lost. <code>{ location.pathname }</code> is not found in our server</p>
    </Container>
  );
}

const mapstp = (state) => {
  return {}
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ logout }, dispatch);
}

export default connect(mapstp, mapdtp)(LostScreen);
