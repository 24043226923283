import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { detail } from '../../actions/areas';
import './Area.scss';
import { Link } from 'react-router-dom';


class AreaCard extends Component {

  gotoDetail = (item) => {
    this.props.detail(item);
  }

  render() {
    const { id, name, cluster_areas, states, cities } = this.props;
    return (
      <Link to={'/all-price-detail-area'} onClick={() => this.gotoDetail(id) }>
        <Card id={`area-card-${id}`} className="area-card mb-3">
          <Card.Header>
            <span className="area-name">{ name }</span>
            &nbsp;&nbsp;
            <span className="cluster-name">
              {
                cluster_areas.map((cluster_area, index) =>
                  <span key={`cluster-${index}`}>
                    {cluster_area.cluster.description}
                    {cluster_areas.length - 1 === index ? '' : ', '}
                  </span>
                )
              }
            </span>  
            <span className="area-id">{ id }</span>  
          </Card.Header> 
          <Card.Body>
            <span className="title-content block">Provinsi</span>
            <span className="value-content block">
              {
                states.length ?
                (
                  states.map((state, index) => 
                    <div key={`state-${index}`}  style={{ display: 'inline' }}>
                      {state.name}
                      {states.length - 1 === index ? '' : ', '}
                    </div>
                  )
                ) : <span>-</span>
              }
            </span>
            <span style={{ marginTop: '10px', marginBottom: '10px'}}></span>
            <span className="title-content block">Kota / Kabupaten</span>
            <span className="value-content block">
              {
                cities.length ? 
                (
                  cities.map((city, index) =>
                    <div key={`city-${index}`} style={{ display: 'inline' }}>
                      {city.division_type} {city.name}
                      {cities.length - 1 === index ? '' : ', '}
                    </div>
                  )
                ) : <span>-</span>
              }
            </span>
          </Card.Body>
        </Card>
      </Link>
    );
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ detail }, dispatch);
}

export default connect(undefined, mapdtp)(AreaCard);