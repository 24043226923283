export const GET = 'TRANSPORTER_GET';
export const GET_ALL = 'TRANSPORTER_GET_ALL';

export const get = (params = '', type = GET) => {
  return {
    type,
    payload: {
      request: {
        url: `/companies/tags?${params}`,
        method: 'get',
      },
    },
  };
};

export const getAll = (search = '') => {
  return {
    type: GET_ALL,
    payload: {
      request: {
        url: `/companies`,
        method: 'get',
        params: {
          company_type: 'transport',
          searchFields: 'name',
          limit: 10,
          search
        }
      },
    },
  };
};
