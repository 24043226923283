export const CREATE_ATTENDANCE = 'CREATE_ATTENDANCE';
export const createAttendance = (data) => ({
  type: CREATE_ATTENDANCE,
  payload: {
    request: {
      url: `/attendances`,
      method: 'post',
      data,
    },
  },
});