export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_SET_LOGIN_LOADING = 'USER_SET_LOGIN_LOADING'
export const USER_SET_HEADER_VISIBILITY = 'USER_SET_HEADER_VISIBILITY'
export const USER_REQUEST_DATA = 'USER_REQUEST_DATA'
export const USER_REQUEST_GROUP = 'USER_REQUEST_GROUP'
export const USER_TIMEOUT = 'USER_TIMEOUT'
export const USER_CLEAR_TIMEOUT = 'USER_CLEAR_TIMEOUT'
export const USER_PROFILE = 'USER_PROFILE'
export const USERS = 'USERS'
export const ROLES = 'ROLES'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_CREATE = 'USER_CREATE'
export const USER_GET = 'USER_GET'
export const USER_IMPERSONATE = 'USER_IMPERSONATE'


export const login = (data) => {
  return {
    type: USER_LOGIN,
    payload: {
      request: {
        url: '/users/v2/sign_in',
        method: 'post',
        data
      }
    }
  }
}
export const setLoading = (loading) => {
  return {
    type: USER_SET_LOGIN_LOADING,
    loading
  }
}
export const logout = () => {
  return {
    type: USER_LOGOUT,
    payload: {
      request: {
        url: '/users/sign_out',
        method: 'post',
      }
    }
  }
}
export const setHeaderVisibility = (visibilty=false) => {
  return {
    type: USER_SET_HEADER_VISIBILITY,
    visibilty
  }
}
export const getData = () => {
  return {
    type: USER_REQUEST_DATA,
    payload: {
      request: {
        url: '/users/me',
        method: 'get'
      }
    }
  }
}
export const getDataGroup = () => {
  return {
    type: USER_REQUEST_GROUP,
    payload: {
      request: {
        url: '/companies/impersonate?sort=name',
        method: 'get'
      }
    }
  }
}
export const can = (permissions = []) => {
  return (dispatch, getState) => {
    return false;
  }
}

export const handleTimeout = () => {
  return {
    type: USER_TIMEOUT
  }
}

export const clearTimeout = () => {
  return {
    type: USER_CLEAR_TIMEOUT
  }
}

export const getUserProfile = (id) => {
  return {
    type: USER_PROFILE,
    payload: {
      request: {
        url: `/users/${id}?populate=notif_topics,company,account`,
        method: 'get'
      }
    } 
  }
}

export const getUsers = (params) => {
  return {
    type: USERS,
    payload: {
      request: {
        url: `/users`,
        method: 'get',
        params
      }
    } 
  }
}

export const getRoles = () => {
  return {
    type: ROLES,
    payload: {
      request: {
        url: '/roles',
        method: 'get'
      }
    }
  }
}

export const updateUser = (id, data) => {
  return {
    type: USER_UPDATE,
    payload: {
      request: {
        url: `/users/${id}`,
        method: 'put',
        data
      }
    } 
  }
}

export const createUser = (data) => {
  return {
    type: USER_CREATE,
    payload: {
      request: {
        url: `/users`,
        method: 'post',
        data
      }
    }
  }
}

export const getUser = (id) => {
  return {
    type: USER_GET,
    payload: {
      request: {
        url: `/users/${id}`,
        method: 'get'
      }
    } 
  }
}

export const impersonate = (data) => {
  return {
    type: USER_IMPERSONATE,
    payload: {
      request: {
        url: `/users/impersonate`,
        method: "post",
        data,
      },
    },
  };
}