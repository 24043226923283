import React from 'react';
import Select from 'react-select';

export const ReduxFormSelect = props => {
  const { input, options, meta: { touched, error, warning }, placeholder, disabled } = props;

  return (
    <div>
      <Select
        {...input}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={value => {
          input.onChange(value);
          input.value = value;
        }}
        onBlur={() => input.onBlur(input.value)}
        options={options}
      />
      {touched && ((error && <small className="text-danger">{error}</small>) || (warning && <small className="text-danger">{warning}</small>))}
    </div>
  )
}

export default ReduxFormSelect;
