import { combineReducers } from 'redux';
import { SUMMARY_TRIP, SUMMARY_TRIP_TODAY } from '../actions/trip';
import { USER_IMPERSONATE } from '../actions/user';

const summary = (state = {}, action) => {
  switch (action.type) {
    case USER_IMPERSONATE + '_SUCCESS':
      return {};
    case SUMMARY_TRIP + '_SUCCESS':
      const { data } = action.payload;
      if (data) {
        return data;
      }
      return state;
    default:
      return state;
  }
}

const summary_today = (state = {}, action) => {
  switch (action.type) {
    case USER_IMPERSONATE + '_SUCCESS':
      return {};
    case SUMMARY_TRIP_TODAY + '_SUCCESS':
      const { data } = action.payload;
      if (data) {
        return data;
      }
      return state;
    default:
      return state;
  }
}

export default combineReducers({ summary, summary_today });