export const GET = 'SHIPPER_GET';

export const get = (params = '', type = GET) => {
  return {
    type,
    payload: {
      request: {
        url: '/companies?' + params,
        method: 'get',
      },
    },
  };
};
