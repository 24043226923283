export const GET = 'VEHICLE_TYPE_GET';

export const get = (params = 'is_filter=1', type = GET) => {
  return {
    type,
    payload: {
      request: {
        url: `/vehicle_type?${params}`,
        method: 'get',
      },
    },
  };
};
