import React, {Component} from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import noAvatar from '../assets/no-avatar.png';
import SidebarList from './SidebarList';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleSidebar } from '../actions';

class Sidebar extends Component {
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.props.toggleSidebar(open)
  };

  render () {
    const { user } = this.props;
    return (
      <div>
        <SwipeableDrawer
          open={this.props.open}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}>
          <div
            className="ritase-drawer"
            role="presentation"
            onKeyDown={this.toggleDrawer(false)} >
            {user && <div className="ritase-drawer-header text-center w-100">
              <img src={user.image_url?user.image_url:noAvatar} width="50" height="50" className="rounded-circle" alt=""/>
              <p className="m-0 mt-3"><strong>{user.name?user.name.toUpperCase():''}</strong></p>
              <p className="m-0">{user.role && user.role.name}</p>
            </div>}
            <SidebarList closeDrawer={this.toggleDrawer(false)}/>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

const mapstp = (state) => {
  const { sidebarOpen, showSidebar, user } = state;
  return {
    open: sidebarOpen,
    show: showSidebar,
    user: user.data,
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({toggleSidebar}, dispatch)
}

export default connect(mapstp, mapdtp)(Sidebar)
