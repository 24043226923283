import { combineReducers } from 'redux';
import { 
  GET_PRICES, 
  CLEAR_LIST, 
  SET_FILTER, 
  SET_SORT,
  SET_DETAIL_PRICES,
  GET_ORIGIN_COVERAGE,
  GET_DESTINATION_COVERAGE, 
  GET_COMPANIES} from '../actions/all_prices';

const list = (state = [], action) => {
  switch (action.type) {
    case 'all_price_client' +GET_PRICES+'_SUCCESS':
      if (action.payload.data) {
        const { rows = [] } = action.payload.data
        return [
          ...state,
          ...rows
        ];
      }
      return [];
    case 'all_price_client'+CLEAR_LIST:
    case 'all_price_client'+SET_FILTER:
    case 'all_price_client'+SET_SORT:
      return [];
    default:
      return state;
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case 'all_price_client' +GET_PRICES:
    return true;
    case 'all_price_client' +GET_PRICES + '_SUCCESS':
    case 'all_price_client' +GET_PRICES + '_FAIL':
    return false;
    default:
    return state;
  }
}

const sort = (state = {}, action) => {
  switch (action.type) {
    case 'all_price_client' +SET_SORT:
    return action.payload;
    default:
    return state;
  }
}

const filter = (state = {}, action) => {
  switch (action.type) {
    case 'all_price_client' +SET_FILTER:
    return action.payload;
    default:
    return state;
  }
}

const offset = (state = 0, action) => {
  switch (action.type) {
    case 'all_price_client' + GET_PRICES + '_SUCCESS':
      const { errorMessage, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return offset;
    case SET_FILTER:
    case SET_SORT:
      return 0;
    default:
      return state;
  }
}

const count = (state = 0, action) => {
  switch (action.type) {
    case 'all_price_client' + CLEAR_LIST:
      return 0;
    case 'all_price_client' + GET_PRICES + '_SUCCESS':
      const { errorMessage, count } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return count;
    default:
      return state;
  }
}

const detail = (state = {}, action) => {
  switch (action.type) {
    case 'all_price_client'+SET_DETAIL_PRICES:
      return action.item;
    default:
      return state;
  }
}

const originCoverage = (state = {}, action) => {
  switch (action.type) {
    case 'all_price_client' +GET_ORIGIN_COVERAGE+'_SUCCESS':
      if (action.payload.data) {
        const data = action.payload.data
        return {
          ...data
        }
      }
      return state;
    default:
      return state;
  }
}

const destinationCoverage = (state = {}, action) => {
  switch (action.type) {
    case 'all_price_client' +GET_DESTINATION_COVERAGE+'_SUCCESS':
      if (action.payload.data) {
        const data = action.payload.data
        return {
          ...data
        }
      }
      return state;
    default:
      return state;
  }
}

const companies = (state = [], action) => {
  switch (action.type) {
    case 'all_price_client' +GET_COMPANIES+'_SUCCESS':
      if (action.payload.data) {
        const { rows = []} = action.payload.data
        return [
          ...rows
        ]
      }
      return state;
    default:
      return state;
  }
}

export default combineReducers({
  list, 
  sort, 
  filter, 
  loading, 
  offset, 
  count, 
  detail,
  originCoverage, 
  destinationCoverage,
  companies});