import $ from 'jquery';

const errorHandler = ({ logout }, res) => {
  const out = () => {
    logout().then(res => {
      $('body').addClass('splash-bg')
    });
  };

  if (logout) {
    if (res.error.response) {
      switch (res.error.response.status) {
        case 401:
          out();
          break;
        case 403:
          out();
          break;
        default:

      }
    }
  }
}

export default errorHandler;
