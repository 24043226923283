import { combineReducers } from 'redux';
import { GET, CLEAR_ITEMS, GET_DETAIL } from '../actions/trip';

const items = (state = [], action) => {
  switch (action.type) {
    case 'trip_history' + CLEAR_ITEMS:
      return [];
    case 'trip_history' + GET + '_SUCCESS':
      const { errorMessage, rows, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      if (offset > 0) {
        return state.concat(rows)
      }
      return rows;
    default:
      return state;
  }
}

const count = (state = 0, action) => {
  switch (action.type) {
    case 'trip_history' + CLEAR_ITEMS:
      return 0;
    case 'trip_history' + GET + '_SUCCESS':
      const { errorMessage, count } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return count;
    default:
      return state;
  }
}

const offset = (state = 0, action) => {
  switch (action.type) {
    case 'trip_history' + CLEAR_ITEMS:
      return 0;
    case 'trip_history' + GET + '_SUCCESS':
      const { errorMessage, offset } = action.payload.data;
      if (errorMessage) {
        return state;
      }
      return offset;
    default:
      return state;
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case 'trip_history' + GET:
      return true;
    case 'trip_history' + GET + '_SUCCESS':
      return false;
    case 'trip_history' + GET + '_FAIL':
      return false;
    default:
      return state;
  }
}

const fail = (state = false, action) => {
  switch (action.type) {
    case 'trip_history' + GET:
      return false;
    case 'trip_history' + GET + '_FAIL':
      return true;
    default:
      return state;
  }
}

const detail = (state = false, action) => {
  switch (action.type) {
    case GET_DETAIL + '_RESET':
      return {};
    case GET_DETAIL + '_SUCCESS':
      if (action.payload.data.errorMessage) {
        return state;
      }
      return action.payload.data;
    default:
      return state;
  }
}

export default combineReducers({ items, loading, count, offset, fail, detail });
