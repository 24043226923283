export const GET_SUPERGROUP = 'COMPANIES_GET_SUPERGROUP';
export const GET = 'VEHICLE_TYPE_GET';

export const getCompaniesSupergroup = (search = '') => {
  return {
    type: GET_SUPERGROUP,
    payload: {
      request: {
        url: `/companies/supergroup`,
        method: 'get',
        params: {
          searchFields: 'name',
          limit: 10,
          search
        }
      },
    },
  };
};

export const get = (params = 'is_filter=1', type = GET) => {
  return {
    type,
    payload: {
      request: {
        url: `/companies?${params}`,
        method: 'get',
      },
    },
  };
};
