export const GET = 'REASON_GET';
export const GET_PARAMS = 'REASON_GET_PARAMS';
export const GET_REASONS_CATEGORIES = 'GET_REASON_CATEGORIES';
export const GET_ALLOWED_POD_TRACK = 'GET_ALLOWED_POD_TRACK';

export const get = () => {
  return {
    type: GET,
    payload: {
      request: {
        url: '/reasons?type=pod_remarks',
        method: 'get',
      },
    },
  };
};

export const getParams = (params = '') => {
  return {
    type: GET_PARAMS,
    payload: {
      request: {
        url: `/reasons?${params}`,
        method: 'get',
      },
    },
  };
};

/**
 * get reasons categories
 * @param params
 * @returns {{payload: {request: {method: string, params: *, url: string}}, type: string}}
 */
export const getReasonCategories = (params = {}) => ({
  type: GET_REASONS_CATEGORIES,
  payload: {
    request: {
      url: '/reasons/categories',
      method: 'get',
      params,
    }
  }
});

export const getAllowedPodTrackId = () => ({
  type: GET_ALLOWED_POD_TRACK,
  payload: {
    request: {
      url: '/document_tracks/helpers/allowed_pod_tracks_id',
      method: 'get',
    }
  }
});
