import { IonContent } from '@ionic/react';
import { AppBar, Card, CardContent, CircularProgress, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import moment from 'moment';
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleHeader } from '../../actions';
import Can from '../../components/Can';
import CompanyBlock from '../../components/CompanyBlock';
import thousand from '../../helpers/thousand';
import { permissions } from '../../routes';
import "./style.scss";

class DetailCapacityOfferScreen extends Component {
  componentDidMount() {
    this.props.toggleHeader(false);
  }

  componentWillUnmount() {
    this.props.toggleHeader(true);
  }

  goBack = () => {
    this.props.history.push('/capacity-offer');
  }

  render() {

    const { detail, loading } = this.props;

    if (!detail.id || loading) {
      return (
        <Can redirect permissions={permissions('/capacity-offer')}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton edge="start" onClick={() => this.goBack()} style={{color: 'white'}} aria-label="Menu">
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" style={{color: 'white'}}>
              Looking for Shipment Detail
            </Typography>
          </Toolbar>
        </AppBar>
        <Container>
          <Row>
            <Col>
              <center>
              {loading ? <CircularProgress /> : <h2 className="text-danger">
                Terjadi kesalahan saat menampilkan halaman.
              </h2>}
              </center>
            </Col>
          </Row>
        </Container>
      </Can>
      )
    }  
    
    return (
      <Can redirect permissions={permissions("/capacity-offer")}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => this.goBack()}
              style={{ color: "white" }}
              aria-label="Menu"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" style={{ color: "white" }}>
              Looking for Shipment Detail
            </Typography>
          </Toolbar>
        </AppBar>
        <IonContent style={{ "--background": "#F0F6F6" }}>
          <Card className="card-capacity-offer">
            <CardContent>
              <div className="mb-2">
                <div className="label">Transporter</div>
                <CompanyBlock company={detail.company_transport} />
              </div>
              <ul className="route-area-with-title">
                <li>
                  <div className="label">Asal</div>
                  <strong>{detail.origin.name}</strong>
                </li>
                <li>
                  <div className="label">Tujuan</div>
                  <strong>{detail.destination.name}</strong>
                </li>
              </ul>
              <div className="vehicle-type">
                <div className="label">Vehicle Type</div>
                <span>{detail.type.name}</span>
              </div>
              <div className="vehicle-type">
                <div className="label">No Pol - Driver</div>
                <span style={{ color: "#9E9E9E" }}>
                  {detail.vehicle && detail.vehicle.license_plate} -{" "}
                  {detail.driver && detail.driver.name}
                </span>
              </div>
              <div className="offer-date">
                <div className="label">Available Date Time</div>
                <span>
                  {moment(detail.start_time).format("D MMM YYYY HH:mm")} -{" "}
                  {moment(detail.end_time).format(
                    moment(detail.end_time).isSame(detail.start_time, "day")
                      ? "HH:mm"
                      : "D MMM YYYY HH:mm"
                  )}
                </span>
              </div>
              <div className="offer-price">
                <div className="label">Price</div>
                <span style={{ color: "#F16B27", fontSize: "1.25rem" }}>
                  Rp {thousand(detail.price)}
                </span>
              </div>
            </CardContent>
          </Card>
        </IonContent>
      </Can>
    );
  }
}

const mapstp = (state) => {
  return {
    detail: state.capacity_offer.detail,
    loading: state.capacity_offer.loading,
  }
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ toggleHeader }, dispatch)
}

export default connect(mapstp, mapdtp)(DetailCapacityOfferScreen)
