import {combineReducers} from 'redux';
import {reducer as form} from 'redux-form';
import { USER_LOGOUT, USER_LOGIN, USER_SET_HEADER_VISIBILITY, USER_REQUEST_DATA } from '../actions/user';
import user from './user';
import field from './field';
import shipper from './shipper';
import order from './order';
import reason from './reason';
import report from './report';
import transporter from './transporter';
import trip_approval from './trip_approval';
import trip_history from './trip_history';
import trip_summary from './trip_summary';
import trip from './trip';
import contact from './contact';
import vehicle_type from './vehicle_type';
import w_trip from './w_trip';
import issue from './issue';
import issue_summary from './issue_summary';
import ls from 'local-storage';
import { TOGGLE_SIDEBAR, TOGGLE_HEADER, SET_TITLE } from '../actions';
import consignee from "./consignee";
import notification from "./notification";
import capacity_offer from "./capacity_offer";
import approval from "./approval";
import companies from "./companies";
import all_prices from "./all_prices";
import all_price_transporter from "./all_price_transporter";
import all_price_client from "./all_price_client";
import areas from "./areas";


const initialShowHeader = ls.get('USER_TOKEN')?true:false;
const showHeader = (state=initialShowHeader, action) => {
  switch (action.type) {
    case USER_LOGOUT+'_SUCCESS':
    return false;
    case USER_LOGOUT+'_FAIL':
    return false;
    case USER_REQUEST_DATA+'_FAIL':
    return false;
    case USER_SET_HEADER_VISIBILITY:
    return action.visibilty
    case USER_LOGIN+'_SUCCESS':
    var {data, status} = action.payload;
    if (status===200 && !data.errorMessage) {
      // jika ada data user
      state = true
    }
    return state;
    case TOGGLE_HEADER:
    return action.show;
    default:
    return state
  }
}

const sidebarOpen = (state=false, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
    return action.open;
    default:
    return state
  }
}

const titleScreen = (state=null, action) => {
  switch (action.type) {
    case SET_TITLE:
    return action.value;
    default:
    return state
  }
}

const showSidebar = (state=initialShowHeader, action) => {
  switch (action.type) {
    case USER_LOGOUT+'_SUCCESS':
    return false;
    case USER_LOGOUT+'_FAIL':
    return false;
    case USER_REQUEST_DATA+'_FAIL':
    return false;
    case USER_LOGIN+'_SUCCESS':
    var {data, status} = action.payload;
    if (status===200 && !data.errorMessage) {
      // jika ada data user
      state = true
    }
    return state;
    default:
    return state;
  }
}

export default combineReducers({
  form,
  user,
  showHeader,
  titleScreen,
  showSidebar,
  sidebarOpen,
  field,
  shipper,
  order,
  reason,
  report,
  transporter,
  trip,
  trip_approval,
  trip_history,
  trip_summary,
  contact,
  vehicle_type,
  w_trip,
  issue,
  issue_summary,
  consignee,
  notification,
  capacity_offer,
  approval,
  companies,
  all_prices,
  all_price_transporter,
  all_price_client,
  areas
});
