import {FIND_TRIP, FIND_TRIP_BY_SHIPMENT_NUMBER, UPDATE_TRIP, GET_DETAIL_DEFINE} from '../actions/trip';

const trip = {
  loading: {
    findTrip: false,
    updateTrip: false,
  },
  trip: null,
  dictionaryTrip: {}
}

export default (state = trip, action) => {
  switch (action.type) {
    case `${FIND_TRIP}`:
    case `${FIND_TRIP_BY_SHIPMENT_NUMBER}`:
      return {
        ...state,
        loading: {
          findTrip: true,
        },
      };
    case `${FIND_TRIP}_FAIL`:
    case `${FIND_TRIP}_SUCCESS`:
      const {payload, error} = action;
      let trip = null;
      if (!error) {
        const {data} = payload;
        trip = data;
      } else {
        trip = {
          errorMessage: error.data,
        };
      }

      return {
        ...state,
        loading: {
          findTrip: false,
        },
        trip,
      };
    case `${UPDATE_TRIP}`:
      return {
        ...state,
        loading: {
          updateTrip: true,
        },
      };
    case `${UPDATE_TRIP}_SUCCESS`:
    case `${UPDATE_TRIP}_FAIL`:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateTrip: false,
        },
      }
    case `${FIND_TRIP_BY_SHIPMENT_NUMBER}_FAIL`:
    case `${FIND_TRIP_BY_SHIPMENT_NUMBER}_SUCCESS`:
      const {payload: response, error: errorResponse} = action;
      let newTrip = null;
      if (!errorResponse) {
        const {data} = response;
        if (data.rows && data.rows[0]) {
          newTrip = data.rows[0];
        } else {
          newTrip = {
          errorMessage: 'Shipment number tidak dapat ditemukan',
        };
        }
      } else {
        newTrip = {
          errorMessage: errorResponse.data,
        };
      }

      return {
        ...state,
        loading: {
          findTrip: false,
        },
        trip: newTrip,
      };
    case GET_DETAIL_DEFINE:
      return {
        ...state,
        loading: {
          findTrip: true,
        },
      };
    case `${GET_DETAIL_DEFINE}_FAIL`:
    case `${GET_DETAIL_DEFINE}_SUCCESS`:
      const { data } = action.payload;
      return {
        ...state,
        loading: {
          ...state.loading,
          findTrip: false,
        },
        dictionaryTrip: {
          ...state.dictionaryTrip,
          [data.id]: data
        }
      };
    default:
      return state;
  }
}
