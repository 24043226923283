import React, { Component } from 'react';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import workimg from '../../assets/home.png';
import { toggleSidebar } from '../../actions';

class HomeScreen extends Component {
  toggleDrawer = () => {
    this.props.toggleSidebar(true);
  }

  render() {
    return (
      <div className="ritase-home">
        <Container>
          <Row className="justify-content-center">
            <Col sm={6} md={6} >
              <img src={workimg} alt="ritroops" width="100%"/>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6} >
              <Card className="shadow-lg">
                <Card.Body className="text-center">
                  <p className="lead mb-0">Welcome!</p>
                  <p>Please open the menu to use this app!</p>
                  <Button block size="lg" onClick={() => this.toggleDrawer()}>Open Menu</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

// Map state to props
const mapstp = (state) => {
  return {}
}

const mapdtp = (dispatch) => {
  return bindActionCreators({ toggleSidebar }, dispatch)
}

export default connect(mapstp, mapdtp)(HomeScreen)
